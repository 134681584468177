import StockCell from "./stock-cell";
import InfiniteScroll from "react-infinite-scroll-component";

import useStyles from './stocks.css';

import './stocks.css';

const InstrumentList = (props: any) => {
  const classes = useStyles();

  return <div className={classes.asideWrap}>{
    props.stocks && props.stocks.length > 0 && (
      <InfiniteScroll
        dataLength={props.stocks.length}
        next={() => { }}
        hasMore={false}
        loader={<h4>Loading...</h4>}
        endMessage={<></>}
      >
        {
          props.stocks?.map((params: any) => {

            if (props.checkedProjectIDs && props.checkedProjectIDs.length > 1) {
              if (props.checkedProjectIDs.indexOf(params.projectId) === -1) {
                return false;
              }
            }
            const id = params.sid;
            return (
              <div key={id} className={classes.challengeItem} >
                <StockCell {...params} />
              </div>
            )
          })
        }

      </InfiniteScroll>
    )
  }
  </div>
}

export default InstrumentList;

