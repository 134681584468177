import React, { useState } from 'react'
import { Stack, Button, Box, TextField, Snackbar, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { appConfig } from "../../config/settings";
import TemplateDefault from "../template-default";
import { signIn } from "../../services/api-services";
import { useAuth } from '../../common/use-auth';
import './login.scss';
const LOGIN_URL = '/trade';
const baseUrl = appConfig.api.baseUrl;



const Login = () => {
  const history = useHistory();
  let auth = useAuth();

  const [form, setForm]: any = useState({
    password: ''
  })
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity]: any = useState('success');
  const [snackMsg, setSnackMsg]: any = useState('');

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  let windowObjectReference: any = null;
  let previousUrl: any = null;
  const receiveMessage = async (event: any) => {
    // remove any existing event listeners
    // window.removeEventListener('message', receiveMessage);
    
    const { data } = event;
    console.log('token received ' + data.authToken);
    if (data && data.type === 'authToken' && data.authToken) {
      localStorage.setItem('authToken', data.authToken);

      windowObjectReference.close();
      auth.signIn();
      window.setTimeout(() => {
        history.replace(LOGIN_URL);
      })
    }
  };

  const loginWithGoogleHandler = () => {
    const url = baseUrl + "/sso/google";
    const name = "Google Auth";
    // remove any existing event listeners
    // window.removeEventListener('message', receiveMessage);

    const w = 360;
    const h = 480;
    const left = (window.screen.width / 2) - (w / 2);
    const top = (window.screen.height / 2) - (h / 2);
    // window features
    const strWindowFeatures =
      `toolbar=no, menubar=no, width=${w}, height=${h}, top=${top}, left=${left}`;

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
       is not closed; therefore, we can bring it back on top of any other
       window with the focus() method. There would be no need to re-create
       the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', receiveMessage, false);
    // assign the previous URL
    previousUrl = url;
  }

  const loginHandler = () => {
    if (form.password) {
      const signInReq: any = signIn({ password: form.password });
      signInReq.promise
        .then(async (res: any) => {
          if (res.status === 'success') {
            localStorage.setItem('toxyToken', 'true');
            setSnackMsg(res.status);
            setSnackSeverity('success');
            setOpenSnack(true);
            window.setTimeout(() => {
              history.push(LOGIN_URL)
            })

          }
        })
        .catch((error: any) => {
          setSnackMsg(error.message);
          setSnackSeverity('error');
          setOpenSnack(true);
        })
    }
  }

  return (
    <>
      <TemplateDefault
        layout={{
          mainView: 12,
          header: false
        }}

        header={
          (toggleDrawer: any) => {
            return false
          }
        }
      >
        <div className="login-page">
          <Box
            className="login-box">
            <h2>Login</h2>
            <div className="form">
              <Stack spacing={2}>
                <TextField size="small" label="Username" variant="outlined" value={form.username} onChange={(e: any) => setForm({ ...form, username: e.target.value })} />
                <TextField size="small" label="Password" variant="outlined" value={form.password} onChange={(e: any) => setForm({ ...form, password: e.target.value })} />
                <Button variant="contained" onClick={loginHandler}>Login</Button>
                <Button variant="contained" onClick={loginWithGoogleHandler} >Login With Google</Button>
              </Stack>
            </div>
          </Box>
        </div>
        <Snackbar open={openSnack} autoHideDuration={6000}>
          <Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: '100%' }}>
            {snackMsg}
          </Alert>
        </Snackbar>
      </TemplateDefault>
    </>
  )
}

export default Login;