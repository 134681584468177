/**
 * performance chart component
 */
import { ResponsiveLine } from '@nivo/line'
import './chart.scss'

const T100Performance = ({ data }: any) => {
  const CustomSymbol = (d: any) => {
    const color = d.datum.color;
    return (
      <g>
        <circle fill={color + '55'} r={d.datum.labelId === 'Price' ? d.size / 2 : d.size / 4} strokeWidth={d.borderWidth} stroke={color} />
      </g>
    )
  }

  const toolTip = (d: any) => {
    console.log(d);
    const p0 = d.slice.points[0];
    return (
      <div
        className='tooltip'>
        <div className='tooltip-title-rw'><strong>{d.slice.points[2].index / 3 + 1}. {p0.data?.stockInfo?.name}</strong></div>
        <div className='tooltip-title-rw'><strong>{p0.data.dyChange}</strong> {p0.data?.maxMinChange?.toFixed(2)}</div>
        <table className="tooltip-table">
          <tbody>
            <tr>
              {d.slice.points.map((point: any) => (
                <th key={point.id}>
                  {point.data.labelId}
                </th>
              ))}
            </tr>
            <tr>
              {d.slice.points.map((point: any) => (
                <td key={point.id + '-v'} >
                  <strong> {point.data.label}</strong>
                </td>
              ))}
            </tr></tbody>
        </table>
      </div>
    )
  }

  // const CustomSymbol = ({ size, color, borderWidth, borderColor, datum }: any) => (
  //   <g>
  //     <circle
  //       fill="#fff"
  //       r={size / 2}
  //       strokeWidth={borderWidth}
  //       stroke={datum.color}
  //     />
  //     <circle
  //       r={size / 5}
  //       strokeWidth={borderWidth}
  //       stroke={datum.color}
  //       fill={datum.color}
  //       fillOpacity={0.35}
  //     />
  //   </g>
  // );

  const colors = data.map((i: any) => i.color);
  const props = {
    enableSlices: "x",
    enableGridX: false,
    margin: { top: 20, right: 20, bottom: 60, left: 80 },
    pointBorderColor: {
      from: "color",
      modifiers: [["darker", 0.3]],
    },
    pointBorderWidth: 1,
    pointSize: 16,
    pointSymbol: CustomSymbol,
    colors: colors
  } as const;

  return (
    <ResponsiveLine
      data={data}
      sliceTooltip={toolTip}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      {...props}
    />
  )
}

export default T100Performance;
