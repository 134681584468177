import { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";
import { Context } from '../context/store'
import { useNotification } from "../context/notification";


export const GlobalNotification = () => {
  const { dispatch, state }: any = useContext(Context);
  const { closeNotication } = useNotification(dispatch);

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeNotication();
  }

  return (
    <Snackbar open={state.notification.open} autoHideDuration={state.notification.autoHideDuration || 6000}>
      <Alert onClose={handleCloseSnack} severity={state.notification.severity} sx={{ width: '100%' }}>
        {state.notification.message}
      </Alert>
    </Snackbar>
  )
}

export default GlobalNotification;