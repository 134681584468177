import * as _ from "lodash";
import { sectors } from "../config/sectors";
import holding from "../context/holding.ctx";
import { BUYSELL_COLOR } from "./theme";

export const getProjectList = (challenges: any) => {
  const projects: any = [];
  challenges.map((ch: any) => {
    ch.projectId && projects.push(ch.projectId);
    return ch;
  })
  return _.uniq(projects);
}

/**
 * Validate value is unempty
 */
export const requiredValidator = (value: string | boolean) => {
  if (value) {
    return '';
  } else {
    return 'Required';
  }
};

export const isLoggedTApp = () => {
  const token = localStorage.getItem("authToken");
  return token ? true : false;
}

export const getSectorByName = (name: any) => {
  const sByName = _.keyBy(sectors, 'name');
  return sByName[name] ? sByName[name] : sByName['Unlisted'];
}


export const getTotalInvested = (data: any) => {
  let totalInvested = 0;
  data.map((d: any) => {
    const qty = d.quantity + d.t1_quantity;
    const curVal = d.last_price * qty;
    totalInvested += curVal;
    return d;
  })
  // console.log('ti:' + totalInvested);
  return totalInvested;
}
export const processHoldingData = (data: any) => {
  let totalInvested = getTotalInvested(data);
  const result = data.map((d: any, index: any) => {
    // const sym = d.tradingsymbol;
    const qty = d.realised_quantity + d.t1_quantity;
    const curVal = d.last_price * (qty);
    const investedAMt = d.average_price * qty;
    const dNew = {
      invested_amt: investedAMt,
      cur_val: curVal,
      net_change_percentage: (curVal - investedAMt) / investedAMt * 100,
      weight: curVal / totalInvested * 100,
      rpnl: qty * (d.last_price - d.average_price),
      ...d
    }
    return dNew;
  })
  return result;
}
export const processOrdersData = (data: any) => {
  let totalInvested = getTotalInvested(data);
  return data.map((d: any, index: any) => {
    // const sym = d.tradingsymbol;
    const qty = d.quantity + (d.t1_quantity ? d.t1_quantity : 0);
    const curVal = d.last_price * (qty);
    const investedAMt = d.average_price * qty;
    return {
      index: index,
      invested_amt: investedAMt,
      cur_val: curVal,
      net_change_percentage: (curVal - investedAMt) / investedAMt * 100,
      weight: curVal / totalInvested * 100,
      rpnl: qty * (d.last_price - d.average_price),
      color: d.transaction_type === "SELL" ? BUYSELL_COLOR.sell : BUYSELL_COLOR.buy,
      ...d
    }
  })
}

export const processGttTriggerData = (data: any) => {
  return data.map((d: any, index: any) => {
    const qty = d.orders[0].quantity;
    return {
      index: index,
      color: d.transaction_type === "SELL" ? BUYSELL_COLOR.sell : BUYSELL_COLOR.buy,
      ...d
    }
  })
}

/**
 * 
 * @param data holdings data
 * @returns string
 */
export const getSidSearchStr = (data: any) => {
  let holdingStr = '';
  let holdingStrObj: any = {};
  data.map((d: any) => {
    const sym = d.tradingsymbol.substr(0, 3);
    holdingStrObj[sym] = sym;
    return d;
  })
  holdingStr = Object.keys(holdingStrObj).toString().replace(/,/g, '+');
  return holdingStr;
}

// generates treemap data
export const generateTreemapData = ((holdings: any, stkSecM: any) => {
  // console.log(holdings);
  const tmdObj: any = {};
  holdings.map((d: any) => {
    const sym = d.tradingsymbol.replace(/-BE/g, '');
    const sector = stkSecM[sym] ? stkSecM[sym].sector : 'Unlisted';
    const tmd = {
      name: sector,
      color: stkSecM[sym] ? stkSecM[sym].color : '#ccc',
      children: tmdObj[sector] ? tmdObj[sector].children : []
    }
    d.name = sym;
    tmd.children.push(d);
    tmdObj[sector] = tmd;
    return tmd;
  })

  const tmdc = Object.keys(tmdObj).map((key: any, i: any) => {
    const obj = tmdObj[key];
    obj.name = key;
    return obj;
  })

  const treemapData = {
    "name": "Securities",
    "color": "hsl(151, 70%, 50%)",
    children: tmdc
  }
  // console.log('tmdObj');
  // console.log(treemapData);

  return treemapData;
})

export const generateHodlingSector = (holdings: any, stockSectorMapping: any) => {
  return holdings.map((d: any, index: number) => {
    d['id'] = index;
    const mv = stockSectorMapping[d.tradingsymbol.replace(/-BE/g, '')];
    d['sector'] = mv ? mv.sector : 'Unlisted';
    d['color'] = mv ? mv.color : '#ccc';
    // console.log(d)
    return d;
  });
}

export const getProfileCols = (holdingSectorData: any) => {
  const r = holdingSectorData.map((d: any) => {
    return {
      id: 'tradingsymbol',
      label: 'Instrument',
      key: 'tradingsymbol',
      class: 'align-left'
    }
  })
  return r;
}

export const getPercentage = (targetValue: any, baseValue: any) => {
  return ((targetValue) / baseValue * 100).toFixed(2);
}
export const getDiffPercentage = (targetValue: any, baseValue: any) => {
  return ((targetValue - baseValue) / baseValue * 100).toFixed(2);
}

export const getInstrumentTokensFromHolding = (holdings: Array<Object>) => {
  const iTokens = holdings.map((item: any) => item.instrument_token);
  return iTokens;
}


export const formatNumber = (num: any, digits: any = 2) => {
  if (num === 'TO' || num === 0) {
    return num
  }
  if (num) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "B" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    num = num.toString().replace(/,/g, '');
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    digits = digits ? digits : 2;
    const r = (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
    return r === "NaN" ? '...' : r;
  }
  return;
}

/**
 * returngs sids of my holdings
 * @returns 
 */
const MISSINS_SIDS = { AEGISLOG: { sid: 'AEGS' }, UNITDSPR: { sid: 'UNSP' } };
export const getHoldingSids = (allStocks: any, holdingStocks: any) => {
  allStocks = { ...allStocks, ...MISSINS_SIDS };
  const sids = holdingStocks.map((item: any) => {
    return allStocks[item.tradingsymbol] ? allStocks[item.tradingsymbol].sid : item.tradingsymbol;
  });
  return sids;
}

/**
 * parses jwt token
 * @param token jwt token
 * @returns object
 */
export const parseJwt = (token: any = "") => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

// currency formatter
export const currencyFormat = (num: any, hideSymbol: any = false, maximumFractionDigits: any = 2) => {
  const currency = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: hideSymbol ? "code" : "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'long',
  })
  return currency?.format(num)?.replace('INR', '')?.trim();
}

export const formatDecimal = (num: any) => {
  return parseFloat(num).toFixed(2)
}

export const colorGradient = (fadeFraction: any, rgbColor1: any, rgbColor2: any, rgbColor3: any) => {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction / 100;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
    green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
    blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
  };

  return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
}