import { Chip, Stack, Typography } from "@mui/material";
import { colorGradient, formatDecimal } from "../../common/utils";
import ProgressCard from "../progress-card/progress-card";
import RangeSliderCenter from "./RangeSliderCenter";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const screenerInitialState = {
  apef: false,
  indpe: false
}

const getProfileRows = {
  sid: (d: any) => (<Stack
    direction={'column'}
  >
    <Typography variant='body2' display='block'>{d.sid}</Typography>
    <Typography variant='caption' display='block' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95px' }}>{d.name}</Typography>
  </Stack>
  ),
  lastPrice: (d: any) => (<span> {formatDecimal(d.lastPrice)} </span>),
  apef: (d: any) => (<span> {formatDecimal(d.apef)} </span>),
  indpe: (d: any) => (<span> {formatDecimal(d.indpe)} </span>),
  distPE: (d: any) => {
    const multiplier = 10;
    let val = Math.abs(d.apef / d.indpe);
    const valFormated = formatDecimal(val > 1 ? val : 1 / val) + 'x';
    const isChangePositive = val < 1;
    const color = isChangePositive ? 'primary' : 'secondary';
    console.log(d)
    return (
      <ProgressCard value={val > 1 ? (200 - val * multiplier) : (multiplier / val)}
        color={color}
        progressStyle={{
          backgroundColor: isChangePositive ? '#ecf4ff' : '#ffe7df'
        }}
      >
        <Stack
          justifyContent="space-between"
          direction={'row'}
          alignItems="center"
        >
          <Typography variant='body2' component='span' sx={{ width: '72px', fontWeight: 500 }}>{valFormated}
            {isChangePositive ? <ArrowDropUpIcon sx={{ verticalAlign: 'top', color: '#2e7d32' }} /> : <ArrowDropDownIcon sx={{ verticalAlign: 'top', color: '#ff5722' }} />}</Typography>
          <Typography variant='body2' component='span' sx={{ width: '120px', textAlign: 'right' }} >{formatDecimal(d.apef)} / {formatDecimal(d.indpe)}</Typography>
        </Stack>
      </ProgressCard>
      // <RangeSliderCenter value={rangeValue} color={color} marks={marks} min={0}
      //   max={10} />
    )

  },
  ema50d: (d: any) => (<span> {formatDecimal(d.ema50d)} </span>),
  sma50d: (d: any) => (<span> {formatDecimal(d.sma50d)} </span>),
  distFrmSma50d: (d: any) => {
    let val = d.lastPrice / d.ema50d;
    const color = val > 1 ? 'primary' : 'secondary';
    val = val > 1 ? 50 + (val - 1) * 100 : 50 - (1 - val) * 100;
    const valFormated = formatDecimal(-1 * (val > 1 ? 50 - val : 50 + val)) + '%';
    const rangeValue: any = [val, 50];
    const marks = [{ value: val, label: valFormated }];
    return (<RangeSliderCenter value={rangeValue} color={color} marks={marks} />)

  },
  returns: (value: any) => {
    let lowColor = {
      red: 202,
      green: 255,
      blue: 204
    };
    let mediumColor = {
      red: 8,
      green: 143,
      blue: 14
    };
    let highColor = {
      red: 0,
      green: 63,
      blue: 3
    };

    if (value < 0) {
      lowColor = {
        red: 255,
        green: 202,
        blue: 204
      };
      mediumColor = {
        red: 143,
        green: 8,
        blue: 14
      };
      highColor = {
        red: 63,
        green: 0,
        blue: 3
      };
    }

    const color = colorGradient(formatDecimal(Math.abs(value)), lowColor, mediumColor, highColor)
    return (
      <Chip label={formatDecimal(value)}
        sx={{ background: color, color: (value < 50 ? 'black' : 'white') }}
      />
      // <span sx={{back}}>{formatDecimal(value)}</span>
    )
  }
}

export const getScreenerCols = (udpateSymbol: any) => [
  {
    field: 'sid',
    dataType: 'string',
    headerName: 'Ticker',
    renderCell: (params: any) => getProfileRows.sid(params.row)
  },
  {
    field: 'apef',
    headerName: 'PE',
    renderCell: (params: any) => getProfileRows.apef(params.row)
  },
  {
    field: 'indpe',
    headerName: 'Sector PE',
    renderCell: (params: any) => getProfileRows.indpe(params.row)
  }, {
    field: 'distPE',
    headerName: 'PE/Sec-PE',
    valueGetter: (value: any) => Math.abs(value.row.apef / value.row.indpe),
    renderCell: (params: any) => getProfileRows.distPE(params.row),
    width: 200
  },
  {
    field: 'lastPrice',
    renderCell: (params: any) => getProfileRows.lastPrice(params.row)
  },
  {
    field: 'ema50d',
    renderCell: (params: any) => getProfileRows.ema50d(params.row)
  },
  {
    field: 'sma50d',
    renderCell: (params: any) => getProfileRows.sma50d(params.row)
  },
  {
    field: 'distFrmSma50d',
    headerName: 'D-50ema',
    renderCell: (params: any) => getProfileRows.distFrmSma50d(params.row),
    width: 200

  }, {
    field: 'pr1w',
    headerName: '1W R',
    renderCell: (params: any) => getProfileRows.returns(params.row['pr1w'])
  },
  {
    field: '4wpct',
    headerName: '1M R',
    renderCell: (params: any) => getProfileRows.returns(params.row['4wpct'])
  }, {
    field: '26wpct',
    headerName: '6M R',
    renderCell: (params: any) => getProfileRows.returns(params.row['26wpct']),

  }, {
    field: '52wpct',
    headerName: '1Y R',
    renderCell: (params: any) => getProfileRows.returns(params.row['52wpct']),
  }, {
    field: '5yCagrPct',
    headerName: '5Y CAGR',
    renderCell: (params: any) => getProfileRows.returns(params.row['5yCagrPct']),
  }
]