
import { useMemo } from 'react';
import { callCancellableNodeApi } from "../../services/api-services";

// Actions
export const useScreener = (dispatch: any) => {
  // Important(!): memoize array value. Else all context consumers update on *every* render
  const actions = useMemo(() => ({
    updateSidStockInfoMapping: (newData: any) => {
      dispatch({ type: 'SET_SID_STOCK_INFO_MAPPING', payload: newData })
    },

    updatePerformanceData: (payload: any) => {
      dispatch({ type: 'SET_PERFORMANCE_DATA', payload: payload })
    },

    getPerformanceData: (sids: any) => {
      // gets performance data
      const screenerQuotes: any = getScreenerQuotesReq(sids);
      screenerQuotes.promise
        .then((response: any) => {
          dispatch({ type: 'SET_PERFORMANCE_DATA', payload: response.data });
        })
        .catch(function (error: any) {
          console.log(error);
        });
    },

    getUserHoldingsData : () => {
      const holdingsReq:any = getHoldingsReq();
      holdingsReq.promise
        .then((response: any) => {
          dispatch({ type: 'SET_HOLDINGS_SIDS', payload: JSON.stringify(response.sids) });
        })
        .catch(function (error: any) {
          console.log(error);
        });
    },

    updateMutualFund: (payload:any)=>{
      dispatch({ type: 'SET_MUTUAL_FUND', payload: payload })
    },

    updateScreenerChartData: (payload:any)=>{
      dispatch({ type: 'SET_SCREENER_DATA', payload: payload })
    }
  }), [dispatch])
  return actions;
}


const getScreenerQuotesReq = (data: any) => {
  return callCancellableNodeApi(`/quotes/?sids=${data.join(',')}`, 'GET')
};

export const getHoldingsReq = (data: any = '') => {
  return callCancellableNodeApi(`/holding?perPage=1&orderBy=updatedAt`, 'GET', data);
};

export default useScreener;