export const initialGttOrderRequest = {
  condition: { "exchange": "NSE", "tradingsymbol": "BEL", "trigger_values": [1, 100], "last_price": 100 },

  orders: [{ "exchange": "NSE", "tradingsymbol": "BEL", "transaction_type": "SELL", "quantity": 10, "price": 1, "order_type": "LIMIT", "product": "CNC" }, { "exchange": "NSE", "tradingsymbol": "BEL", "transaction_type": "SELL", "quantity": 10, "price": 100, "order_type": "LIMIT", "product": "CNC" }],

  type: 'two-leg'
}

export const sellGTTReq = {
  ...initialGttOrderRequest,
  condition: {...initialGttOrderRequest.condition, "trigger_values": [500]},
  type: 'single'
}