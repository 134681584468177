
import React from 'react'
import { ResponsivePie } from '@nivo/pie';

import './demo.scss';

const MAX_LABEL_LEN = 12;


const ChartBuilderPie = ({ data, chartLabel, maxItems }: any) => {


  const legendStyle: any = [
    {

      anchor: 'bottom-left',
      direction: 'column',
      justify: false,
      translateX: -60,
      translateY: 22,
      itemsSpacing: 8,
      itemWidth: 100,
      itemHeight: 18,
      itemTextColor: '#333',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 12,
      symbolShape: 'circle',
      toggleSerie: true,
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#000'
          }
        }
      ],
    }
  ]

  const CenteredMetric = ({ centerX, centerY }: any) => {
    let totalSuppliers = Math.min(data.length, maxItems);
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '32px',
          fontWeight: 600,
          cursor: 'pointer',
          textAlign: 'center'
        }}
      >
        <tspan x="46%" dy="-0.6em">Top {totalSuppliers}</tspan>
        <tspan x="46%" dy="1.2em">{chartLabel}</tspan>

      </text>
    )
  }


  return (
    <>
      {data.length > 0 && <ResponsivePie
        data={data}
        theme={{
          labels: {
            text: { fontWeight: 600, textShadow: '1px 1px 2px rgb(255 255 255 / 50%)' }
          },
        }}
        margin={{ top: 20, right: 10, bottom: 20, left: 70 }}
        innerRadius={0.6}
        padAngle={0.6}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        // colors={{ datum: 'data.color' }}
        colors={{ scheme: 'paired' }}

        enableArcLabels={true}
        arcLabel={d => d.value + ''}
        arcLabelsSkipAngle={9}
        enableArcLinkLabels={true}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabel={d => `${(d.id + '').substr(0, MAX_LABEL_LEN)}`}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}

        tooltip={
          (d) => {
            return (
              <div className="tt-body">
                <div className="tt-row"><span className="tt-sqr"
                  style={{ 'background': d.datum.color }}></span>
                  <span>{d.datum.id}: <strong>{parseFloat(d.datum.value + '').toLocaleString().replace(/,/g, ' ')}</strong></span></div>
              </div>
            )
          }}
        legends={legendStyle}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      />
      }
    </>
  )
}

export default ChartBuilderPie;