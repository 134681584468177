import { createContext, useContext, useState } from "react";
import { getUser } from "../services/api-services";
import { parseJwt } from "./utils";

const initialVal = {
  user: null,
  signIn: () => { },
  signOut: () => { }
}
export const authContext = createContext(initialVal);

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function checkLocalToken() {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) return null;
  const { iat, exp, user } = parseJwt(authToken);
  if (exp) {
    // checks wheter the token is still valid
    const ctTime = new Date().getTime();
    const expInMillSec = exp * 1000;
    if (ctTime < expInMillSec) {
      return { iat, exp, user };
    };
  }
  return { user: null };
}

function useProvideAuth() {
  const parsedToken: any = checkLocalToken();
  const [user, setUser] = useState(parsedToken ? parsedToken.user : {});

  const signIn = () => {
    console.log('singin...')
    return new Promise((resolve) => {
      const getUserReq = getUser();
      getUserReq.promise
        .then((res: any) => {
          if (res.id) {
            console.log('set res')
            setUser(res);
          }
        })
        .catch((error: any) => {
          console.log(error);
          window.location.href = "/login";
        })
        .finally(() => {
          resolve(true);
        })
    })
  };

  const signOut = () => {
    window.location.href = "/SignOutApp"
  };

  return {
    user,
    signIn,
    signOut
  };
}