export const appConfig = {
  api: {
    baseUrl: 'https://toxycoin.alwaysdata.net/v1', //'https://toxycoin-api.onrender.com/v1',
    // baseUrl: 'http://localhost:3002/v1',
    token: 'abc'
  },
  theme: [
    {
      name: 'snow',
      snowCount: 10
    }
  ],
  handleKey: 'handle',
  memberIdKey: 'memberId',
  gitlabAuthTokenKey: 'GitlabAuthTokenKey'
}

export const userConfig = {
  activeTheme: appConfig.theme[0],
  prefersDarkMode: false,
  topcoderHandle: '',
  memberId: '',
  headerAnimation: false
}