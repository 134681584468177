export const marketConfig: any = {
  smallCap: {
    "g": 1,
    "l": 30000
  },
  midCap: {
    "g": 30000,
    "l": 90000
  },
  largeCap: {
    "g": 90000,
    "l": 2802983.00
  }
}

export default marketConfig;