import ChartBuilder from "./chartBuilder";
import dataCsv from "./demo-data";
import TemplateDefault from "./template-default";

const Demo = () => {


  return (
    <>
      <TemplateDefault
        layout={{
          mainView: 12
        }}
      >
        <ChartBuilder switchLayout={()=>{}} meta={{
          dashboardName: 'WW DMFI - Backlog Management',
          profileName: 'Lorem_profileName',
          alert: 'WW -DMFI - S4 Fallout'
        }}
        loadingDrilldown='true'
        drilldown={{
          omtConditions: {}
        }}
        drilldownData={dataCsv}
         />
      </TemplateDefault>
    </>
  )
}

export default Demo;