import React, { useReducer } from "react";
import global from "./global.reducer";
import { initialState } from "./initial-state";
import holding from "./holding.ctx";
import { screener } from "./screener";
import { notification } from "./notification";

const combineReducers = (slices: any) => (state: any, action: any) => {
  const r = Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );
  return r;
}
const rootReducer = combineReducers({ global, holding, screener, notification });
export const Context = React.createContext({});

export const Store = (props: any) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  // Important(!): memoize array value. Else all context consumers update on *every* render
  const value = React.useMemo(() => { return { state, dispatch } }, [state]);
  return (
    <Context.Provider value={value}>{props.children}</Context.Provider>
  );
}

export default Store;
