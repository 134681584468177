import { useEffect, useContext } from "react";
import { Context } from '../../context/store'
import { useAuth } from '../../common/use-auth'
import { getStockInfo, getInstruments } from "../../services/api-services";
import { getStockSectorMapping, generateInstumentMapping } from "../../common/utils-init";
const REFRESH_INTERVAL = 12 * 60 * 60 * 1000; //12hrs in ms

export const AppSetup = () => {
  const auth = useAuth();
  const { dispatch, state }: any = useContext(Context);

  useEffect(() => {
    if (auth.user) {
      dispatch({ type: 'SET_LOADING', payload: true });
      const setMapping = (response: any) => {
        const ssm = getStockSectorMapping(response);
        dispatch({ type: 'SET_STOCK_MAPPING', payload: ssm });
        dispatch({ type: 'SET_LOADING', payload: false });
      }

      let localSM: any = localStorage.getItem('stockMappingReq');
      localSM = localSM ? JSON.parse(localSM) : {};
      const today: any = new Date();
      const updatedAt: any = new Date(localSM.time ? localSM.time : '')
      const timeDiff = Math.abs(today - updatedAt);
      const authToken = localStorage.getItem('authToken');
      if (localSM.data && timeDiff <= REFRESH_INTERVAL) {
        setMapping(localSM.data);
      } else if (authToken) {
        const stockMappingReq: any = getStockInfo();
        stockMappingReq.promise
          .then((response: any) => {
            const saveObj: any = {
              time: new Date(),
              data: response
            }
            localStorage.setItem('stockMappingReq', JSON.stringify(saveObj));
            setMapping(saveObj.data);
          })
          .catch((error: any) => {
            console.log(error);
          })
      }
    }
  }, [auth.user, dispatch])


  useEffect(() => {
    if (auth.user) {
      dispatch({ type: 'SET_LOADING', payload: true });

      const setInstruments = (data: any) => {
        dispatch({ type: 'SET_ALL_INSTRUMENTS', payload: data });
        dispatch({ type: 'SET_LOADING', payload: false });
      }

      let localSM: any = localStorage.getItem('allInstruments');
      localSM = localSM ? JSON.parse(localSM) : {};
      const today: any = new Date();
      const updatedAt: any = new Date(localSM.time ? localSM.time : '')
      const timeDiff = Math.abs(today - updatedAt);
      const authToken = localStorage.getItem('authToken');
      const allInstrumentsKeys = Object.keys(state.global.allInstruments);
      if (localSM.data && (timeDiff <= REFRESH_INTERVAL || allInstrumentsKeys.length <= 0) ) {
        setInstruments(localSM.data);
      } else if (authToken) {
        const getInstrumentsReq: any = getInstruments();
        getInstrumentsReq.promise
          .then((response: any) => {
            if (typeof (response) === 'string' || response.status !== "e") {
              const saveObj: any = {
                time: new Date(),
                data: response
              }
              localStorage.setItem('allInstruments', JSON.stringify(saveObj));
              setInstruments(saveObj.data);
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
      }
    }
  }, [auth.user, dispatch])

  useEffect(() => {
    if (auth.user) {
      const instrumentLabels = generateInstumentMapping(state.global.allInstruments);
      dispatch({ type: 'SET_INSTRUMENT_LABELS', payload: instrumentLabels });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.global.allInstruments])

  return <></>
}

export default AppSetup;