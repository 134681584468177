export const sectors = [
  {
    id: 'energy',
    name: 'Energy',
    sym: 'Energy',
    color: '#00897b'
  },
  {
    id: 'it',
    name: 'Information Technology',
    sym: 'IT',
    color: '#1e88e5'
  }, {
    id: 'financials',
    name: 'Financials',
    sym: 'ConS',
    color: '#8e24aa'
  }, {
    id: 'cs',
    name: 'Consumer Staples',
    sym: 'Consumer',
    color: '#5e35b1'
  }, {
    id: 'comms',
    name: 'Communication Services',
    sym: 'Comms',
    "color": '#64c8b6'
  }, {
    id: 'mat',
    name: 'Materials',
    sym: 'Mat',
    "color": '#6d4c41'
  }, {
    id: 'consumerDisc',
    name: 'Consumer Discretionary',
    sym: 'ConD',
    "color": '#d81b60'
  }, {
    id: 'indus',
    name: 'Industrials',
    sym: 'Indus',
    "color": '#e53935'
  }, {
    id: 'halth',
    name: 'Health Care',
    sym: 'Health',
    "color": '#7cb342'
  }, {
    id: 'utilities',
    name: 'Utilities',
    sym: 'Utilities',
    "color": '#43a047'
  }, {
    id: 'realEstate',
    name: 'Real Estate',
    sym: 'Real Estate',
    "color": '#c0ca33'
  }, {
    id: 'unlisted',
    name: 'Unlisted',
    sym: 'Unlisted',
    "color": '#546e7a'
  }
]

export const allSectors = [
  {
    "name": "Consumer Discretionary",
    "label": "CD",
    "color": '#d81b60',
    "subindustries": [
      {
        "label": "AUP",
        "name": "Auto Parts"
      },
      {
        "label": "T_R",
        "name": "Tires & Rubber"
      },
      {
        "label": "FOW",
        "name": "Four Wheelers"
      },
      {
        "label": "THW",
        "name": "Three Wheelers"
      },
      {
        "label": "TWW",
        "name": "Two Wheelers"
      },
      {
        "label": "CYC",
        "name": "Cycles"
      },
      {
        "label": "EDS",
        "name": "Education Services"
      },
      {
        "label": "WES",
        "name": "Wellness Services"
      },
      {
        "label": "HRC",
        "name": "Hotels, Resorts & Cruise Lines"
      },
      {
        "label": "R_C",
        "name": "Restaurants & Cafes"
      },
      {
        "label": "T_G",
        "name": "Theme Parks & Gaming"
      },
      {
        "label": "T_T",
        "name": "Tour & Travel Services"
      },
      {
        "label": "HEA",
        "name": "Home Electronics & Appliances"
      },
      {
        "label": "HFU",
        "name": "Home Furnishing"
      },
      {
        "label": "HOU",
        "name": "Housewares"
      },
      {
        "label": "R_A",
        "name": "Retail - Apparel"
      },
      {
        "label": "R_D",
        "name": "Retail - Department Stores"
      },
      {
        "label": "R_O",
        "name": "Retail - Online"
      },
      {
        "label": "R_S",
        "name": "Retail - Speciality"
      },
      {
        "label": "A_A",
        "name": "Apparel & Accessories"
      },
      {
        "label": "FOO",
        "name": "Footwear"
      },
      {
        "label": "PJW",
        "name": "Precious Metals, Jewellery & Watches"
      },
      {
        "label": "TEX",
        "name": "Textiles"
      },
      {
        "label": "ANI",
        "name": "Animation"
      }
    ]
  },
  {
    "name": "Communication Services",
    "label": "TS",
    "color": '#64c8b6',
    "subindustries": [
      {
        "label": "ADV",
        "name": "Advertising"
      },
      {
        "label": "C_D",
        "name": "Cable & D2H"
      },
      {
        "label": "M_T",
        "name": "Movies & TV Serials"
      },
      {
        "label": "PBL",
        "name": "Publishing"
      },
      {
        "label": "RAD",
        "name": "Radio"
      },
      {
        "label": "THE",
        "name": "Theatres"
      },
      {
        "label": "TVB",
        "name": "TV Channels & Broadcasters"
      },
      {
        "label": "ONL",
        "name": "Online Services"
      },
      {
        "label": "T_E",
        "name": "Telecom Equipments"
      },
      {
        "label": "T_I",
        "name": "Telecom Infrastructure"
      },
      {
        "label": "T_S",
        "name": "Telecom Services"
      }
    ]
  },
  {
    "name": "Consumer Staples",
    "label": "CS",
    "color": '#5e35b1',
    "subindustries": [
      {
        "label": "ALC",
        "name": "Alcoholic Beverages"
      },
      {
        "label": "SOF",
        "name": "Soft Drinks"
      },
      {
        "label": "T_C",
        "name": "Tea & Coffee"
      },
      {
        "label": "AGR",
        "name": "Agro Products"
      },
      {
        "label": "F_F",
        "name": "FMCG - Foods"
      },
      {
        "label": "P_M",
        "name": "Packaged Foods & Meats"
      },
      {
        "label": "SEE",
        "name": "Seeds"
      },
      {
        "label": "SUG",
        "name": "Sugar"
      },
      {
        "label": "F_H",
        "name": "FMCG - Household Products"
      },
      {
        "label": "F_P",
        "name": "FMCG - Personal Products"
      },
      {
        "label": "F_T",
        "name": "FMCG - Tobacco"
      }
    ]
  },
  {
    "name": "Energy",
    "label": "EN",
    "color": '#00897b',
    "subindustries": [
      {
        "label": "OES",
        "name": "Oil & Gas - Equipment & Services"
      },
      {
        "label": "OEP",
        "name": "Oil & Gas - Exploration & Production"
      },
      {
        "label": "ORM",
        "name": "Oil & Gas - Refining & Marketing"
      },
      {
        "label": "OST",
        "name": "Oil & Gas - Storage & Transportation"
      }
    ]
  },
  {
    "name": "Financials",
    "label": "FN",
    "color": '#8e24aa',
    "subindustries": [
      {
        "label": "PRI",
        "name": "Private Banks"
      },
      {
        "label": "PUB",
        "name": "Public Banks"
      },
      {
        "label": "ASS",
        "name": "Asset Management"
      },
      {
        "label": "I_B",
        "name": "Investment Banking & Brokerage"
      },
      {
        "label": "S_R",
        "name": "Stock Exchanges & Ratings"
      },
      {
        "label": "CUS",
        "name": "Consumer Finance"
      },
      {
        "label": "DIV",
        "name": "Diversified Financials"
      },
      {
        "label": "SPE",
        "name": "Specialized Finance"
      },
      {
        "label": "INS",
        "name": "Insurance"
      },
      {
        "label": "H_F",
        "name": "Home Financing"
      },
      {
        "label": "PMT",
        "name": "Payment Infrastructure"
      }
    ]
  },
  {
    "name": "Health Care",
    "label": "HC",
    "color": '#7cb342',
    "subindustries": [
      {
        "label": "BIO",
        "name": "Biotechnology"
      },
      {
        "label": "H_E",
        "name": "Health Care Equipment & Supplies"
      },
      {
        "label": "H_D",
        "name": "Hospitals & Diagnostic Centres"
      },
      {
        "label": "LLS",
        "name": "Labs & Life Sciences Services"
      },
      {
        "label": "PHA",
        "name": "Pharmaceuticals"
      }
    ]
  },
  {
    "name": "Industrials",
    "label": "IN",
    "color": '#e53935',
    "subindustries": [
      {
        "label": "ARL",
        "name": "Airlines"
      },
      {
        "label": "BPC",
        "name": "Building Products - Ceramics"
      },
      {
        "label": "B_G",
        "name": "Building Products - Glass"
      },
      {
        "label": "BPG",
        "name": "Building Products - Granite"
      },
      {
        "label": "BPL",
        "name": "Building Products - Laminates"
      },
      {
        "label": "B_P",
        "name": "Building Products - Pipes"
      },
      {
        "label": "BSS",
        "name": "Business Support Services"
      },
      {
        "label": "STA",
        "name": "Stationery"
      },
      {
        "label": "C_E",
        "name": "Construction & Engineering"
      },
      {
        "label": "BAT",
        "name": "Batteries"
      },
      {
        "label": "CAB",
        "name": "Cables"
      },
      {
        "label": "ECE",
        "name": "Electrical Components & Equipments"
      },
      {
        "label": "HEE",
        "name": "Heavy Electrical Equipments"
      },
      {
        "label": "CON",
        "name": "Conglomerates"
      },
      {
        "label": "A_F",
        "name": "Agricultural & Farm Machinery"
      },
      {
        "label": "H_M",
        "name": "Heavy Machinery"
      },
      {
        "label": "I_M",
        "name": "Industrial Machinery"
      },
      {
        "label": "RAI",
        "name": "Rail"
      },
      {
        "label": "SHI",
        "name": "Shipbuilding"
      },
      {
        "label": "TRA",
        "name": "Tractors"
      },
      {
        "label": "T_B",
        "name": "Trucks & Buses"
      },
      {
        "label": "EMP",
        "name": "Employment Services"
      },
      {
        "label": "AIR",
        "name": "Airports"
      },
      {
        "label": "DRE",
        "name": "Dredging"
      },
      {
        "label": "LOG",
        "name": "Logistics"
      },
      {
        "label": "POR",
        "name": "Ports"
      },
      {
        "label": "ROA",
        "name": "Roads"
      },
      {
        "label": "RES",
        "name": "Renewable Energy Equipment & Services"
      },
      {
        "label": "BPP",
        "name": "Building Products - Prefab Structures"
      },
      {
        "label": "C_T",
        "name": "Commodities Trading"
      },
      {
        "label": "ADE",
        "name": "Aerospace & Defense Equipments"
      }
    ]
  },
  {
    "name": "Information Technology",
    "label": "IT",
    "color": '#1e88e5',
    "subindustries": [
      {
        "label": "C_N",
        "name": "Communication & Networking"
      },
      {
        "label": "E_E",
        "name": "Electronic Equipments"
      },
      {
        "label": "T_H",
        "name": "Technology Hardware"
      },
      {
        "label": "ISC",
        "name": "IT Services & Consulting"
      },
      {
        "label": "O_S",
        "name": "Outsourced services"
      },
      {
        "label": "S_S",
        "name": "Software Services"
      }
    ]
  },
  {
    "name": "Materials",
    "label": "MT",
    "color": "#e4c0a2",
    "subindustries": [
      {
        "label": "C_C",
        "name": "Commodity Chemicals"
      },
      {
        "label": "D_C",
        "name": "Diversified Chemicals"
      },
      {
        "label": "F_A",
        "name": "Fertilizers & Agro Chemicals"
      },
      {
        "label": "PAI",
        "name": "Paints"
      },
      {
        "label": "PLA",
        "name": "Plastic Products"
      },
      {
        "label": "SPC",
        "name": "Specialty Chemicals"
      },
      {
        "label": "CEM",
        "name": "Cement"
      },
      {
        "label": "PAC",
        "name": "Packaging"
      },
      {
        "label": "I_S",
        "name": "Iron & Steel"
      },
      {
        "label": "MEA",
        "name": "Metals - Aluminium"
      },
      {
        "label": "MEC",
        "name": "Metals - Coke"
      },
      {
        "label": "MEO",
        "name": "Metals - Copper"
      },
      {
        "label": "MEI",
        "name": "Metals - Diversified"
      },
      {
        "label": "MEL",
        "name": "Metals - Lead"
      },
      {
        "label": "MIC",
        "name": "Mining - Coal"
      },
      {
        "label": "MIO",
        "name": "Mining - Copper"
      },
      {
        "label": "MID",
        "name": "Mining - Diversified"
      },
      {
        "label": "MII",
        "name": "Mining - Iron Ore"
      },
      {
        "label": "MIM",
        "name": "Mining - Manganese"
      },
      {
        "label": "P_P",
        "name": "Paper Products"
      },
      {
        "label": "W_P",
        "name": "Wood Products"
      }
    ]
  },
  {
    "name": "Real Estate",
    "label": "RE",
    "color": '#c0ca33',
    "subindustries": [
      {
        "label": "REE",
        "name": "Real Estate"
      }
    ]
  },
  {
    "name": "Utilities",
    "label": "UT",
    "color": '#43a047',
    "subindustries": [
      {
        "label": "P_I",
        "name": "Power Infrastructure"
      },
      {
        "label": "PTD",
        "name": "Power Transmission & Distribution"
      },
      {
        "label": "G_D",
        "name": "Gas Distribution"
      },
      {
        "label": "PTC",
        "name": "Power Trading & Consultancy"
      },
      {
        "label": "P_G",
        "name": "Power Generation"
      },
      {
        "label": "R_E",
        "name": "Renewable Energy"
      },
      {
        "label": "ETM",
        "name": "Water Management"
      }
    ]
  },
  {
    "name": "ETF",
    "label": "ET",
    "subindustries": [
      {
        "label": "E_G",
        "name": "Gold"
      },
      {
        "label": "E_Q",
        "name": "Equity"
      },
      {
        "label": "E_D",
        "name": "Debt"
      }
    ]
  }
]

export const userHolding = ["ADIA","JUBI","ADNA","APSE","ADAN","AEGS","ANGE","APLA","ADAG","AWL","BCG-BE","BAJE","BFRG","BORO","BPCL","BIRS","COAL","COFO","CRSL","DLMI","ENDU","PROS","GAIL","GRVL","GSPT","HAPP","HSNT","IIAN","IGAS","IOC","KPIE","LAOP","LOD","LART","LRTI","MAHM","MAST","UNSP","MINT","MSU","NHPC","NTPC","NYK","ONGC","PLNG","MAGM","PGRD","REDI","RELI","SARE","SJVN","SOBH","SNFS","TTEX","TAMO","TAMdv","TTPW","TITN","TRIE","TTML","UBBW","VRLL","VART","ZENT"];