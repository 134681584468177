
import MarketConfig from "../../config/market-config";

// key for data that shows the count of each mf portfilio
export const MF_STOCK_KEY = 'StockMfAllocation'; 

export const stockGroupData: any = {
  largeCap: [],
  midCap: [],
  smallCap: [],
  custom: [],
  dynamicCap: []
};
export const chartTypes = [
  {
    id: 'line',
    label: 'Line'
  },
  {
    id: 'bar',
    label: 'Bar'
  },
  {
    id: 'performance',
    label: 'Day Range'
  }]

export const iFilter: any = {
  sectors: []
}


export const durationOptsDefault = [
  {
    name: 'pr1d',
    label: '1D',
    checked: true
  }, {
    name: 'pr1w',
    label: '1W',
    checked: false
  }, {
    name: '4wpct',
    label: '1M',
    checked: false
  }, {
    name: '26wpct',
    label: '6M',
    checked: false
  }, {
    name: '52wpct',
    label: '1Y',
    checked: false
  }, {
    name: '5yCagrPct',
    label: '5Y',
    checked: false
  }, {
    name: 'vol1dChPct',
    label: '1d Vol',
    checked: false
  }

]

export const defaultCommonQuery = {
    "match": {
      "mrktCapf": MarketConfig.largeCap
    },
    "sortBy": "mrktCapf",
    "sortOrder": -1,
    "sids": [],
    "project": [
      "subindustry",
      "mrktCapf",
      "lastPrice",
      "apef",
      "4wpct",
      "pr1w",
      "pr1d",
      "26wpct",
      "52wpct",
      "5yCagrPct",
      "vol1dChPct",
      "52whd",
      "52wld"
    ],
    "offset": 0,
    "count": 500
  };