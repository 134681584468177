// Reducer function
export const notificatonReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        open: true,
        severity: (action.payload?.severity ),
        message: (action.payload?.message || '')
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        open: false,
        severity: 'success',
        message: ''
      };
    default:
      return state;
  }
}

export default notificatonReducer;