/**
 * line chart component
 */
import { ResponsiveLine } from '@nivo/line'
import { formatNumber } from "../common/utils";
import './chart.scss'

const T100Line = ({ data }: any) => {
  const CustomSymbol = (d: any) => {
    const color = d.datum.color;
    return (
      <g>
        <circle fill={color + '55'} r={d.size / 2} strokeWidth={d.borderWidth} stroke={color} />
      </g>
    )
  }

  const commonProperties: any = {
    margin: { top: 50, right: 110, bottom: 50, left: 60 },
    animate: true,
    enableSlices: 'x',
    enableGridX: false
  }

  return (
    <ResponsiveLine
      {...commonProperties}
      data={data}

      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat=" >-.2f"
      enablePointLabel={true}
      axisTop={null}
      axisRight={null}
      enableArea={true}
      pointLabel={(value: any) => value.y > 5000 ? formatNumber(value.y) : value.yFormatted}
      axisLeft={{
        // orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Change',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      axisBottom={{
        // orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      pointSymbol={CustomSymbol}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: '#b0413e', strokeWidth: 1 },
        },
      ]}
      colors={['#F7DBF0', '#FAD4D4','#EF9F9F', '#FFA1C9', '#96CEB4', '#53BF9D', '#DBF7F0' ]}
      sliceTooltip={(d: any) => {
        const pt = d.slice.points.length >= 1 ? d.slice.points[0] : {};
        const color = pt.data.color;
        const stk = pt.data.stock;
        // console.log(pt);
        return (
          <div
            className='tooltip tooltip-line'>
            <div className='tooltip-title-rw'>
              <strong style={{ color: color }}>[{pt.data.ticker}]</strong>
              <span>{pt.index + 1} </span>
            </div>
            <div>{pt.data.name}</div>
            <div>
              <div><strong>LTP: {stk.advancedRatios.lastPrice?.toFixed(1)}</strong>
              </div>
              <div>
                <table className="tooltip-table">
                  <tbody>
                    <tr><th>1d</th>
                      <th>1w</th>
                      <th>1m</th>
                      <th>6m</th>
                      <th>1y</th>
                      <th>1dVol</th></tr>
                    <tr>
                      <td>{stk.advancedRatios['pr1d']?.toFixed(1)}</td>
                      <td>{stk.advancedRatios['pr1w']?.toFixed(1)}</td>
                      <td>{stk.advancedRatios['4wpct']?.toFixed(1)}</td>
                      <td>{stk.advancedRatios['26wpct']?.toFixed(1)}</td>
                      <td>{stk.advancedRatios['52wpct']?.toFixed(1)}</td>
                      <td>{formatNumber(stk.advancedRatios['vol1dChPct'])}</td>
                    </tr></tbody>
                </table></div>
            </div>
          </div>
        )
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          toggleSerie: true,
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}

export default T100Line;
