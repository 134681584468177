/**
 * profile pie component
 */
import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import './chart.scss'

const ProfilePie = ({ data, sortBy }: any) => {
  // const [colors, setColors] = useState([]);
  const commonProperties: any = {
    margin: { top: 10, right: 32, bottom: 20, left: 32 },
    animate: true,
    enableSlices: 'x',
    enableGridX: false
  }

  const updateData = (data: any, sort_key: any) => {
    const nd = [...data];
    return nd.sort((a: any, b: any) => Math.abs(b[sort_key]) - Math.abs(a[sort_key]))
  }
  data = updateData(data, sortBy);
  const updateColors = (data: any) => {
    let colors = data.map((d: any) => d.color);
    if (sortBy === 'day_change_percentage') {
      colors = data.map((d: any) => {
        return d[sortBy] >= 0 ? '#19af55aa' : '#d82f44aa';
      });
    }
    return colors;
  }
  const colors = updateColors(data);

  return (
    <ResponsivePie
      {...commonProperties}
      data={data}
      id={'tradingsymbol'}
      value={(d: any) => Math.abs(d[sortBy])}
      valueFormat={">-.2f"}
      arcLinkLabel={(d: any) => { return d.id.substr(0, 4) }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={5}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsDiagonalLength= {10}
      arcLinkLabelsStraightLength= {4}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      colors={colors}
      tooltip={(d: any) => {
        const pt = d.datum;
        const dt = d.datum.data;
        const color = pt.color;
        // console.log(d);
        return (
          <div
            style={{
              padding: 4,
              background: '#ffffff',
              boxShadow: '1px 1px 2px rgba(0,0,0,0.1)',
              borderRadius: 2,
              border: '1px solid #f6f6f6',
              fontSize: 12
            }}>
            <strong style={{ color: color }}>{pt.id}</strong>
            <div>{sortBy}: <strong>
              {pt.formattedValue}%
            </strong></div>
            <div>
              <table className="tooltip-table tt-pie">
                <tbody>
                  <tr><th>Invested</th>
                    <th>Current</th>
                    <th>Pnl</th>
                    <th>% Day</th>
                    <th>% Net</th></tr>
                  <tr>
                    <td>{dt.invested_amt.toFixed(1)}</td>
                    <td>{dt.cur_val.toFixed(1)}</td>
                    <td>{(dt.cur_val - dt.invested_amt).toFixed(1)}</td>
                    <td>{dt.day_change_percentage?.toFixed(1)}</td>
                    <td>{dt.net_change_percentage?.toFixed(1)}</td>
                  </tr></tbody>
              </table></div>
          </div>
        )
      }}
    />
  )
}

export default ProfilePie;
