import React, { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper, TextField, Stack, Button, Snackbar, Alert, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CircularProgress from '@mui/material/CircularProgress';
import TemplateDefault from "./template-default";
import AppHeader from '../components/app-header';
import useStyles from './stock-line.css';
import { updateUser } from "../services/api-services";


const UserSettings = () => {
  const classes = useStyles();
  const [state, setState]: any = useState({
    openSnack: false,
    snackSeverity: 'success',
    snackMsg: ''
  })
  const [form, setForm]: any = useState({
    siteCookie: ''
  })

  const saveHandler = () => {
    const updateUserReq = updateUser({ "siteCookie": form.siteCookie?.trim() });
    updateUserReq.promise
      .then((res: any) => {
        if (res.success) {
          setState({ ...state, snackMsg: 'Site cookie saved', setSnackSeverity: 'success', openSnack: true });
        }
      })
      .catch((error: any) => {
        setState({ ...state, snackMsg: error.message, setSnackSeverity: 'error', openSnack: true });
      })
  }
  const removeHandler = () => {
    const updateUserReq = updateUser({ "siteCookie": "user_id=saf; public_token=dd; enctoken=sadfas" });
    updateUserReq.promise
      .then((res: any) => {
        if (res.success) {
          setState({ ...state, snackMsg: 'Removed sucessfully', setSnackSeverity: 'success', openSnack: true });
        }
      })
      .catch((error: any) => {
        setState({ ...state, snackMsg: error.message, setSnackSeverity: 'error', openSnack: true });
      })
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, openSnack: false })
  }

  return (
    <>
      <TemplateDefault
        isSideBarOpen={true}
        layout={{
          mainView: 12,
          isLeftSidebar: true,
          isRightSidebar: false
        }}

        header={
          (toggleDrawer: any) => {
            return (
              <AppHeader
                handleDrawerOpen={() => { return }}
                challengesFlag={false}
                getChallenges={() => { return }}
                open={false}
                lastUpdated={''}
                activeIndex={-1}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        }

        sidebar={
          <div className={classes.sidebar}>
            <Accordion defaultExpanded elevation={0} square >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p>Site Cookie</p>
              </AccordionDetails>
            </Accordion>
          </div>
        }
      >
        <Container className="form">
          <h2>User Settings</h2>
          <Stack spacing={2}>
            <TextField size="small" label="Site Cookie" variant="outlined"
              value={form.siteCookie}
              multiline
              maxRows={4}
              onChange={(e: any) => setForm({ ...form, siteCookie: e.target.value })} />
            <Stack direction="row" spacing={5}>
              <Button variant="contained" onClick={saveHandler}>Save</Button>
              <Button variant="contained" color='secondary' onClick={removeHandler}>Remove</Button>
            </Stack>
          </Stack>
        </Container>
        <Snackbar open={state.openSnack} autoHideDuration={6000} onClose={state.handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity={state.snackSeverity} sx={{ width: '100%' }}>
            {state.snackMsg}
          </Alert>
        </Snackbar>
      </TemplateDefault>
    </>
  )
}

export default UserSettings;