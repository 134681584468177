import { Slider } from '@mui/material';

import './styles.scss'


const RangeSlider = ({ keyVal = '', color = 'secondary', min = 0, max = 100, value = 0 }: any) => {

  const marks = [
    {
      value: min,
      label: min,
    },
    {
      value: max,
      label: max,
    },
  ];

  return (
    <div className="range-slider" >
      <Slider
        key={keyVal}
        aria-label="Always visible"
        defaultValue={value}
        min={min}
        step={1}
        max={max}
        marks={marks}
        color={color}
        valueLabelDisplay="auto"
        size="small"
      />
    </div>
  );
}

export default RangeSlider;