import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Route, Redirect
} from 'react-router-dom';
import AppSetup from "./components/app-setup/app-setup";
import { isLoggedTApp } from "./common/utils";
import { useAuth, ProvideAuth } from './common/use-auth';
import Store from "./context/store";


import { routes, securedRoutes } from './routes';

import './App.scss';
import theme from "./theme.css";
import useStyles from './app.css';

function App() {
  const classes = useStyles();
  const defaultNav: any = localStorage.getItem('defaultNav');


  // screen if you're not yet authenticated.
  function PrivateRoute({ children, ...rest }: any) {
    let auth = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !!auth.user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }


  // Helper function that reders single route
  const renderRoute = (route: any, props: any) => {
    const { privateRoute = false } = route.pageConfig ? route.pageConfig : {};
    window.scrollTo(0, 0); // Reset scroll to top
    return (
      privateRoute
        ?
        <PrivateRoute>
          <route.component routeParams={props.match.params} />
        </PrivateRoute>

        :
        <>
          <route.component routeParams={props.match.params} />
        </>
    );
  };

  // Helper function that create all routes
  const createRoutes = (state: any) => {
    const routesToRender = isLoggedTApp() ? securedRoutes : routes;
    const r = routesToRender.map((route) => (
      <Route
        exact
        key={route.path}
        path={route.path}
        component={(props: any) => renderRoute(route, props)}>
        {/* {defaultNav && <Redirect from="/" to={defaultNav} />} */}
      </Route>
    ));
    defaultNav && r.push(
      <Route key={'dp'} exact path="/" render={() => (<Redirect to={defaultNav} />)} />
    )
    return r;
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Store>
        <ProvideAuth>
          <Router>
            <div className={`app ${classes.app}`}>
              {createRoutes({})}
            </div>
          </Router>
          <AppSetup />
        </ProvideAuth>
      </Store>
    </ThemeProvider>
  );
}

export default App;
