import { useEffect, useRef, memo } from "react";
import "./TradingViewWidget.scss";

function TradingViewStock({ symbol = "BSE:HAL" }: any) {
  const container = useRef();
  const getConfig = (symbol) => ({
    autosize: true,
    symbol: symbol,
    interval: "D",
    timezone: "Asia/Kolkata",
    theme: "light",
    style: "1",
    locale: "en",
    enable_publishing: false,
    hide_side_toolbar: true,
    allow_symbol_change: true,
    // "watchlist": [
    //   "BSE:HAL",
    //   "BSE:BEL"
    // ],
    calendar: false,
    studies: [
      "STD;Seasonality"
    ]
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify(getConfig(symbol));

    container.current.innerHTML = "";
    container.current.appendChild(script);
  }, [symbol]);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(TradingViewStock);
