import { createElement, MouseEvent, useCallback, useMemo } from 'react'
import { ResponsiveBar, BarDatum, BarItemProps } from '@nivo/bar'
import { useTooltip } from '@nivo/tooltip'
import { useTheme } from '@nivo/core'
import { animated, to } from '@react-spring/web'


const T100Bar = ({ data }: any) => {

  const commonProperties: any = {
    margin: { top: 50, right: 110, bottom: 50, left: 60 },
    animate: true,
    enableSlices: 'x',
    enableGridX: false
  }

  const CustomBarComponent = <RawDatum extends BarDatum>({
    bar: { data, ...bar },

    style: {
      color,
      height,
      transform,
      width,
      x,
      y
    },

    borderRadius,
    borderWidth,

    label,
    shouldRenderLabel,

    isInteractive,
    onClick,
    onMouseEnter,
    onMouseLeave,

    tooltip
  }: BarItemProps<RawDatum>) => {
    // const [isHoverd, setIsHovered] = useState(false);
    // const [tf, setTf] = useState(transform);
    const theme = useTheme()
    const { showTooltipFromEvent, hideTooltip } = useTooltip()

    // const tf = isHoverd ? tfb : transform;
    // const tf = useSpring({
    //   transform: isHoverd
    //     ? `translate(${x}, ${y}) scale(1.2, 1)`
    //     : `translate(${x}, ${y})`
    // })
    // });
    // console.log(transform);

    const renderTooltip = useMemo(() => () => createElement(tooltip, { ...bar, ...data }), [
      tooltip,
      bar,
      data,
    ])

    const handleClick = useCallback(
      (event: MouseEvent<SVGRectElement>) => {
        onClick?.({ color: bar.color, ...data }, event)
      },
      [bar, data, onClick]
    )
    const handleTooltip = useCallback(
      (event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
      [showTooltipFromEvent, renderTooltip]
    )
    const handleMouseEnter = useCallback(
      (event: MouseEvent<SVGRectElement>) => {
        onMouseEnter?.(data, event)
        showTooltipFromEvent(renderTooltip(), event)
      },
      [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
    )
    const handleMouseLeave = useCallback(
      (event: MouseEvent<SVGRectElement>) => {
        onMouseLeave?.(data, event)
        hideTooltip()
      },
      [data, hideTooltip, onMouseLeave]
    )

    return (
      <animated.g transform={transform}>
        <animated.rect
          width={to(width, value => Math.max(value, 0))}
          height={to(height, value => Math.max(value, 0))}
          rx={borderRadius}
          ry={borderRadius}
          fill={data.fill ?? color}
          strokeWidth={borderWidth}
          stroke={color}
          focusable={false}
          onMouseEnter={isInteractive ? handleMouseEnter : undefined}
          onMouseMove={isInteractive ? handleTooltip : undefined}
          onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          onClick={isInteractive ? handleClick : undefined}
        />
        {shouldRenderLabel && (
          <animated.text
            x={bar.width / 2}
            y={-10}
            textAnchor="middle"
            dominantBaseline="central"
            fillOpacity={1}
            style={{
              ...theme.labels.text,
              pointerEvents: 'none',
              fill: '#333',
            }}
          >
            {label}
          </animated.text>
        )}
      </animated.g>
    )
  }

  return (
    <ResponsiveBar
      {...commonProperties}
      data={data[0].data}
      indexBy="x"
      keys={['y']}
      valueFormat=" >-1.1f"

      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      barComponent={CustomBarComponent}

      onClick={(d: any) => { console.log(d); }}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}

      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Stock',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Change',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: '#b0413e', strokeWidth: 1 },
        },
      ]}
      tooltip={(d: any) => {
        const pt = d;
        const color = d.color;
        return (
          <div
            style={{
              padding: 4,
              background: '#ffffff',
              boxShadow: '1px 1px 2px rgba(0,0,0,0.1)',
              borderRadius: 2,
              border: '1px solid #f6f6f6',
              fontSize: 12
            }}>
            <strong style={{ color: color }}>[{pt.data.ticker}]</strong> {pt.data.name}
            <div>Change: <strong>
              {pt.formattedValue}%
            </strong></div>
          </div>
        )
      }}
    />
  )
}

export default T100Bar;
