import "./ScreenerChart.scss";
import React from "react";
import { LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import { profileInitialState, profileSorting } from "../../container/profile-table-config";
import { screenerInitialState, getScreenerCols } from "./screener-chart-config";

const ScreenerChart = ({ data = [], loading = false }: any) => {
  const screenerData = data.map((item: any) => {
    return {
      id: item.sid, sid: item.sid, name:item.stock?.info?.name, ...item.stock.advancedRatios
    }
  });
  return (
    <React.Fragment>
      <div className="view-table stock-table">

        
        <DataGrid
          rows={screenerData}
          columns={getScreenerCols(false)}
          disableRowSelectionOnClick
          loading={loading}
          hideFooter
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <Typography variant="h6" component="h6">Stocks: {screenerData.length}</Typography>
              </GridToolbarContainer>
            ),
          }}
          initialState={{
            columns: {
              columnVisibilityModel: screenerInitialState
            },
            sorting: profileSorting
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default ScreenerChart;
