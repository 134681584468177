import { useState, useEffect } from 'react'

import ChartBuilderPie from "./chartbuilder-pie";
import { ResponsiveBar } from '@nivo/bar';
import { DownloadIcon, CloseIcon } from "../icons";

import './demo.scss';

const NUM_MS_IN_A_DAY = 86400000;
const ChartTypes = [
  {
    id: "donut",
    label: "Donut chart"
  }, {
    id: "bar",
    label: "Bar chart"
  }
];

const aggregationOptions = [
  {
    id: 'count',
    label: 'Count'
  },
  {
    id: 'countDistinct',
    label: 'Count Distinct'
  }, {
    id: 'sum',
    label: 'Sum'
  }]

const timeFields = [{
  id: 'purch_order_date',
  label: 'Purchase Order Date'
}, {
  id: 'hp_receive_date',
  label: 'HP Receive Date'
}, {
  id: 'order_entry_date',
  label: 'Order Entry Date'
}]

const timeRangeGroups = [{
  id: 'g1',
  label: '< 105d',
  duration: 150 * NUM_MS_IN_A_DAY
}, {
  id: 'g2',
  label: '< 200d',
  duration: 200 * NUM_MS_IN_A_DAY
}, {
  id: 'g3',
  label: '< 250d',
  duration: 250 * NUM_MS_IN_A_DAY
}, {
  id: 'g4',
  label: '< 300d',
  duration: 300 * NUM_MS_IN_A_DAY
}, {
  id: 'g5',
  label: '> 300d',
  durationGt: 300 * NUM_MS_IN_A_DAY
}]

const MAX_ITEMS = 20;
const MAX_LABEL_LEN = 12;

const generateAxisFields = (fieldKeyArray: any) => {
  return fieldKeyArray.map((item: any) => {
    const itemArr = item.split('');
    itemArr[0] = itemArr[0].toUpperCase();
    const label = (itemArr.join('')).replace(/_/g, ' ');
    return {
      id: item,
      label: label
    }
  })
}



// const data: any = [{ "id": "US21", "label": "US21", "value": 8557, "color": "#fd9182" }, { "id": "MX11", "label": "MX11", "value": 6548, "color": "#ffd4a2" }, { "id": "CH92", "label": "CH92", "value": 1551, "color": "#fff8a5" },
// { "id": "CH66", "label": "CH66", "value": 2227, "color": "#deff95" }, { "id": "US01", "label": "US01", "value": 3440, "color": "#8dff7e" }]

const ChartBuilder = (props: any) => {
  const { switchLayout, meta, drilldown, drilldownData }: any = { ...props };
  const [currentChartType, setCurrentChartType]: any = useState(ChartTypes[0]);
  const [currentField, setCurrentField]: any = useState({})
  const [yAxisFields, setYAxisFields]: any = useState([]);
  const [curTimeField]: any = useState(timeFields[0].id);
  const [curAggregateField, setCurAggregateField]: any = useState(aggregationOptions[0]);

  const [data, setData]: any = useState([]);

  /**
   * formats data in the form required to render the chart
   * @param dataObj object
   * @returns array
   */
  const generateChartData = (dataObj: any, isDistinct: any = false) => {
    const r = Object.keys(dataObj).map((key: any) => {
      const sortKey = key.substr(0, MAX_LABEL_LEN) + (key.length > MAX_LABEL_LEN ? '..' : '');
      let value: any = dataObj[key];
      if (isDistinct) {
        // get unique values
        const uniqueValues: any = dataObj[key].reduce((p: any, i: any) => {
          p.indexOf(i) === -1 && p.push(i); return p;
        }, [])
        value = uniqueValues.length;
      }
      return {
        id: key,
        label: sortKey + ': ' + value,
        value: value
      }
    });
    return r;
  }

  useEffect(() => {
    if (drilldownData.nrOfRealRows > 0) {
      const { internalColumns }: any = { ...drilldownData };
      const yFields = generateAxisFields(internalColumns);
      setYAxisFields(yFields);
      setCurrentField(yFields[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (drilldownData.nrOfRealRows > 0) {
      const { internalColumns, rows }: any = { ...drilldownData };
      let chartSeriesObj: any = {};
      setYAxisFields(generateAxisFields(internalColumns));

      if (curAggregateField.id === 'count') {
        /**
         * generates a map between values & it's aggregated value
         * ip: rows = [["cancelled", "delivered"], ["cancelled", "delivered"]] 
         * op: {cancelled: 2, delivered: 2}
         */
        rows.map((d: any, i: any) => {
          const cobj: any = {};
          internalColumns.map((col: any, i: any) => {
            cobj[col] = d[i];
            if (col === currentField?.id) {
              const key = d[i] ? d[i] : 'Unknown';
              chartSeriesObj[key] = chartSeriesObj[key] ? chartSeriesObj[key] + 1 : 1;
            }
            return col;
          })
          return cobj
        })
      }

      if (curAggregateField.id === 'sum') {
        /**
         * generates a map between timeRangeGroups & distinct values of selected entity (like hp_order_no) 
         * ip: rows = [["ord_11", "2021-06-24"], ["ord_12", "2021-06-25"], , ["ord_13", "2021-06-26"]] 
         * timeRangeGroups: [{id:'lt1'...}, {id:'gt1'...}]
         * op: {lt1: 1, gt1: 2} , assuming current date is: "2021-06-27"
         */
        rows.map((d: any, i: any) => {
          const cobj: any = {};
          internalColumns.map((col: any, i: any) => {
            cobj[col] = d[i];
            return col;
          })

          timeRangeGroups.map((curTimeRange: any) => {
            const today: any = new Date();
            const hpDate: any = new Date(cobj[curTimeField])
            const dateDiff = Math.abs(today - hpDate);
            // lesser than case
            if (curTimeRange.duration && dateDiff <= curTimeRange.duration) {
              const key: any = curTimeRange['label'];
              chartSeriesObj[key] = chartSeriesObj[key] ? chartSeriesObj[key] + 1 : 1;
            }
            // greater than case
            if (curTimeRange.durationGt && dateDiff > curTimeRange.durationGt) {
              const key: any = curTimeRange['label'];
              chartSeriesObj[key] = chartSeriesObj[key] ? chartSeriesObj[key] + 1 : 1;
            }
            return curTimeRange;
          })
          return d
        })
      }

      if (curAggregateField.id === 'countDistinct') {
        /**
         * generates a map between timeRangeGroups & distinct values of selected entity (like hp_order_no) 
         * ip: rows = [["ord_11", "2021-06-24"], ["ord_12", "2021-06-25"], , ["ord_13", "2021-06-26"]] 
         * timeRangeGroups: [{id:'lt1'...}, {id:'gt1'...}]
         * op: {lt1: 1, gt1: 2} , assuming current date is: "2021-06-27"
         */
        rows.map((d: any, i: any) => {
          const cobj: any = {};
          internalColumns.map((col: any, i: any) => {
            cobj[col] = d[i];
            return col;
          })

          timeRangeGroups.map((curTimeRange: any) => {
            const today: any = new Date();
            const hpDate: any = new Date(cobj[curTimeField]);
            const cfData = cobj[currentField.id];
            // lesser than case
            if (curTimeRange.duration && Math.abs(today - hpDate) <= curTimeRange.duration) {
              const key: any = curTimeRange['label'];
              chartSeriesObj[key] ? chartSeriesObj[key].push(cfData) : chartSeriesObj[key] = [cfData];
            }
            // greater than case
            if (curTimeRange.durationGt && Math.abs(today - hpDate) > curTimeRange.durationGt) {
              const key: any = curTimeRange['label'];
              chartSeriesObj[key] ? chartSeriesObj[key].push(cfData) : chartSeriesObj[key] = [cfData];
            }
            return curTimeRange;
          })
          return d
        })
      }


      const cs = generateChartData(chartSeriesObj, curAggregateField.id === 'countDistinct');
      cs.sort((a: any, b: any) => b.value > a.value ? 1 : -1);


      const chartData = cs.slice(0, MAX_ITEMS);
      const others = {
        id: "Others",
        label: "",
        value: 0
      };
      cs.slice(MAX_ITEMS).forEach((item: any) => {
        others.value += item.value;
        others.label = "Others: " + others.value
      })
      if (others.value > 0) {
        chartData.push(others);
      }

      setData(chartData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentField?.id, curAggregateField?.id])

  const onChartTypeChange = (e: any) => {
    ChartTypes.map((f: any) => {
      if (f.id === e.target.value) {
        setCurrentChartType(f);
      }
      return f;
    })
  }
  const onAggregateFieldChange = (e: any) => {
    aggregationOptions.map((f: any) => {
      if (f.id === e.target.value) {
        setCurAggregateField(f);
      }
      return f;
    })
  }
  const onChartFieldChange = (e: any) => {
    yAxisFields.map((f: any) => {
      if (f.id === e.target.value) {
        setCurrentField(f);
      }
      return f;
    })
  }
  const exportAsImage = () => {
    const svgElement: any = document.querySelector('.chartbuilder svg');
    const chartBuilderEl: any = document.querySelector('.chartbuilder');
    const boardHeight = chartBuilderEl.offsetHeight;
    const boardWidth = chartBuilderEl.offsetWidth;
    const svgBlob = new Blob([svgElement.outerHTML], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl: any = URL.createObjectURL(svgBlob);

    const canvas: any = document.getElementById("canvas");
    canvas.setAttribute("height", boardHeight);
    canvas.setAttribute("width", boardWidth);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, boardWidth, boardHeight);
    const img = new Image();
    let pngUrl = '';
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      let dateTimeStr: any = new Date();
      dateTimeStr = dateTimeStr.toLocaleDateString("en-US").replace(/\//g, '-')
      console.log(pngUrl)
      downloadLink.href = pngUrl;
      downloadLink.download = `bmt-chart-${dateTimeStr}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(pngUrl);
    };
    img.src = svgUrl;
  }

  return (
    <>
      <div className={" chartbuilder-group"} style={{ height: '100%' }}>
        <section className="view-chartbuilder">
          <header className="cb-header">
            <h3>Chart Builder</h3>
            <div className="actions">
              <button onClick={exportAsImage} className='btn-export' ><DownloadIcon /></button>
              <button className="btn-modal-close" onClick={() => switchLayout('')}><CloseIcon /></button>
            </div>
          </header>
          <div className="chart-config-opts">
            <div className="c-gr">
              <div className="selector">
                <label>Chart Type:</label> <select name="chartType" id="chartType" className="chart-type"
                  onChange={onChartTypeChange}
                >
                  {ChartTypes.map((ct: any) => {
                    return (
                      <option key={'ct-' + ct.id} defaultValue={currentChartType.id} value={ct.id}  >{ct.label}</option>
                    )
                  })}
                </select>
              </div>
              <div className="selector">
                <label>Aggregate by:</label> <select name="chartField" id="chartField" className="chart-type"
                  onChange={onAggregateFieldChange}
                >
                  {aggregationOptions.map((ct: any) => {
                    return (
                      <option key={'fd-' + ct.id} defaultValue={currentField?.id} value={ct.id}  >{ct.label}</option>
                    )
                  })}
                </select>
              </div>
              <div className="selector">
                <label>Entity:</label> <select name="chartField" id="chartField" className="chart-type"
                  onChange={onChartFieldChange}
                >
                  {yAxisFields.map((ct: any) => {
                    return (
                      <option key={'fd-' + ct.id} defaultValue={currentField?.id} value={ct.id}  >{ct.label}</option>
                    )
                  })}
                </select>
              </div>

            </div>
          </div>


          <div className="chartbuilder-wrap">
            <div className="chartbuilder">
              {(!data || data.length <= 0)
                ? (<div className='err-msg'><span>No data found. Please select an alert.</span></div>)
                : (
                  <>
                    <header className="cbg-header">
                      <div className='legend-label'>Legend</div>
                      <h4 className="sub-header">
                        {curAggregateField.label + ' of ' + currentField.label}
                      </h4>
                    </header>
                    <div className="chartpanel">
                      {data.length > 0 && currentField && currentField.id && currentChartType.id + '' === 'donut' && (
                        <>
                          <ChartBuilderPie data={data} chartLabel={currentField.label} maxItems={MAX_ITEMS}

                          />
                        </>
                      )
                      }

                      {data.length > 0 && currentField && currentField.id && currentChartType.id === 'bar' &&
                        <ResponsiveBar
                          data={data}
                          // keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
                          indexBy="id"
                          margin={{ top: 20, right: 20, bottom: 80, left: 60 }}
                          padding={0.3}
                          valueScale={{ type: 'linear' }}
                          indexScale={{ type: 'band', round: true }}
                          // colors={data.map((c: any) => c.color)}
                          colorBy={"indexValue"}
                          colors={{ scheme: 'paired' }}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: '#38bcb2',
                              size: 4,
                              padding: 1,
                              stagger: true
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: '#eed312',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10
                            }
                          ]}
                          fill={[
                            {
                              match: {
                                id: 'fries'
                              },
                              id: 'dots'
                            },
                            {
                              match: {
                                id: 'sandwich'
                              },
                              id: 'lines'
                            }
                          ]}
                          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}

                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 90,
                            legend: currentField.label,
                            legendPosition: 'middle',
                            legendOffset: 60,
                            format: (d) => { return d.substr(0, 6) + (d.length > 6 ? '..' : '') }
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: curAggregateField.label,
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          labelSkipWidth={12}
                          labelSkipHeight={12}
                          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                          tooltip={(d: any) => {
                            return (
                              <div className="tt-body">
                                <div className="tt-row"><span className="tt-sqr"
                                  style={{ 'background': d.color }}></span>
                                  <span>{d.indexValue}: <strong>{parseFloat(d.value + '').toLocaleString().replace(/,/g, ' ')}</strong></span></div>
                              </div>
                            )
                          }}
                        // legends={''}
                        />
                      }
                    </div>
                  </>
                )
              }
            </div>
            <aside className='chartbuilder-meta'>
              <div className="f-gr"><label>Alert</label> <span>{meta.alert}</span></div>
              <div className="f-gr"><label>Dashboard</label> <span>{meta.dashboardName}</span></div>
              <div className="f-gr"><label>Profile</label> <span>{meta.profileName}</span></div>
              <div className="f-gr"><label>Display Values</label> <span>{drilldown.omtConditions.displayValues}</span></div>
              <div className="f-gr"><label>Ad-Hoc filters</label> <span>{drilldown.omtConditions.adhoc && drilldown.omtConditions.adhoc.length > 0 && JSON.stringify(drilldown.omtConditions.adhoc)}</span></div>
            </aside>
          </div>
        </section>
      </div>
      <canvas id="canvas" style={{ display: 'none' }}></canvas>
    </>
  )
}

export default ChartBuilder;