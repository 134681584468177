export const initialState = {
    global: {
        allStocks: [],
        allInstruments: {},
        instrumentLabels: {}
    },
    notification: {
        openSnack: false,
        autoHideDuration: 6000,
        snackSeverity: 'success',
        message: ''
    },
    holding: { total: { cur_val: 0, invested_amt: 0 } },
    screener: {
        performance: [],
        sidStockInfoMapping: {},
        mutualfund: [],
        screenerData: {}
    }
};