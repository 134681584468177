import React from 'react'
import clsx from 'clsx';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import useStyles from '../components/stocks.css';

import GlobalNotification from "./global-notification";
import '../components/stocks.css';
import '../App.scss';
import { styled } from '@mui/material';
const drawerWidth = 240;

//Main
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  zIndex: 1099,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const TemplateDefault = (props: any) => {
  const [open, setOpen] = React.useState(!!props.isSideBarOpen);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const classes = useStyles();

  const layout: any = {
    mainView: 10,
    sideView: 2,
    header: true,
    isLeftSidebar: false,
    isRightSidebar: true,
    ...props.layout
  }
  layout.sideView = 12 - layout.mainView;


  return (
    <div className={classes.root}>
      {props.header(toggleDrawer)}
      <Drawer
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: (theme) => theme.zIndex.appBar - 1,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', paddingTop: 6 },
        }}
        anchor={'left'}
        open={open}
        onClose={handleDrawerClose}
      >
        {(layout.isLeftSidebar) && (
          <aside className={'sidebar ' + classes.sidebar}>
            {props.sidebar}
          </aside>
        )}
      </Drawer>
      <Main open={open}>
        <div
          className={clsx(classes.content, classes.noDrawer, {
            [classes.contentShift]: open,
          }) + ' main default-view'}
        >
          {layout.header && <div className={classes.drawerHeader} />}
          <Grid className={classes.challengeGrid} container >
            <Grid item xs={12} md={layout.mainView} className={classes.challengeDetails + ' mainview'}>
              <div className="view-content">
                {props.children}
              </div>
            </Grid>

            {(layout.isRightSidebar && layout.mainView < 12) &&
              (<Grid item xs={12} md={layout.sideView} className={'sidebar ' + classes.sidebar}>
                {props.sidebar}
              </Grid>)
            }
          </Grid>
        </div>
        <GlobalNotification/>
      </Main>
    </div>
  );
}

export default TemplateDefault;

