import "./MutualFund.scss";
import MfChart from "../../components/charts/mf-chart";
import React from "react";


const MutualFund = ({ activeSeries = "", data = [] }: any) => {
  const chartData = activeSeries ? data[activeSeries] : [];
  return (
    <React.Fragment>
      {activeSeries ? <MfChart data={chartData} /> : <p>Loading</p>}
    </React.Fragment>
  );
}

export default MutualFund;
