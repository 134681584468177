import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../context/store'
import { useScreener } from '../../context/screener/index';

import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper, TextField, Checkbox, FormControlLabel, Tab, Tabs, Container, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import TemplateDefault from "../template-default";
import T100Chart from "../../components/t100-chart";
import AppHeader from '../../components/app-header';
import Radio from "../../components/controls/radio";
import { getScreener, getMutualFund } from "../../services/api-services";
import { sectors, userHolding } from "../../config/sectors";
import useStyles from '../stock-line.css';
import { stockGroupData, chartTypes, iFilter, durationOptsDefault, defaultCommonQuery, MF_STOCK_KEY } from "./screener-config";
import CustomTabPanel from '../../components/CustomTabPanel';
import MutualFund from "../../components/MutualFund/MutualFund";
import ScreenerChart from "../../components/ScreenerChart/ScreenerChart";
import MarketConfig from "../../config/market-config";
import MarketCapSlider from "../../components/MarketCapSlider/market-cap-slider";
import _ from 'lodash';

const mfList = ['M_QUNG', 'M_QUPP', 'M_HDYV', 'M_ICCYG', 'M_MOAS', 'M_EDFA', 'M_ADTS', MF_STOCK_KEY];
const mfName = ['Quant Infrastructure Fund', 'Quant PSU Fund', 'HDFC Retirement Plan', 'I Equity & Debt Fund', 'MO S&P BSE', 'Edelweiss Nifty Smallcap 250', 'Aditya Birla SL PSU', 'Stock Mutualfund Allocation']

const capOpts = [
  {
    value: 'largeCap',
    label: 'Large'
  }, {
    value: 'midCap',
    label: 'Medium'
  }, {
    value: 'smallCap',
    label: 'Small'
  }, {
    value: 'custom',
    label: 'Custom'
  }
]

const TAB_ID = {
  STOCK: 'stock',
  MUTUAL_FUND: 'mutual-fund',
  DMA: 'DMA'
}

const tabConfig = [
  {
    name: 'Stock',
    id: TAB_ID.STOCK
  }, {
    name: 'Mutual Fund',
    id: TAB_ID.MUTUAL_FUND
  }, {
    name: 'DMA',
    id: TAB_ID.DMA
  }

]

const StockScatter = () => {
  const { dispatch, state }: any = useContext(Context);
  const { updateSidStockInfoMapping, getPerformanceData, getUserHoldingsData, updateMutualFund, updateScreenerChartData } = useScreener(dispatch);
  const classes = useStyles();
  const [activeDurationSeries, setActiveDurationSeries] = useState(['pr1d']);
  const [activeCap, setActiveCap] = useState('largeCap');
  const [chartType, setChartType] = useState(chartTypes[0].id);
  const [activeData, setActiveData] = useState([]);
  const [filter, setFilter] = useState(iFilter)
  const [durationOpts, setDurationOpts] = useState(durationOptsDefault)
  const [sids, setSids] = useState(JSON.stringify(state.screener.sids || userHolding));
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeMF, setActiveMF] = useState('M_QUPP');
  const [capRange, setCapRange] = React.useState<number[]>([MarketConfig.largeCap.g, MarketConfig.largeCap.l]);
  const [capRangeScreener, setCapRangeScreener] = React.useState<number[]>([MarketConfig.largeCap.g, MarketConfig.largeCap.l]);

  const handleSidsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSids(event.target.value);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const [commonQuery] = useState(defaultCommonQuery);


  // gets data based on tab selected
  useEffect(() => {
    const currentTab = tabConfig[tabValue];
    if (currentTab.id === TAB_ID.MUTUAL_FUND && activeMF !== MF_STOCK_KEY) {
      setLoading(true);
      const mutualFundReq: any = getMutualFund(activeMF);
      mutualFundReq.promise
        .then((response: any) => {
          const payload: any = {};
          payload[activeMF] = response.data?.currentAllocation;
          updateMutualFund(payload);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        })
    } else if (currentTab.id === TAB_ID.STOCK) {
      getMarketData();
    } else if (currentTab.id === TAB_ID.DMA) {
      getScreenerChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, activeMF])

  useEffect(() => {
    window.setTimeout(async () => {
      if (activeCap === 'custom' && sids.length > 0) {
        const getScreenerReq = getScreener(
          { ...commonQuery, match: {}, sids: JSON.parse(sids) }
        );
        await getScreenerReq.promise
          .then(function (response: any) {
            stockGroupData['custom'] = response.data.results;
          })
          .catch(function (error: any) {
            console.log(error);
          });
      }
      const filteredActiveData = stockGroupData[activeCap].filter((val: any) => {
        const sector = val.stock.info.sector || 'Unlisted';
        return filter.sectors.length === 0 || filter.sectors.indexOf(sector) >= 0;
      })
      setActiveData(filteredActiveData);
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, activeCap])

  useEffect(() => {
    if (state.screener?.sids) {
      setSids(state.screener.sids)
    }
  }, [state.screener.sids])


  const getMarketData = () => {
    // gets lCap data
    const lCapReq: any = getScreener(commonQuery);
    lCapReq.promise.then(function (response: any) {
      stockGroupData['largeCap'] = response.data.results;
      updateSidStockInfoMapping(response.data.results);
      setActiveData(response.data.results);
    })
      .catch(function (error: any) {
        console.log(error);
      });

    // gets midCap data
    const match = {
      "mrktCapf": MarketConfig.midCap
    };
    const mCapReq: any = getScreener(
      { ...commonQuery, match: match }
    );
    mCapReq.promise.then(function (response: any) {
      updateSidStockInfoMapping(response.data.results);
      stockGroupData['midCap'] = response.data.results;
    })
      .catch(function (error: any) {
        console.log(error);
      });

    // gets sCap data  
    const sCapMatch = {
      "mrktCapf": MarketConfig.smallCap
    };
    const sCapReq = getScreener(
      { ...commonQuery, match: sCapMatch }
    );
    sCapReq.promise.then(function (response: any) {
      updateSidStockInfoMapping(response.data.results);
      stockGroupData['smallCap'] = response.data.results;
    })
      .catch(function (error: any) {
        console.log(error);
      });

    //gets sids
    getUserHoldingsData();
  }

  const onScreenerCapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ value: e.target.value, mc: MarketConfig[e.target.value] })
    setActiveCap(e.target.value);
    if (MarketConfig[e.target.value]) {
      setCapRangeScreener([MarketConfig[e.target.value].g, MarketConfig[e.target.value].l])
      getScreenerChartData({
        g: MarketConfig[e.target.value].g,
        l: MarketConfig[e.target.value].l
      })
    } else if (e.target.value === 'custom') {
      getScreenerChartData({}, JSON.parse(sids))
    }
  }

  const getScreenerChartData = React.useRef(
    _.debounce((marketCap = {}, sids = []) => {
      if (!loading) {
        setLoading(true);
        const project: any = [
          "subindustry",
          "mrktCapf",
          "apef",
          "indpe",
          "sma50d",
          "ema50d",
          "lastPrice",
          'pr1w',
          '4wpct',
          '26wpct',
          '52wpct',
          '5yCagrPct'
        ];
        let match = {};
        if (marketCap?.g) {
          match = { mrktCapf: marketCap };
        }
        const apiReqest: any = getScreener({ ...commonQuery, project: project, match: match, sids: sids });
        apiReqest.promise
          .then((response: any) => {
            const payload: any = {};
            payload['data'] = response.data?.results;
            updateScreenerChartData(payload);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          })

      }
    }, 250)
  ).current

  const onSeriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCheckGroupValue({ id: e.target.name, value: e.target.checked })
  }
  const onCapChange = (e: any) => {
    setActiveCap(e.target.value);
    if (MarketConfig[e.target.value]) {
      setCapRange([Number(MarketConfig[e.target.value].g), Number(MarketConfig[e.target.value].l)]);
    }
  }
  const onChartTypeChange = (e: any) => {
    setChartType(e.target.value);
  }

  const filterBySector = (sector: any) => {
    const cfIdx = filter.sectors.indexOf(sector.name);
    cfIdx >= 0 ? filter.sectors.splice(cfIdx, 1) : filter.sectors.push(sector.name);
    setFilter({
      sectors: filter.sectors
    })
  }

  const updateCheckGroupValue = ({ id, value }: any) => {
    const newActiveDurationSeries: any = [];
    const newDO = durationOpts.map((item: any) => {
      if (item.name === id) {
        item.checked = value
      }
      if (item.checked) {
        newActiveDurationSeries.push(item.name);
      }
      return item;
    })

    setDurationOpts(newDO)
    setActiveDurationSeries(newActiveDurationSeries);
  }

  const getScreenerData = React.useRef(
    _.debounce((marketCap) => {
      if (!loading) {
        setLoading(true);
        const match = {
          "mrktCapf": marketCap
        };
        const req: any = getScreener(
          { ...commonQuery, match: match }
        );
        req.promise
          .then(function (response: any) {
            stockGroupData['dynamicCap'] = response.data.results;
            updateSidStockInfoMapping(response.data.results);
            setActiveData(response.data.results);
            setActiveCap('dynamicCap')
          })
          .catch(function (error: any) {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 3000)
  ).current

  const len = activeData.length;


  return (
    <>
      <TemplateDefault
        isSideBarOpen={false}
        header={
          (toggleDrawer: any) => {
            return (
              <AppHeader
                handleDrawerOpen={() => { return }}
                challengesFlag={false}
                getChallenges={() => { return }}
                open={false}
                lastUpdated={''}
                activeIndex={1}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        }

        sidebar={
          <div className={classes.sidebar}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
            >
              {
                tabConfig.map(item => {
                  return (<Tab key={item.id} label={item.name} />)
                })
              }
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <Container disableGutters={true}>
                <Accordion defaultExpanded elevation={0} square >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Active series
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="opts">{
                      durationOpts.map((opt: any, i: any) => (
                        <FormControlLabel
                          key={'do-' + i}
                          label={opt.label}
                          control={
                            <Checkbox name={opt.name} checked={opt.checked} onChange={onSeriesChange} />
                          } />
                      ))
                    }</div>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded elevation={0} square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Market Cap
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MarketCapSlider getScreenerData={getScreenerData} newCapRange={capRange} />
                    <div className="opts">{
                      capOpts.map((opt: any, i: any) => (
                        <Radio key={'cap-' + i} label={opt.label} name={'cap-series'} value={opt.value} onChange={onCapChange} currentValue={activeCap} />
                      ))
                    }</div>
                    <div>
                      <TextField label="Sids" variant="outlined"
                        size="small"
                        value={sids}
                        onChange={handleSidsChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion elevation={0} square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Chart Type
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="opts">{
                      chartTypes.map((opt: any, i: any) => (
                        <Radio key={'ct-' + i} label={opt.label} name={'cts'} value={opt.id} onChange={onChartTypeChange} currentValue={chartType} />
                      ))
                    }</div>
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded elevation={0} square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Legend
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="sectors">
                      {sectors.map((sector: any, i: any) => {
                        const color = sector.color;
                        const r = 8;
                        const CustomSymbol = (
                          <svg width={2 * r} height={2 * r}><g transform={`translate(${r}, ${r})`}>
                            <circle fill={color} r={r - 2} strokeWidth={1} stroke={color} />
                          </g></svg>
                        )

                        return (
                          <li key={'sec-' + i}
                            role="button"
                            onClick={() => { filterBySector(sector) }}
                            className={filter.sectors.indexOf(sector.name) >= 0 ? 'active' : ''}
                          > {CustomSymbol} <span>{sector.name}</span></li>
                        )
                      })}
                    </ul>
                  </AccordionDetails>
                </Accordion></Container>
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={1}>
              <Container disableGutters={true}>
                <List>
                  {mfList.map((item, index) => {
                    return (
                      <ListItem key={item} disablePadding>
                        <ListItemButton onClick={() => setActiveMF(item)} >
                          <ListItemText primary={item} secondary={mfName[index]} />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </Container>
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={2}>
              <Container disableGutters={true}>
                <Accordion defaultExpanded elevation={0} square >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Active series
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="opts">{
                      durationOpts.map((opt: any, i: any) => (
                        <FormControlLabel
                          key={'do-' + i}
                          label={opt.label}
                          control={
                            <Checkbox name={opt.name} checked={opt.checked} onChange={onSeriesChange} />
                          } />
                      ))
                    }</div>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded elevation={0} square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Market Cap
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MarketCapSlider getScreenerData={getScreenerChartData} newCapRange={capRangeScreener} />
                    <div className="opts">{
                      capOpts.map((opt: any, i: any) => (
                        <Radio key={'cap-' + i} label={opt.label} name={'cap-series'} value={opt.value} onChange={onScreenerCapChange} currentValue={activeCap} />
                      ))
                    }</div>
                    <div>
                      <TextField label="Sids" variant="outlined"
                        size="small"
                        value={sids}
                        onChange={handleSidsChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Container>
            </CustomTabPanel>
          </div>
        }
      >
        <Paper className={classes.pane}>
          {/* stocks panel */}
          <CustomTabPanel value={tabValue} index={0} style={{ height: '100%' }}>
            <div className={classes.chartGroup} style={{ height: '100%' }}>
              {len > 0 && activeDurationSeries.length > 0 ? <figure className={'scatter-chart'} style={{ width: `${Math.max(36 * len, 720)}px` }}>
                <T100Chart data={activeData} durationSeries={activeDurationSeries} chartType={chartType}
                  getPerformanceData={getPerformanceData} performanceChartData={state.screener.performance} />
              </figure>
                : <div className="loading-progress"><CircularProgress /></div>}
            </div>
          </CustomTabPanel>
          {/* mutual fund panel */}
          <CustomTabPanel value={tabValue} index={1} style={{ height: "100%" }}>
            <MutualFund data={state.screener.mutualfund} activeSeries={activeMF} />
          </CustomTabPanel>
          {/* dma panel */}
          <CustomTabPanel value={tabValue} index={2} style={{ height: "100%" }}>
            <ScreenerChart data={state.screener.screenerData.data} loading={loading} />
          </CustomTabPanel>
        </Paper>
      </TemplateDefault>
    </>
  )
}

export default StockScatter;

