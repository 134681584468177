import { useEffect } from "react";
import { useHistory } from 'react-router-dom';

function SignOut() {
  const history = useHistory();

  useEffect(() => {
    removeLocalToken();
    signOutHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const removeLocalToken = () => {
    localStorage.removeItem('toxyToken');
    localStorage.removeItem('authToken');
    localStorage.removeItem('defaultNav');
    localStorage.removeItem('allInstruments');
    localStorage.removeItem('stockMappingReq');
  }


  const signOutHandler = () => {
    window.location.href = '/login';
  }

  return null;
}

export default SignOut;
