import "./chart.scss";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Brush,
  ReferenceLine,
} from "recharts";

const CustomizedDot = (props: any) => {
  const { cx, cy, stroke, value } = props;

  const color = value < 0 ? 'red' : stroke;
  return (
    <svg x={cx - 5} y={cy - 5} width={10} height={10} fill={color} viewBox="0 0 20 20"><g><path d="M10 .4C4.698.4.4 4.698.4 10a9.6 9.6 0 1 0 19.2-.001C19.6 4.698 15.301.4 10 .4zm0 17.199A7.6 7.6 0 1 1 10 2.4a7.6 7.6 0 1 1 0 15.199z"></path></g></svg>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`} >
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" transform="rotate(-90)">
        {payload.value}
      </text>
    </g >
  );
}

const CustomizedLabel = (props: any) => {
  const { x, y, stroke, value } = props;
  const label = value ? (value === parseInt(value) ? value : value.toFixed(1)) : '';
  return (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={10} textAnchor="middle">
      {label}
    </text>
  );
}

const MfChart = ({ data = [] }: any) => {
  const newData = data.sort((a: any, b: any) => a.change3m - b.change3m);
  const dataWithIndex = newData.map((item: any, index: any) => ({ ...item, index }))

  const endIndex = Math.min(30, newData.length - 1);

  const getLabel = (props: any) => {
    const d = props[0] && props[0].payload ? props[0] : { payload: {} };
    return `${d.payload.index + 1} ${d.payload.ticker ? d.payload.ticker : d.payload.title}`;
  }

  return (
    <ResponsiveContainer width="100%"
      height="100%">
      {dataWithIndex && dataWithIndex.length > 0 && <ComposedChart
        data={dataWithIndex}
        margin={{
          top: 20,
          right: 8,
          left: 8,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />

        <XAxis dataKey="sid" interval={0} padding={{ left: 5, right: 5 }} tick={<CustomizedAxisTick />} height={50} />
        <YAxis type="number" padding={{ bottom: 10 }}
          domain={[(dataMin: any) => (parseInt(dataMin) - 3), (dataMax: any) => parseInt(dataMax) + 3]}
          allowDataOverflow />
        <Tooltip
          formatter={(value: any, name) => { return [value?.toFixed(4), name] }}
          labelFormatter={(label, props: any) => getLabel(props)}
        />
        <Legend wrapperStyle={{ lineHeight: '40px' }} />
        <Line dataKey="latest" stroke="#8884d8" dot={<CustomizedDot />} label={<CustomizedLabel />} />
        <Line dataKey="change3m" stroke="#82ca9d" dot={<CustomizedDot />} label={<CustomizedLabel />} />
        <ReferenceLine y={0} stroke="#ddd" />
        <Brush height={30} stroke="#8884d8" endIndex={endIndex} />
      </ComposedChart>}
    </ResponsiveContainer>
  );
}
export default MfChart