
export interface IState {
  allStocks: any;
  allInstruments: any;
  instrumentLabels: any;
  isLoading: false
}

export type Actions = IState

export const AppSetupReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_STOCK_MAPPING':
      return {
        ...state,
        allStocks: action.payload
      };
    case 'SET_ALL_INSTRUMENTS':
      return {
        ...state,
        allInstruments: action.payload
      };
    case 'SET_INSTRUMENT_LABELS':
      return {
        ...state,
        instrumentLabels: action.payload
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_QUOTE_LTP':
      return {
        ...state,
        quoteLtp: action.payload
      };
    default:
      return state;
  }
};

export default AppSetupReducer;
