import { Button, Stack, Typography } from "@mui/material"
import { currencyFormat } from "../common/utils";
import ProgressCard from "../components/progress-card/progress-card"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FlashOnIcon from '@mui/icons-material/FlashOn';

export const profileInitialState = {
  sector: false,
  qty: false,
  avg: false,
  last_price: false,
  invested_amt: false,
  cur_val: false
}

export const profileSorting: any = {
  sortModel: [{ field: 'weight', sort: 'desc' }],
}

const getProfileRows = {
  sector: (d: any) => (<span title={d.sector}></span>),
  instrument: (d: any, udpateSymbol: any) => (
    <Button variant="text" className="tsym" onClick={() => { udpateSymbol('BSE:' + d.tradingsymbol) }} sx={{ justifyContent: 'flex-start' }}>{d.tradingsymbol} {d.gtt && <FlashOnIcon sx={{ fontSize: '16px', marginLeft: '2px' }} />}</Button>),

  weight: (d: any) => {
    const percentage = (d.weight)?.toFixed(2);
    const qty = d.quantity;
    const invested = currencyFormat(d.cur_val, true);
    return (<ProgressCard value={parseFloat(percentage)}
      color={'primary'}
      progressStyle={{
        backgroundColor: '#ecf4ff',
        // '& .MuiLinearProgress-bar': {
        //   backgroundColor: d.color
        // }
      }}
    >
      <Stack spacing={0.5}
        justifyContent="space-between"
        direction={'row'}
        alignItems="center"
      >
        <Typography variant='body2' component='span' sx={{ fontWeight: 500 }} >{percentage}%</Typography>
        <Typography variant='body2' component='span' >{invested}</Typography>
        <Typography variant='body2' component='span' >{qty}</Typography>
      </Stack>
    </ProgressCard>)
  },

  avg: (d: any) => (d.average_price?.toFixed(2)),
  last_price: (d: any) => (d.last_price?.toFixed(2)),
  invested_amt: (d: any) => currencyFormat(d.invested_amt),
  cur_val: (d: any) => currencyFormat(d.cur_val),
  qty: (d: any) => (d.qty?.toFixed(2)),
  pnl: (d: any) => {
    const percentage = (d.net_change_percentage)?.toFixed(1);
    /*
    for make the progress-bar fill from right, 
    substract the percentage value from 200 (i.e. 200 - percentage)
    */
    const isChangePositive = d.net_change_percentage >= 0;
    const valPercentage = isChangePositive ? Math.abs(percentage) : 200 - Math.abs(percentage);
    const pnl = currencyFormat(Math.abs(d.rpnl), true);
    const ival = currencyFormat(d.invested_amt, true);
    const cval = currencyFormat(d.cur_val, true);
    return (<ProgressCard value={valPercentage}
      color={isChangePositive ? 'primary' : 'secondary'}
      progressStyle={{
        backgroundColor: isChangePositive ? '#ecf4ff' : '#ffe7df'
      }}
    >
      <Stack
        justifyContent="space-between"
        direction={'row'}
        alignItems="center"
      >
        <Typography variant='body2' component='span' sx={{ width: '120px', fontWeight: 500 }}>{percentage}%
          {isChangePositive ? <ArrowDropUpIcon sx={{ verticalAlign: 'top', color: '#2e7d32' }} /> : <ArrowDropDownIcon sx={{ verticalAlign: 'top', color: '#ff5722' }} />}
          {pnl}</Typography>
        <Typography variant='body2' component='span'
          sx={{ width: '72px', textAlign: 'center' }}>{ival}</Typography>
        <Typography variant='body2' component='span' sx={{ width: '72px', textAlign: 'right' }} >{cval}</Typography>
      </Stack>
    </ProgressCard >)
  },
  day_change: (d: any) => (<span
    className={d.day_change_percentage >= 0 ? 'positive' : 'negative'}
  >{d.day_change_percentage?.toFixed(1)}%</span>),

  circuit_limit: (d: any) => {
    const percentage = ((1 - d.lower_circuit_limit / d.upper_circuit_limit) * 100)?.toFixed(2);
    return (<ProgressCard value={percentage}
      color={'primary'}
      progressStyle={{
        backgroundColor: '#ecf4ff',
        // '& .MuiLinearProgress-bar': {
        //   backgroundColor: d.color
        // }
      }}
    >
      <Stack spacing={0.5}
        justifyContent="space-between"
        direction={'row'}
        alignItems="center"
      >
        <Typography variant='body2' component='span' >{d.upper_circuit_limit}</Typography>
        <Typography variant='body2' component='span' >{d.last_price}</Typography>
        <Typography variant='body2' component='span' >{d.lower_circuit_limit}</Typography>
      </Stack>
    </ProgressCard>)
  },
}

export const profileCols = (udpateSymbol: any) => [
  {
    field: 'sector',
    // headerName: (<span title="Sector"><StreamIcon /></span>),
    className: 'col-sector',
    dataType: 'string',
  },
  {
    field: 'instrument',
    headerName: 'Instrument',
    flex: 0.2,
    renderCell: (params: any) => getProfileRows.instrument(params.row, udpateSymbol)
  }, {
    field: 'weight',
    headerName: 'Weight / Cur Val / Qty',
    flex: 0.4,
    renderCell: (params: any) => getProfileRows.weight(params.row),
    width: 200,
  }, {
    field: 'avg',
    headerName: 'Avg cost',
    renderCell: (params: any) => getProfileRows.avg(params.row)
  }, {
    field: 'last_price',
    headerName: 'LTP',
    renderCell: (params: any) => getProfileRows.last_price(params.row)
  }, {
    field: 'invested_amt',
    headerName: 'Invested amt',
    renderCell: (params: any) => getProfileRows.invested_amt(params.row)
  }, {
    field: 'cur_val',
    headerName: 'Current val',
    renderCell: (params: any) => getProfileRows.cur_val(params.row)
  }, {
    field: 'qty',
    headerName: 'Weight',
    renderCell: (params: any) => getProfileRows.qty(params.row)
  }, {
    field: 'pnl',
    headerName: 'P&L / Invested / Current',
    flex: 0.5,
    renderCell: (params: any) => getProfileRows.pnl(params.row),
    width: 240
  }, {
    field: 'day_change',
    headerName: 'Day chg',
    renderCell: (params: any) => getProfileRows.day_change(params.row),
    width: 80
  }, {
    field: 'circuit_limit',
    headerName: 'U cu / L pr / L cu ',
    renderCell: (params: any) => getProfileRows.circuit_limit(params.row),
    width: 200
  }
]