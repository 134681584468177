import { createTheme } from '@mui/material/styles';
import { userConfig } from "./config/settings";
const prefersDarkMode = userConfig.prefersDarkMode;

const lightTheme: any = {
  type: 'light',
  primary: {
    main: '#448EF6',
    icon: '#aaaaaa'
  },
  secondary: {
    main: '#FF5722',
  },
};
const darkTheme: any = {
  type: 'dark',
  primary: {
    main: '#2196f3',
    icon: '#fefefe'
  },
  secondary: {
    main: '#196DB3',
  },
  background: {
    default: '#1d1e22',
    paper: '#1a1b1f'
  },
  text: {
    primary: 'rgba(255, 255, 255, 0.82)'
  },
};
const theme = createTheme({
  palette: prefersDarkMode ? darkTheme : lightTheme,
  components: {
    MuiButtonBase: {
      styleOverrides: {
        // Style sheet name ⚛️
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded:before": {
            opacity: 1
          }
        }
      },
      defaultProps: {
        disableGutters: true,
        elevation: 0
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)':{
            minHeight: 48,
          }
        }
      }
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h5: {
      fontSize: '1.5rem'
    },
    h6: {
      fontSize: '1.2rem'
    }
  }
});

export default theme;