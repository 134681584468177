import { Grid, Slider } from '@mui/material';


const RangeSliderCenter = ({ value = [0,50], marks = [], color = 'primary', min=0, max=100 }: any) => {

  return (
    <Grid item xs>
      <Slider
        defaultValue={value}
        color={color}
        valueLabelDisplay="off"
        size="small"
        marks={marks}
        min={min}
        max={max}
        sx={{
          marginBottom: '0',
          '& .MuiSlider-thumb': {
            width: 5,
            height: 5,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&.Mui-active': {
              width: 20,
              height: 20,
            },
          },
          '& .MuiSlider-rail': {
            opacity: 0.28,
          },
          '& .MuiSlider-markLabel': {
            top: '20px'
          }
        }}
      />
    </Grid>
  );
}

export default RangeSliderCenter;