import React, { useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import AppHeader from '../../components/app-header';
import { getSectorByName } from "../../common/utils";
import { tabView } from "../../common/constants";

import InstrumentList from "../../components/instrument-list";
import ScatterChartComponent from "../../components/scatter-chart";

import '../../components/stocks.css';
import { Container, Paper, Tab, Tabs } from '@mui/material';

import { getScreener } from '../../services/api-services';
import TemplateDefault from '../template-default';
import CustomTabPanel from '../../components/CustomTabPanel';

function Stocks() {
  const [chartData, setChartData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [currentChallenge] = useState({ name: '', description: '', registrants: [] });
  const [checkedProjectIDs] = useState([0]);
  const [activeViewType] = useState(tabView[0]);

  const getStocksFunction = () => {
    const commonQuery = {
      "match": {
        "mrktCapf": {
          "g": 20000,
          "l": 1616494.13
        },
        "5YrevChg": {
          "g": 0,
          "l": 169.19
        }
      },
      "sortBy": "mrktCapf",
      "sortOrder": -1,
      "sids": [],
      "project": [
        "subindustry",
        "mrktCapf",
        "lastPrice",
        "apef",
        "3Ywal",
        "beta",
        "5YrevChg"
      ],
      "offset": 0,
      "count": 500
    };

    const { promise } = getScreener(commonQuery);
    promise
      .then(function (response: any) {
        let chData: any = {};

        const gridD: any = [];

        response.data.results.map((d: any) => {
          const sector = d.stock.info.sector;
          const sd = getSectorByName(sector);

          gridD.push({
            ...d,
            sectorSym: sd.sym,
            color: sd.color
          })

          const cd = {
            sid: d.sid,
            name: d.stock.info.name,
            ticker: d.stock.info.ticker,
            x: d.stock.advancedRatios['3Ywal'],
            y: d.stock.advancedRatios.beta,
            color: sd.color
          }
          if (cd.y !== null) {
            if (!chData[sd.sym]) {
              chData[sd.sym] = {
                id: sd.sym,
                name: sd.name,
                color: sd.color,
                data: []
              }
            }
            chData[sd.sym].data.push(cd);
          }
          return d;
        })

        setGridData(gridD);

        const groupChartData: any = [];
        Object.keys(chData).map((key: any) => {
          groupChartData.push(chData[key])
          return key;
        })
        // console.log(groupChartData);
        setChartData(groupChartData);
      })
      .catch(function (error) {
        console.log(error);
        setChartData([]);
      });
  }

  useEffect(() => {
    getStocksFunction();
  }, [])

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <>
      <TemplateDefault
        isSideBarOpen={false}
        header={
          (toggleDrawer: any) => {
            return (
              <AppHeader
                handleDrawerOpen={() => { return }}
                challengesFlag={false}
                getChallenges={() => { return }}
                open={false}
                lastUpdated={''}
                activeIndex={0}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        }

        sidebar={
          <div className='sidebar'>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab label="Stock" />
              <Tab label="Filter" />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <Container disableGutters={true}>
                {gridData && <InstrumentList
                  stocks={gridData}
                  currentChallenge={currentChallenge}
                  checkedProjectIDs={checkedProjectIDs}
                  setActiveChallenge={() => { return; }}
                  getResources={() => { return; }}
                  activeViewType={activeViewType}
                />}
              </Container>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Container disableGutters={true}>tab 2</Container>
            </CustomTabPanel>
          </div>
        }
      >
        <Paper sx={{height: '100%'}}>
          <CustomTabPanel value={tabValue} index={0} style={{ height: '100%' }}>
            {chartData.length > 0 ? <ScatterChartComponent data={chartData} /> : <div className="loading-progress"><CircularProgress /></div>}
          </CustomTabPanel>
        </Paper>
      </TemplateDefault>
    </>

  );
}

export default Stocks;

