import Stocks from './container/stocks/stocks';
import SignOutApp from './components/SignOut';
import Screener from './container/screener/screener';
import Profile from './container/profile/profile';
import Trade from "./container/trade/trade";
import Demo from "./container/demo";
import Login from "./container/login/login";
import UserSettings from "./container/user-settings";
import Callback from "./container/login/callback";

/**
 * Generate an object with all necessary fields to render a page.
 * @param {string} path - The page path
 * @param {string} title - THe page title (for SEO)
 * @param {Function} component - The component to be rendered. Containers can also be used
 * @param {string} description - The page description (for SEO) [OPTIONAL]
 * @param {string} keywords - The comma separated page keywords (for SEO) [OPTIONAL]
 * @returns {object}
 */
const createPage = (path: any, title: any, component: any, pageConfig: any = {}, description: any = '',
  keywords: any = '') => (
  {
    path,
    title: `${title} | 'ToxyCoin'`,
    description,
    keywords,
    component,
    pageConfig,
  });

const commonRoutes = [
  createPage('/login', 'Login', Login),
  createPage('/SignOutApp', 'SignOutApp', SignOutApp),
  createPage('/demo', 'Demo', Demo),
  createPage('/trade', 'Trade', Trade, { privateRoute: true }),
  createPage('/stocks', 'Dashboard', Stocks, { privateRoute: true }),
  createPage('/screener', 'StockScatter', Screener, { privateRoute: true }),
  // createPage('/performance', 'Performance', StockPerformance, { privateRoute: true }),
  createPage('/profile', 'Profile', Profile, { privateRoute: true }),
  createPage('/userSettings', 'UserSettings', UserSettings, { privateRoute: true }),
  createPage('/callback', 'Callback', Callback, { privateRoute: false }),
]

export const routes = [
  ...commonRoutes,
  createPage('/', 'Login', Login)

];

export const securedRoutes = [
  ...commonRoutes,
  createPage('/', 'Dashboard', Stocks, { privateRoute: true }),

];

export default routes;
