export const varietyParams = {
  amo: 'amo',
  regular: 'regular'
}

export const exchangeParams = {
  bse: 'BSE',
  nse: 'NSE'
}

export const transactionTypeParams = {
  buy: 'BUY',
  sell: 'SELL'
}

export const productParams = {
  cnc: 'CNC',
  mis: 'MIS'
}

export const orderTypeParams = {
  limit: 'LIMIT',
  market: 'MARKET',
  sl: 'SL',
  slm: 'SL-M'
}

export const orderStatus = {
  amoReqReceived: "AMO REQ RECEIVED",
  cancelled: "CANCELLED",
  complete: "COMPLETE",
  cancelledAmo: "CANCELLED AMO"
}
