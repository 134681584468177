export const BUYSELL_COLOR = {
  buy: '#448ef6',
  sell: '#FF5722'
}
export const colors = {
  up: '#19af55',
  down: '#d82f44',
  noChange: '#999999',
}

const theme = { BUYSELL_COLOR: BUYSELL_COLOR, colors: colors }

export default theme;