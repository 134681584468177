import { LinearProgress, Stack, styled } from "@mui/material"

const RoundedLinearProgress = styled(LinearProgress)(({ }) => ({
  borderRadius: 5
}));

export const ProgressCard = (params: any) => {
  return (
    <Stack sx={{ width: '100%', paddingRight: '32px', ...params.sx }}>
      {params.children}
      <Stack>
        <RoundedLinearProgress
          variant="determinate"
          value={params.value}
          color={params.color}
          sx={params.progressStyle}
        />
      </Stack>
    </Stack>

  )
}

export default ProgressCard