import moment from "moment";
import { Chip, Typography, Stack } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ProgressCard from "../../components/progress-card/progress-card";
import { BUYSELL_COLOR } from "../../common/theme";
import { GridActionsCellItem } from '@mui/x-data-grid';

const orderType = {
  oco: 'two-leg',
  single: 'single'
}

export const gttInitialState = {
  statusBar: false,
  average_price: false
}

export const getGttRows = {
  statusBar: (d: any) => (<span>{d.index + 1}</span>),

  created_at: (d: any) => {
    const t = d.created_at;
    return t ? moment(t).format('LT D/M') : null
  },

  instrument: (d: any) => (<Stack spacing={0.5} alignItems="center" direction="row"><span>{d.index+1}.</span><a className="tsym" href={`https://stocks.tickertape.in/${d.condition.tradingsymbol}`} rel="noreferrer" title={d.condition.instrument_token} target="_blank">{d.condition.tradingsymbol}</a> <small>{d.condition.exchange.substr(0, 1)}</small>
  </Stack>),

  transaction_type: (d: any) => {
    const tot = d.orders[0]?.transaction_type;
    const type = <Typography variant="body2" component="span" sx={{ textTransform: 'capitalize' }}>{d.type.toLowerCase() === orderType.oco ? "OCO" : d.type}</Typography>;
    const tt = <Typography variant='body2' component='span' sx={{ color: tot === 'BUY' ? BUYSELL_COLOR.buy : BUYSELL_COLOR.sell }}>{tot}</Typography>;
    return ((<Stack spacing={0.5} alignItems="center" direction="row">{type}<span>/</span>{tt}</Stack>))
  },
  trigger_values: (d: any) => {
    const ltp = d?.condition?.last_price;
    const triggerPrice0 = d.condition.trigger_values[0];
    const pLoss: any = ltp ? ((d.condition.trigger_values[0] - ltp) / ltp * 100).toFixed(1) : null;
    const pTarget = ltp && d.type.toLowerCase() === orderType.oco ? ((d.condition.trigger_values[1] - ltp) / ltp * 100).toFixed(1) : null;

    let flexDirection: any = triggerPrice0 < ltp ? 'row-reverse' : 'row';
    let barColor: any = triggerPrice0 < ltp ? 'primary' : 'secondary';
    let absPercentage = 100 - Math.abs(pLoss) + '';

    return d.type.toLowerCase() === orderType.oco
      ? (<><div>{d.condition.trigger_values[0]} {pLoss && <small>{pLoss}%</small>}</div>
        <div>{d.condition.trigger_values[1]} {pTarget && <small>{pTarget}%</small>}</div></>)
      : (<ProgressCard value={parseFloat(absPercentage)} color={barColor}>
        <Stack spacing={0.5}
          justifyContent="space-between"
          direction={flexDirection}
          alignItems="center"
        >
          <Typography variant='body2' component='span' sx={{ fontWeight: 'bold' }} >{pLoss && parseInt(pLoss)}%</Typography>
          <Typography variant='body2' component='span' >{ltp && parseInt(ltp)} / {triggerPrice0 & parseInt(triggerPrice0)}</Typography>
        </Stack>
      </ProgressCard>)

    // (<span>{d.condition.trigger_values[0]} {pLoss && <small>{pLoss}%</small>}</span>
  },


  qty: (d: any) => (d.type.toLowerCase() === orderType.oco ? d.orders[0].quantity + ' / ' + d.orders[1].quantity : d.orders[0].quantity),

  average_price: (d: any) => (<span>{d?.condition?.last_price}</span>),

  status: (d: any) => (<Chip variant="outlined" size="small" label={d.status} color={d.status.toLowerCase() === 'active' ? 'primary' : 'secondary'} />),

  actions: (d: any, deleteGttOrderAction: any) => [
    <GridActionsCellItem
      icon={<DeleteOutlinedIcon fontSize="inherit" color="secondary" />}
      label="Delete"
      onClick={() => deleteGttOrderAction(d)}
    />,
  ]
}


export const gttCols = (deleteGttOrderAction: any) => [
  {
    field: 'statusBar',
    headerName: 'SNo',
    renderCell: (params: any) => getGttRows.statusBar(params.row),
    width: 20
  },
  {
    field: 'instrument',
    headerName: 'Instrument',
    renderCell: (params: any) => getGttRows.instrument(params.row)
  },
  {
    field: 'created_at',
    headerName: 'Created on',
    renderCell: (params: any) => getGttRows.created_at(params.row)
  },
  {
    field: 'transaction_type',
    headerName: 'Type',
    renderCell: (params: any) => getGttRows.transaction_type(params.row)
  },
  {
    field: 'trigger_values',
    headerName: 'Trigger',
    renderCell: (params: any) => getGttRows.trigger_values(params.row),
    width: 160
  },
  {
    field: 'qty',
    headerName: 'Qty',
    renderCell: (params: any) => getGttRows.qty(params.row)
  },
  {
    field: 'average_price',
    headerName: 'LTP',
    renderCell: (params: any) => getGttRows.average_price(params.row)
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params: any) => getGttRows.status(params.row),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    getActions: (params: any) => getGttRows.actions(params.row, deleteGttOrderAction),
  }
]

const config = { gttCols, getGttRows, gttInitialState };

export default config;