
import { useMemo } from 'react';

// Actions
export const useNotification = (dispatch: any) => {
  // Important(!): memoize array value. Else all context consumers update on *every* render
  const actions = useMemo(() => ({
    notify: ({ message='', severity='success' }: any) => {
      dispatch({ type: 'SHOW_NOTIFICATION', payload: {message, severity} })
    },

    closeNotication: () => {
      dispatch({ type: 'HIDE_NOTIFICATION' })
    }
  }), [dispatch])
  return actions;
}

export default useNotification;