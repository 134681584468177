
import { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Collapse } from '@mui/material';
import './responsive-table.scss';

let SORTING_ID = 'sector';

const Trow = ({ data, rows, columns }: any) => {
  const [open, setOpen] = useState(false);

  const columnList: any = [];
  columns.map((colData: any) => {
    const colKey = colData.id;
    if (rows[colKey]) {
      const className = colData.className
      const toggleCollapse = (colData.action && colData.action === 'toggle-collapse') ? true : false;
      if (toggleCollapse) {
        data.collapseState = open;
        data.onClick = () => setOpen(!open);
      }
      const val = rows[colKey](data);
      columnList.push(
        <TableCell key={'td-' + colKey} data-label={colKey}
          className={className}
          style={colKey === 'sector' ? { borderColor: `${data.color}88`, borderStyle: 'solid', backgroundColor: `${data.color}08` } : {}}
        > {val}</TableCell >
      );
    }
    if(colData.defaultSortField){
      SORTING_ID = colKey
    }
    return colData;
  })

  return <><TableRow >{columnList}</TableRow>
    <TableRow className="collapseabel-row">
      <TableCell colSpan={columns.length}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {rows.collpseContent && data.gtt && rows.collpseContent(data)}
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

const ResponsiveTable = ({ data, columns, rows, className }: any) => {
  data.sort((a: any, b: any) => (b[SORTING_ID] < a[SORTING_ID] ? 1 : -1));
  const [isAsc, setIsAsc]: any = useState(true);

  const generateTbody = (data: any) => {
    return data.map((d: any, ri: any) => {
      return <Trow key={'tbr-' + ri} data={d} rows={rows} columns={columns} />;
    })
  }
  const [tbody, setTbody]: any = useState(generateTbody(data));

  useEffect(() => {
    setTbody(generateTbody(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const sortCol = (col: any) => {
    const dataType = col.dataType ? col.dataType : 'number';
    const ntd = data.sort((a: any, b: any) => {
      if (dataType === 'number') {
        const diff: any = parseFloat(b[col.id]) < parseFloat(a[col.id]);
        if (isAsc) {
          return diff ? 1 : -1;
        } else {
          return !diff ? 1 : -1;
        }
      } else {
        const diff: any = b[col.id] < a[col.id] ? -1 : (b[col.id] > a[col.id] ? 1 : 0);
        if (isAsc) {
          return diff;
        } else {
          return diff * -1;
        }
      }
    })
    setIsAsc(!isAsc);
    setTbody(generateTbody(ntd));
  }

  const columnList = columns.filter((col: any) => {
    return col.disabled ? false : true;
  }).map((col: any, i: any) => {
    return (<TableCell key={'th-' + i} className={col.className}>
      <span onClick={() => sortCol(col)}>{col.label}</span>
    </TableCell>)
  })
  const thead = (<TableRow>{columnList}</TableRow>);



  return (
    <Table aria-label="collapsible table" className={"responsive-table " + className}>
      <TableHead>
        {thead}
      </TableHead>
      <TableBody>
        {tbody}
      </TableBody>
    </Table>
  );

};

export default ResponsiveTable;