import { makeStyles, createStyles } from '@mui/styles';

const drawerWidth = 240;

const cssInJs = (theme: any) => createStyles(
  {
    appToolBar: {
      minHeight: 48,
      zIndex: 51,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loadingBtn: {
      color: '#aaa'
    },
    navs: {
      display: 'flex',
      flexGrow: 1,
      '& .MuiButton-root': {
        color: '#333',
        marginRight: theme.spacing(2),
        borderRadius: 0,
        borderBottom: `2px solid transparent`,
        borderTop: `2px solid transparent`
      },
      '& .MuiButton-root.active': {
        borderBottom: `2px solid`
,      },

    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }
);

export default makeStyles(cssInJs);