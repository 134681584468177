import { makeStyles, createStyles } from '@mui/styles';
import theme from "./theme.css";

const cssInJs = () => createStyles(
  {
    app: {
      backgroundColor: theme.palette.background.default,
      /* scrollbar */
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.divider
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: 'linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%)'
      },
      '& ::-webkit-scrollbar-track': {
        background: theme.palette.divider
      }
    },

  }
);

export default makeStyles(cssInJs);