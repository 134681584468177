import {
  ReadMoreRounded, ArrowUpwardRounded, ArrowDownwardRounded, KeyboardArrowDown, KeyboardArrowUp
} from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import RangeSlider from '../../components/range-slider';
import InlineGtt from './InlineGtt';


export const holdingsInitialState = {
  sector: false,
  last_price: true,
  weight: false,
  pnl: false,
  day_change_percentage: false,
  actions: false,
  gtt: false,
  circuts: true
}

export const holdingsSorting: any = {
  sortModel: [{ field: 'weight', sort: 'desc' }],
}

export const holdingRows = {
  sector: (d: any) => (<span title={d.sector}>
    {d.gtt && <span role="button"
      title={'GTT: ' + d.gtt.orders[0]['transaction_type'] + JSON.stringify(d.gtt.condition.trigger_values)}
      onClick={d.onClick}
    ><ReadMoreRounded /></span>}
  </span>),

  tradingsymbol: (d: any) => (<Stack spacing={0.5} alignItems="center" direction="row" >
    <a className="tsym" href={`https://stocks.tickertape.in/${d.tradingsymbol}`} rel="noreferrer" title={d.instrument_token} target="_blank">{d.tradingsymbol}</a> <small>{d.exchange.substr(0, 1)}</small>
  </Stack>),

  qty: (d: any) => (d.t1_quantity > 0 ? (<><small>T1: {d.t1_quantity}</small> {d.realised_quantity}</>) : d.realised_quantity),
  last_price: (d: any) => (d.last_price?.toFixed(2)),

  pnl: (d: any) => (d && d.net_change_percentage && <span
    className={d.net_change_percentage >= 0 ? 'positive' : 'negative'}
  >{Math.abs(d.net_change_percentage?.toFixed(1))}% {d.net_change_percentage >= 0 ? <ArrowUpwardRounded sx={{ fontSize: "16px", verticalAlign: 'top' }} /> : <ArrowDownwardRounded sx={{ fontSize: "16px", verticalAlign: 'top' }} />} {Math.abs(d.rpnl)?.toFixed(2)}</span>),

  day_change_percentage: (d: any) => (<span
    className={d.day_change_percentage >= 0 ? 'positive' : 'negative'}
  >{d.day_change_percentage?.toFixed(1)}%</span>),

  gtt_trigger: (d: any) => {
    if (d.gtt) {
      const tType = d.gtt.orders[0].transaction_type;
      let min = d.gtt.condition.trigger_values[0] || 0;
      let max = d.gtt.condition.trigger_values[1] || min + 100;
      if (d.gtt.type === 'single') {
        max = min;
        min = d.last_price - 50;
      }
      if (tType === 'BUY') {
        min = d.gtt.condition.trigger_values[0] || 0;
        max = d.last_price;
      }
      min = +min.toFixed(1);
      max = +max.toFixed(1);
      const val = +d.last_price.toFixed(1);
      return (<RangeSlider keyVal={d.gtt.condition.tradingsymbol} color={tType === 'SELL' ? 'secondary' : 'primary'} min={min} max={max} value={val} />)
    }
  },

  actions: (d: any) => {
    return (
      d.gtt && (<IconButton className={`btn-collapse ${d.collapseState ? 'active' : ''}`} onClick={d.onClick}
      >{d.collapseState ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>))
  },

  collpseContent: (d: any) => (
    <>
      {d.gtt ? (<span>{d.gtt.orders[0].transaction_type}: ${d.gtt.condition.trigger_values[0]} <strong>${d.last_price}</strong> ${d.gtt.condition?.trigger_values[1]}</span>) : 'Empty'
      }
    </>
  ),

  setTarget: (d: any, params: any) => {
    return <InlineGtt rowData={d} {...params} />
  },

  circuts: (d: any, params: any) => {
    return <div>
      <Button variant="outlined" size="small"  onClick={() => params.placeCircutOrder(false, d)}>U: {d.upper_circuit_limit}</Button>
      <span>L: {d.lower_circuit_limit}</span>
    </div>
  }
}


export const holdingCols = (params: any) => {
  return [
    {
      field: 'sector',
      headerName: 'Sector', //<Stream />
      renderCell: (d: any) => holdingRows.sector(d.row),
      width: 60
    },
    {
      field: 'tradingsymbol',
      headerName: 'Instrument',
      renderCell: (d: any) => holdingRows.tradingsymbol(d.row)
    }, {
      field: 'qty',
      headerName: 'Qty',
      type: 'number',
      renderCell: (d: any) => holdingRows.qty(d.row),
      width: 80
    }, {
      field: 'last_price',
      headerName: 'LTP',
      type: 'number',
      valueFormatter: (d: any) => (d?.value?.toFixed(2)),
    }, {
      field: 'weight',
      headerName: 'Weight',
      type: 'number',
      valueFormatter: (d: any) => (d?.value?.toFixed(2)),
    }, {
      field: 'pnl',
      headerName: 'P&L',
      type: 'number',
      renderCell: (d: any) => holdingRows.pnl(d.row),
    }, {
      field: 'day_change_percentage',
      headerName: 'Day chg',
      type: 'number',
      renderCell: (d: any) => holdingRows.day_change_percentage(d.row),
    },
    {
      field: 'gtt',
      headerName: 'GTT trigger',
      renderCell: (d: any) => holdingRows.gtt_trigger(d.row)
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (d: any) => holdingRows.actions(d.row),
      sortable: false
    },
    {
      field: 'gtt_actions',
      headerName: 'Set target',
      renderCell: (d: any) => holdingRows.setTarget(d.row, params),
      width: 336,
      sortable: false
    },
    {
      field: 'circuts',
      headerName: 'Circuts',
      renderCell: (d: any) => holdingRows.circuts(d.row, params)
    }
  ]
}

const config = { holdingRows, holdingCols, holdingsInitialState, holdingsSorting };
export default config;
