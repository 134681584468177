import React from 'react'
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'
import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { userConfig } from "../config/settings";

import useStyles from './app-header.css';
import './app-header.scss';
import { isLoggedTApp } from '../common/utils';
const snowCount = userConfig.activeTheme.snowCount;

function AppHeader(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const snow = Array.from(Array(snowCount).keys());

  const setDefaultNav = (nav: any) => {
    localStorage.setItem('defaultNav', nav);
  }

  const toolbar = (<Toolbar className={classes.appToolBar}>
    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={props.toggleDrawer} >
      <MenuIcon />
    </IconButton>
    <div className={classes.navs}>
      <Button className={(!props.activeIndex || props.activeIndex === 0) ? 'active' : ''} onClick={() => setDefaultNav('/stocks')} href="/stocks">
        <Typography variant="h6">
          Stocks
        </Typography>
      </Button>
      <Button onClick={() => {
        setDefaultNav('/screener')
        history.push(`/screener`);
      }} className={(props.activeIndex === 1) ? 'active' : ''}>
        <Typography variant="h6" >
          Screener
        </Typography>
      </Button>
      <Button onClick={() => {
        setDefaultNav('/profile');
        history.push(`/profile`);
      }} className={(props.activeIndex === 2) ? 'active' : ''}>
        <Typography variant="h6" >
          Profile
        </Typography>
      </Button>
      {isLoggedTApp() &&
        <Button onClick={() => {
          history.push(`/trade`);
        }} className={(props.activeIndex === 3) ? 'active' : ''}>
          <Typography variant="h6" >
            Trade
          </Typography>
        </Button>
      }
    </div>
    {/* <Button
      onClick={props.getChallenges}
      size="small"
      className={classes.loadingBtn}
      startIcon={<RotateLeftIcon className={props.challengesFlag ? 'spin' : ''} />}
    >

      <Typography variant="body2">{props.lastUpdated ? `${moment(props.lastUpdated).format('DD MMM hh:mm:ss')}` : ''}
      </Typography>
    </Button> */}
    {
      isLoggedTApp()
        ? <>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" 
          onClick={()=>{history.push('/userSettings')}} >
            <AccountCircle />
          </IconButton>
          <Button href="/signoutApp">Logout</Button>
        </>
        : <Button href="/login">Login</Button>
    }
  </Toolbar >)

  const toolbarSelfRegister = (
    <Toolbar className={classes.appToolBar}>
      <div className={classes.navs}>
        {props.headerContent}
      </div>
      <div>
        {props.welcomeMsg}
      </div>
    </Toolbar>
  )

  let activeToolbar = toolbar;

  switch (props.type) {

    case 'selfRegister':
      activeToolbar = toolbarSelfRegister;
      break;

    default:
      activeToolbar = toolbar;
      break;
  }
  return (
    <AppBar elevation={0} color="secondary" position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      {activeToolbar}
      <div className="hOveraly skyOverlay daySkyOverlay">
        {
          userConfig.headerAnimation && snow.map((i: any) => {
            return <div key={'ho-' + i} className="snow"></div>
          })
        }
      </div>
    </AppBar>
  )
}

export default AppHeader;
