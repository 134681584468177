import { makeStyles, createStyles } from '@mui/styles';

const cssInJs = (theme: any) => createStyles(
  {
    headerOpts: {
      display: 'flex',
      alignItems: 'center',

      '& .opts': {
        marginRight: 80,
        background: 'green'
      }
    },
    chartGroup: {

      '& figure': {
        margin: 0,
        height: 'calc(100% - 10px)'
      },

      '& .loading-progress': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 10px)'
      }
    },
    sidebar: {
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      },
      '& .sectors': {
        margin: 0,
        padding: 0
      },
      '& .sectors li': {
        listStyle: 'none',
        lineHeight: '16px',
        marginBottom: '8px'
      },
      '& .sectors li.active': {
        fontWeight: 'bold'
      },
      '& .sectors li span': {
        verticalAlign: 'top'
      }
    },
    pane: {
      padding: theme.spacing(2),
      paddingTop: 0,
      overflow: 'auto',
      height: 'calc(100vh - 96px)',
    },
  }
);

export default makeStyles(cssInJs);