import React, { useState } from "react";
import { Alert, Container, IconButton, Stack, TextField, Typography } from "@mui/material"
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CheckIcon from '@mui/icons-material/Check';
import { useForm, Controller } from "react-hook-form";
import { placeGttOrder, deleteGttOrder } from "../../services/trade-services";
import { getSingleOrderPayload } from "./normalize";
import { initialGttOrderRequest } from "./constants";
import ProgressCard from "../../components/progress-card/progress-card";
import { currencyFormat } from "../../common/utils";

export const defaultValues = {
  trigger: '',
  target: '',
  percentage: ''
};

const ViewState = {
  FORM: 'form',
  STATUS: 'status',
  VALUE: 'value'
}

const InlineGtt = ({ rowData, getGttTriggersReq }: any) => {

  // setting gtt target value
  const targetValue: any = {};
  let defalutViewState = ViewState.FORM;
  let barColor = 'primary';

  if (rowData.gtt) {
    // console.log('rowGtt', rowData.gtt)
    targetValue.orderPrice = rowData.gtt?.orders[0]?.price;
    targetValue.lastPrice = rowData.gtt?.condition?.last_price;
    targetValue.percentage = (targetValue.orderPrice - targetValue.lastPrice) / targetValue.lastPrice * 100;
    targetValue.remainingPercentage = targetValue.percentage.toFixed(2);
    defalutViewState = ViewState.VALUE;
    targetValue.isChangePositive = targetValue.percentage >= 0;

    barColor = targetValue.isChangePositive ? 'secondary' : 'primary';
  }

  const [state, setState]: any = useState({
    openSnack: defalutViewState
  });
  const [gttRequestData, setGttRequestData] = useState(initialGttOrderRequest);

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultValues

  });

  const fieldPercentage = watch('percentage');
  const watchTrigger = watch('trigger');

  React.useEffect(() => {
    if (!state.updatingForm && fieldPercentage) {
      setState({ ...state, updatingForm: true })
      const lastPrice = rowData?.last_price;
      const percentageVal = parseFloat(fieldPercentage);
      const triggerPrice = (lastPrice + lastPrice * percentageVal / 100).toFixed(2);
      setValue("trigger", triggerPrice);
      setValue("target", triggerPrice);
      window.setTimeout(() => { setState({ ...state, updatingForm: false }) }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPercentage])

  React.useEffect(() => {
    if (!state.updatingForm && watchTrigger) {
      setState({ ...state, updatingForm: true })
      const lastPrice = rowData?.last_price;
      const triggerPrice = parseFloat(watchTrigger);
      const percentageVal = (triggerPrice - lastPrice) / lastPrice * 100;
      setValue("percentage", percentageVal.toFixed(2));
      window.setTimeout(() => { setState({ ...state, updatingForm: false }) }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTrigger])

  const deleteOrder = () => {
    const delReq: any = deleteGttOrder(rowData.gtt.id);
    delReq.promise
      .then((res: any) => {
        setState({
          ...state,
          snackMessage: res.message ? res.message : `Gtt order ${rowData.gtt.id} deleted.`,
          snackSeverity: res.status,
          openSnack: ViewState.STATUS
        });
        getGttTriggersReq()
      })
      .catch((error: any) => {
        setState({
          ...state,
          snackMessage: error.message,
          snackSeverity: 'error',
          openSnack: ViewState.STATUS
        });
      })
  }

  const createOrder = (formData: any) => {
    const sellingPrice = parseFloat(formData.target).toFixed(1);
    const triggerPrice = parseFloat(formData.trigger).toFixed(1);
    const payload = getSingleOrderPayload({
      exchange: rowData.exchange,
      trigger_values: [triggerPrice],
      quantity: rowData.quantity || rowData.t1_quantity,
      tradingsymbol: rowData.tradingsymbol,
      transaction_type: 'SELL',
      price: sellingPrice,
      last_price: rowData.last_price
    })
    setGttRequestData({
      ...gttRequestData,

      type: 'single'
    });

    const po: any = placeGttOrder(payload);
    po.promise
      .then((res: any) => {
        setState({
          ...state,
          snackMessage: res.message ? res.message : `GTT order created`,
          snackSeverity: res.status,
          openSnack: ViewState.STATUS
        });

        if (res.status === 'error') {
          setState({
            ...state,
            snackSeverity: 'error',
            openSnack: ViewState.STATUS
          });
        }
        getGttTriggersReq();
      })
      .catch((error: any) => {
        setState({
          ...state,
          snackMessage: error.message,
          snackSeverity: 'error',
          openSnack: ViewState.STATUS
        });
      })
  }

  const onSubmit = (data: any) => {
    createOrder(data);
  }

  const handleReset = () => {
    reset();
    setState({
      ...state,
      snackMessage: '',
      snackSeverity: '',
      openSnack: ViewState.FORM
    })
  }

  return (
    <form className='inline-gtt form-sm'
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Container sx={{ display: state.openSnack === ViewState.FORM ? 'block' : 'none' }} disableGutters>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Trigger" variant="outlined"
              sx={{ width: 76 }}
              size="small"
              error={!!error}
            />
          )}
          name="trigger"
          control={control}
          rules={{ required: true }}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="%" variant="outlined"
              sx={{ width: 54, marginLeft: 1 }}
              size="small"
              error={!!error}
            />
          )}
          name="percentage"
          control={control}
          rules={{ required: true }}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Target" variant="outlined"
              sx={{ width: 76, marginLeft: 1 }}
              size="small"
              error={!!error}
            />
          )}
          name="target"
          control={control}
          rules={{ required: true }}
        />

        <IconButton size="small"
          sx={{ marginLeft: 1 }}
          onClick={handleSubmit(onSubmit)}
          color='primary'
        ><TaskAltIcon /></IconButton>
      </Container>
      <Container sx={{ display: state.openSnack === ViewState.STATUS ? 'block' : 'none' }} disableGutters>
        <Alert icon={<CheckIcon fontSize="inherit" />} severity={state.severity}
          action={
            <IconButton size="small"
              onClick={handleReset}
              color='primary'
            ><TaskAltIcon /></IconButton>
          }
          sx={{ maxWidth: '300px' }}
        >
          {state.snackMessage}
        </Alert>
      </Container>
      <Container sx={{ display: state.openSnack === ViewState.VALUE ? 'block' : 'none' }} disableGutters>
        <Stack spacing={1}
          direction="row"
          alignItems="center"
        >
          <ProgressCard value={Math.abs(targetValue.percentage)}
            color={barColor}
            progressStyle={{
              backgroundColor: targetValue.isChangePositive ? '#ffe7df' : '#ecf4ff'
            }}
            sx={{ paddingRight: 0, width: '221px' }}
          >
            <Stack spacing={0.5}
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Typography variant='body2' component='span' sx={{ fontWeight: 'bold' }} >{targetValue.remainingPercentage}%</Typography>
              {/* <Typography variant='body2' component='span' ></Typography> */}
              <Typography variant='body2' component='span' >{currencyFormat(targetValue.lastPrice)} / {currencyFormat(targetValue.orderPrice)}</Typography>
            </Stack>
          </ProgressCard>

          <IconButton size="small"
            sx={{ marginLeft: 0.5, display: rowData.gtt ? 'inline-flex' : 'none' }}
            onClick={deleteOrder}
            color='secondary'
          ><DeleteOutlineRoundedIcon /></IconButton>
        </Stack>
      </Container>
    </form>
  )
}

export default InlineGtt;