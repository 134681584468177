import { useEffect } from 'react'

const Callback = () => {
  useEffect(() => {
    const params = window.location.search;
    const token = params.split('=')[1];
    console.log('token sent ' + token);
    if (!!token) {
      localStorage.setItem('authToken', token);
      if (window.opener) {
        window.setTimeout(() => {
          window.opener.postMessage({ authToken: token, type: "authToken" }, "*");
          window.close();
        });
      }
    }
  })

  return (
    <>
      <div>Loading...</div>
    </>
  )
}

export default Callback;