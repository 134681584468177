import { Grid, Typography, Button } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { AlphaIcon, BetaIcon } from "../icons/Icons";

import { tabView } from "../common/constants";

import useStyles from './stocks.css';

function StockCell(props: any) {
  const classes = useStyles();

  const info = props.stock.info;
  const advRatios = props.stock.advancedRatios;
  const chName = `[${props.sid}] ${info.name}`;

  return (
    <div
      onFocus={(event) => event.stopPropagation()}>
      <Grid>
        <Grid item xs={12}>
          <div>
            <Button
              title={chName + ''} className={classes.challengeNameLink}
              onClick={() => { props.setActiveChallenge(props.id) }}
              color="primary">{chName}</Button>
          </div>
        </Grid>

        <Grid item xs={12} onClick={(event) => { event.stopPropagation() }}>
          <Typography className={classes.buttonList}>
            <Button
              title="Registrations"
              color="primary"
              size="small"
              onClick={() => props.getResources(props, tabView[1])}
              startIcon={<AlphaIcon style={{ fontSize: 13 }} />}
            >
              {advRatios['3Ywal']?.toFixed(4)}
            </Button>
            <Button
              title="Registrations"
              color="primary"
              size="small"
              onClick={() => props.getResources(props, tabView[1])}
              startIcon={<BetaIcon style={{ fontSize: 18 }} />}
            >
              {advRatios.beta?.toFixed(4)}
            </Button>
            <Button
              title={info.sector}
              color="primary"
              size="small"
              onClick={() => props.getResources(props, tabView[1])}
              startIcon={<SupervisedUserCircleIcon style={{ color: props.color }} />}
            >
              {props.sectorSym}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default StockCell;