import { makeStyles, createStyles } from '@mui/styles';
import theme from "../theme.css";

const drawerWidth = 240;

const cssInJs = () => createStyles(
  {
    root: {
      display: 'flex',
    },
    appToolBar: {
      minHeight: 48,
    },
    challengeContent: {
      padding: theme.spacing(2),
      paddingTop: 0,
      overflow: 'auto',
      height: 'calc(100vh - 96px)',
    },
    challenges: {
      margin: 0,
      maxHeight: 'calc(100vh - 96px)',
      maxWidth: 512,
      [theme.breakpoints.down('md')]: {
        maxWidth: 1000,
        overflowX: 'hidden'
      },
    },
    sidebar: {
      margin: 0,
      maxHeight: 'calc(100vh - 96px)',
      maxWidth: 512,
      [theme.breakpoints.down('md')]: {
        maxWidth: 1000,
        overflowX: 'hidden'
      },
      overflow: 'auto',
      paddingBottom: '16px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loadingBtn: {
      color: '#aaa'
    },
    iconButtonLink: {
      color: '#2196f3'
    },
    title: {
      flexGrow: 1,
    },
    challengeGrid: {
      justifyContent: 'center',
      position: 'relative',
      '& > .MuiGrid-item': {
        padding: 0
      }
    },
    challengeDetails: {
      color: '#333333',

      '& img': {
        maxWidth: '100%'
      },

      '&  a': {
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        }
      },
    },
    challengeDetailsInline: {
      overflow: 'auto',
      maxWidth: `calc(100vw - 42px)`,
      '& img': {
        maxWidth: '100%'
      },

      '&  a': {
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        }
      },
    },
    challengeContentList: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    challengeSummary: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,

        '& .MuiIconButton-edgeEnd': {
          marginRight: 0
        }
      },
    },
    challengeNameLink: {
      justifyContent: 'start',
      textTransform: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      whiteSpace: 'nowrap',
      flex: '0 1 0%',
      textAlign: 'left',

      '& span': {
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '360px',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '280px',
      },
    },
    challengeColList: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 180,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    Icobtn: {
      fontSize: 12,
      lineHeight: '24px',
      '& a': {
        textDecoration: 'none',
      },
      '& svg': {
        verticalAlign: 'top'
      }
    },
    asideWrap: {
      marginRight: `${theme.spacing(1)}px`,
      maxWidth: 512,
      [theme.breakpoints.down('md')]: {
        maxWidth: 1000,
        overflowX: 'hidden'
      },
      '& .infinite-scroll-component ': {
        maxHeight: 'calc(100vh - 96px)',
        overflow: 'auto',
        paddingLeft: '5px',
        paddingBottom: '5px'
      }
    },
    challengeItem: {
      width: 'calc(100% - 5px)',
      borderTop: '1px solid #eee'
    },
    // radial-gradient(circle, rgba(28,181,224,1) 0%, rgba(254,211,0,1) 24%, rgba(0,8,81,1) 32%);
    // background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);
    // background: linear-gradient(90deg, #030103 0%, #515ada 100%);
    // background: linear-gradient(90deg, #daae51 0%, #d53369 32%);
    // appbar
    appBar: {
      // background: 'radial-gradient(circle, #000851 0%, #000851 32%)',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // '&:hover': {
      //   background: 'radial-gradient(circle, #1CB5E0 0%, #000851 32%)',
      // }
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // content
    content: {
      flexGrow: 1,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      maxWidth: '100vw',
      minHeight: '100vh'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    noDrawer: {
      marginLeft: 0,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      [theme.breakpoints.up('md')]: {
        minHeight: theme.spacing(6),
      },
    },
    buttonList: {
      '& svg': {
        fontSize: '1.2rem'
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75)
      },
      '& button': {
        minWidth: 42
      }
    },
    timeLabel: {
      textTransform: 'none'
    }
  }
);

export default makeStyles(cssInJs);