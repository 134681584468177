import axios, { CancelTokenSource, Method } from 'axios';
import { appConfig } from "../config/settings";

const baseUrl = appConfig.api.baseUrl;

const restApiAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface CallResult<T> {
  promise: Promise<T>;
  cancelToken: CancelTokenSource;
}
/**
 * Call API in a cancellable way
 * @param url the url to call API
 * @param method HTTP Method
 * @param data request payload
 * @returns CallResult
 */
export function callCancellableNodeApi<T>(
  url: string,
  method: Method,
  data?: any
): CallResult<T> {
  const source = axios.CancelToken.source();
  const authToken = localStorage.getItem('authToken');
  restApiAxios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  const promise = restApiAxios
    .request({
      url,
      method,
      data,
      cancelToken: source.token,
    })
    .then((res) => res.data)
    .catch((reason) => {
      if (axios.isCancel(reason)) {
        console.log('Request has been cancelled.');
      } else {
        console.error('API call error', reason);
      }
    });
  return {
    promise,
    cancelToken: source,
  };
}

export const getScreener = (data: any) => {
  return callCancellableNodeApi(`/Screener`, 'POST', data)
};

/**
 *
 * @returns array: stock mapping deatails & baisc info
 */
export const getStockInfo = () => {
  const queryData = { "match": {}, "sortBy": "mrktCapf", "sortOrder": -1, "sids": [], "project": ["subindustry", "mrktCapf"], "offset": 0, "count": 4500 };
  return callCancellableNodeApi(`/Screener`, 'POST', queryData);
};
/**
 * 
 * @param data query data
 * @returns holdings
 */
export const getHoldings = (data: any = '') => {
  return callCancellableNodeApi(`/holding?perPage=1&orderBy=updatedAt`, 'GET', data);
};

/**
 * 
 * @param data query data
 * @returns holdings
 */
export const getLiveHoldings = (data: any = '') => {
  return callCancellableNodeApi(`/portfolio/holding`, 'GET', data);
};

export const setHoldings = (data: any = '') => {
  return callCancellableNodeApi(`/holding`, 'POST', data);
};

/**
 * 
 * @param data query data
 * @returns holdings
 */
export const getOrders = async (data: any = '') => {
  return callCancellableNodeApi(`/oms/orders`, 'GET', data);
};

/**
 * 
 * @param data query data
 * @returns holdings
 */
export const getInstruments = (data: any = '') => {
  return callCancellableNodeApi(`/instruments/NSE`, 'GET', data);
};

/**
 * 
 * @param data query data
 * @returns quote
 */
export const getQuote = (data: any = '') => {
  return callCancellableNodeApi(`/quote?i=${data}`, 'GET');
};
/**
 * 
 * @param data query data
 * @returns quote
 */
export const getGttTriggers = () => {
  return callCancellableNodeApi(`/oms/gtt/triggers`, 'GET');
};
/**
 * 
 * @param data query data
 * @returns quote
 */
export const getGttTriggersById = (gttId: any = '') => {
  return callCancellableNodeApi(`/oms/gtt/triggers/${gttId}`, 'GET');
};

/**
 * signIn
 */
export const signIn = (data: any) => {
  return callCancellableNodeApi(`/auth`, 'POST', data)
};

/**
 * 
 * @param data query data
 * @returns quote
 */
export const getQuoteLtp = (quotes: any = '') => {
  return callCancellableNodeApi(`/quote/ltp?${quotes}`, 'GET');
};

/**
 * updateUser
 */
export const updateUser = (data: any) => {
  return callCancellableNodeApi(`/user`, 'PATCH', data)
};

/**
 * getUser
 */
export const getUser = () => {
  return callCancellableNodeApi(`/user`, 'GET')
};

/**
 * getMutualFund
 */
export const getMutualFund = (data:any) => {
  return callCancellableNodeApi(`/mutualfunds/${data}`, 'GET')
};

/**
 * getProxy
 */
export const getProxy = () => {
  return callCancellableNodeApi(`/user`, 'GET')
};