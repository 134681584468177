import { CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Legend, Scatter, ResponsiveContainer, ScatterChart, ReferenceLine } from 'recharts';
import "./chart.scss";

const ScatterChartComponent = ({ data }: any) => {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      console.log(payload)
      return (
        <div className="custom-tooltip">
          <h4 className="title">{payload[0]?.payload?.ticker}</h4>
          {payload.map((d: any) => (
            <div key={d.name} className="label">{`${d.name} : ${d.value?.toFixed(4)}`}</div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%"
      height="100%">
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 10,
          left: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" type="number" 
        name="α"
        label={{ value: 'α', position: 'insideBottom', offset: 0 }} />
        <YAxis dataKey="y" type="number"
          name="β"
          label={{ value: 'β', offset: 0 }}
          domain={[(dataMin: any) => (parseInt(dataMin) - 0.7), (dataMax: any) => parseInt(dataMax) + 0.7]} />
        {/* <ZAxis dataKey="x" type="number" name="α" /> */}
        <ReferenceLine y={1} stroke="#ddd" />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip />}
        />
        <Legend />
        {
          data.map((seriesData: any) => (
            <Scatter key={seriesData.id} name={seriesData.name} data={seriesData.data} fill={seriesData.color} />
          ))
        }
      </ScatterChart>
    </ResponsiveContainer>


  )
}

export default ScatterChartComponent;
