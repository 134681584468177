import React from 'react'
import StreamIcon from '@mui/icons-material/Stream';
import moment from "moment";

export const myOrdersCols = [
  {
    id: 'sector',
    label: (<span title="Transaction type"><StreamIcon /></span>),
    className: 'col-sector',
    dataType: 'string',
  },
  {
    id: 'exchange_update_timestamp',
    label: 'Time',
    className: 'align-left'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    dataType: 'string',
    className: 'align-left'
  }, {
    id: 'qty',
    label: 'Qty'
  }, {
    id: 'product',
    label: 'Product',
    className: 'align-left'
  },  {
    id: 'transaction_type',
    label: 'Type',
    className: 'align-left'
  }, {
    id: 'status',
    label: 'Status',
    className: 'align-left'
  }, {
    id: 'average_price',
    label: 'Avg Price'
  }
]

export const myOrdersRows = {
  sector: (d: any) => { return (<span title={d.transaction_type}></span>)},

  exchange_update_timestamp: (d: any) => {
    const t = d.order_timestamp;
    return t ? moment(t).format('LT D/M') : null
  },

  instrument: (d: any) => (<><a className="tsym" href={`https://stocks.tickertape.in/${d.tradingsymbol}`} rel="noreferrer" target="_blank">{d.tradingsymbol}</a> <small>{d.exchange}</small></>),

  qty: (d: any) => ( (d.quantity - d.pending_quantity) + ' / ' + d.quantity),

  product: (d: any) => (d.product),

  transaction_type: (d: any) => (<span
    className={d.transaction_type === 'BUY' ? 'positive' : 'negative'}
  >{d.transaction_type}</span>),

  status: (d: any) => (<span
    className={d.status === 'COMPLETE' ? 'positive' : 'negative'}
  >{d.status}</span>),

  average_price: (d: any) => (<span>{
    d.trigger_price > 0 && d.trigger_price ?  d.price.toFixed(2) + '/' + d.trigger_price.toFixed(2) + ' trg.' : (d.price ? d.price.toFixed(2): d.average_price.toFixed(2) )
  }</span>)
}