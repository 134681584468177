import { useMemo } from "react";

export interface IState {
  holdings: any;
  holdingSectorData: any;
}

// export type Actions = IState

export const holding = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_HOLDINGS':
      return {
        ...state,
        holdings: action.payload
      };
    case 'SET_TOTAL':
      return {
        ...state,
        total: action.payload
      };
    case 'SET_HOLDING_SECTOR_DATA':
      return {
        ...state,
        holdingSectorData: action.payload
      };
    case 'SET_SIDS':
      return {
        ...state,
        sids: action.payload
      };
    default:
      return state;
  }
};

// actions
export const useHolding = (dispatch: any) => {
  // Important(!): memoize array value. Else all context consumers update on *every* render
  const actions = useMemo(() => ({
    updateHolding: (payload: any) => {
      dispatch({ type: 'SET_HOLDINGS', payload: payload })
    },
    updateTotal: (payload: any) => {
      const total = { invested_amt: 0, cur_val: 0 };
      payload?.forEach((d: any) => {
        total.invested_amt += d.invested_amt;
        total.cur_val += d.cur_val;
      })
      dispatch({ type: 'SET_TOTAL', payload: total })
    },
    updateSectorData: (payload: any) => {
      dispatch({ type: 'SET_HOLDING_SECTOR_DATA', payload: payload })
    },
    updateSids: (payload: any) => {
      dispatch({ type: 'SET_SIDS', payload: payload })
    }
  }), [dispatch])
  return actions;
}

export default holding;
