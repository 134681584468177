import axios, { CancelTokenSource, Method } from 'axios';
import { appConfig } from "../config/settings";
const qs = require("qs");

const baseUrl = appConfig.api.baseUrl;

const restApiAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});

export interface CallResult<T> {
  promise: Promise<T>;
  cancelToken: CancelTokenSource;
}
/**
 * Call API in a cancellable way
 * @param url the url to call API
 * @param method HTTP Method
 * @param data request payload
 * @returns CallResult
 */
export function callCancellableNodeApi<T>(
  url: string,
  method: Method,
  data?: any
): CallResult<T> {
  const source = axios.CancelToken.source();
  restApiAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
  const promise = restApiAxios
    .request({
      url,
      method,
      data,
      cancelToken: source.token,
    })
    .then((res) => res.data)
    .catch((reason) => {
      if (axios.isCancel(reason)) {
        console.log('Request has been cancelled.');
      } else {
        console.error('API call error', reason);
      }
    });
  return {
    promise,
    cancelToken: source,
  };
}

export const placeOrder = (data: any) => {
  return callCancellableNodeApi(`/oms/orders/${data.variety}`, 'POST', qs.stringify(data))
};

export const placeGttOrder = (data: any) => {
  return callCancellableNodeApi(`/oms/gtt/triggers`, 'POST', qs.stringify(data))
};

export const deleteGttOrder = (id: any) => {
  return callCancellableNodeApi(`/oms/gtt/triggers/${id}`, 'DELETE')
};

export const getLimitsFromWS = (data: any) => {
  return callCancellableNodeApi(`/wsquote?instrumentTokens=${data}`, 'GET')
}