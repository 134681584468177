import { useState, useEffect, useContext, useMemo } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material';

import TemplateDefault from "../template-default";
import ProfilePie from "../../components/profile-pie";
import ProfileTreemap from "../../components/profile-treemap";
import AppHeader from '../../components/app-header';
import { getHoldings, getStockInfo, getMutualFund } from "../../services/api-services";
import { processHoldingData, generateHodlingSector, generateTreemapData } from "../../common/utils";
import { getStockSectorMapping } from "../../common/utils-init";
import { profileCols, profileInitialState, profileSorting } from '../profile-table-config';
import useStyles from '../stock-line.css';
import './profile.scss';
import { Context } from '../../context/store';
import CustomTabPanel from '../../components/CustomTabPanel';
import TradingViewHeatmap from "../../components/TradingViewWidget/TradingViewHeatmap";
import TradingViewStock from "../../components/TradingViewWidget/TradingViewStock";
import MfChart from "../../components/charts/mf-chart";
import { useScreener } from '../../context/screener/index';

const MF_SERIES = "M_CANM"

const SORT_KEY = 'weight';
const sortOptoins: any = [{
  id: 'weight',
  label: 'Weight'
},
{
  id: 'day_change_percentage',
  label: 'Day change'
}]

const Profile = () => {
  const { dispatch, state }: any = useContext(Context);
  const { updateMutualFund } = useScreener(dispatch);

  const classes = useStyles();
  const [holdings, setHoldings] = useState([]);
  const [symbol, setSymol] = useState();
  const [loading, setLoading] = useState(false);
  const [holdingSectorData, setHoldingSectorData] = useState([]);
  const [sortBy, setsortBy] = useState(SORT_KEY);
  const [stockSectorMapping, setStockSectorMapping] = useState([]);
  const [treemapdata, setTreemapdata]: any = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [activeMF] = useState('M_CANM');

  const [commonQuery] = useState({
    "match": {
      "mrktCapf": {
        "g": 20000,
        "l": 1695633.6
      }
    },
    "sortBy": "mrktCapf",
    "sortOrder": -1,
    "sids": [],
    "project": [
      "subindustry",
      "mrktCapf",
      "lastPrice",
      "apef",
      "4wpct",
      "pr1w",
      "pr1d",
      "26wpct",
      "52wpct",
      "5yCagrPct"
    ],
    "offset": 0,
    "count": 500
  });

  useEffect(() => {
    setLoading(true);
    const holdings: any = getHoldings();
    holdings.promise
      .then((response: any) => {
        const hData = processHoldingData(response.data);
        setHoldings(hData);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })

    let localSM: any = localStorage.getItem('stockMappingReq');
    localSM = localSM ? JSON.parse(localSM) : '';
    if (localSM.data) {
      setMapping(localSM.data);
    } else {
      const stockMappingReq: any = getStockInfo();
      stockMappingReq.promise
        .then((response: any) => {
          const saveObj: any = {
            time: new Date(),
            data: response
          }
          localStorage.setItem('stockMappingReq', JSON.stringify(saveObj));
          setMapping(saveObj.data);
        })
        .catch((error: any) => {
          console.log(error);
        })
    }

  }, [commonQuery])

  useEffect(() => {
    if (holdings.length > 0 && Object.keys(stockSectorMapping).length > 0) {
      const tmd: any = generateTreemapData(holdings, stockSectorMapping);
      tmd.name && setTreemapdata(tmd);
      const holdingSectorData = generateHodlingSector(holdings, stockSectorMapping);
      setHoldingSectorData(holdingSectorData)
    }
  }, [holdings, stockSectorMapping])

  //getMutualfund
  useEffect(() => {
    setLoading(true);
    const mutualFundReq: any = getMutualFund(activeMF);
    mutualFundReq.promise
      .then((response: any) => {
        const payload: any = {};
        payload[activeMF] = response.data?.currentAllocation;
        updateMutualFund(payload);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setMapping = (response: any) => {
    const ssm = getStockSectorMapping(response);
    setStockSectorMapping(ssm);

  }

  const onsortByChange = (e: any) => {
    console.log(e.target.value);
    setsortBy(e.target.value);
  }

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const udpateSymbol = (newSymbol: any) => {
    setSymol(newSymbol)
    console.log('symbol', symbol)
  }

  const getProfileCols = useMemo(() => {
    return profileCols(udpateSymbol);
  }, [udpateSymbol])

  return (
    <>
      <TemplateDefault
        layout={{
          mainView: 12
        }}
        header={
          (toggleDrawer: any) => {
            return (
              <AppHeader
                handleDrawerOpen={() => { return }}
                challengesFlag={false}
                getChallenges={() => { return }}
                open={false}
                lastUpdated={''}
                activeIndex={2}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        }
      >

        {/* <div className={classes.chartGroup + " profile-group"} style={{ height: '100%' }}> */}
        <Grid className={classes.chartGroup + " profile-group"} container spacing={0}>
          <Grid item xs={6}>
            <div className="view-table stock-table">
              <DataGrid
                rows={holdingSectorData}
                columns={getProfileCols}
                disableRowSelectionOnClick
                hideFooter
                loading={loading}
                slots={{
                  loadingOverlay: LinearProgress,
                  toolbar: () => (
                    <GridToolbarContainer>
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarDensitySelector />
                      <GridToolbarExport />
                    </GridToolbarContainer>
                  ),
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: profileInitialState
                  },
                  sorting: profileSorting
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="view-chart">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Orders"
              >
                <Tab label="PIE" />
                <Tab label="HeatMap" />
                <Tab label="Markets" />
                <Tab label="Stock" />
                <Tab label="Mutual Fund" />
              </Tabs>
              <CustomTabPanel value={tabValue} index={0}>
                <div className="chart-pie">
                  {holdingSectorData.length > 0 && <ProfilePie data={holdingSectorData} sortBy={sortBy} />}
                </div>
                <div className="chart-config-opts">
                  <div className="selector">
                    <label>Chart Type:</label> <select name="sortBySel" id="sortBySel" className="dd-ctrl"
                      onChange={onsortByChange}
                    >
                      {sortOptoins.map((ct: any) => {
                        return (
                          <option key={'ct-' + ct.id} defaultValue={sortBy} value={ct.id}  >{ct.label}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="chart-treemap">
                  {treemapdata.name && <ProfileTreemap data={treemapdata} />}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <TradingViewHeatmap />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3}>
                <TradingViewStock symbol={symbol} />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={4}>
                <MfChart data={state?.screener?.mutualfund[MF_SERIES]} />
              </CustomTabPanel>
            </div>
          </Grid>
        </Grid>
        {/* </div> */}
      </TemplateDefault>
    </>
  )
}

export default Profile;