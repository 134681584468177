import React, { useState, useEffect, useContext } from 'react'
import _ from "lodash";
import TemplateDefault from "../template-default";
import AppHeader from '../../components/app-header';
import { getLiveHoldings, setHoldings, getOrders, getQuote, getGttTriggers, getQuoteLtp } from "../../services/api-services";
import { processHoldingData, generateHodlingSector, processOrdersData, processGttTriggerData, getPercentage, getHoldingSids, currencyFormat, getInstrumentTokensFromHolding } from "../../common/utils";
import ResponsiveTable from "../../components/responsive-table/responsive-table";

import { placeOrder, deleteGttOrder, getLimitsFromWS } from "../../services/trade-services";
import { normalizeOrder } from "./normalize";
import { varietyParams, exchangeParams, transactionTypeParams, productParams, orderTypeParams, orderStatus } from "./params";
import './trade.scss';
import { Alert, Autocomplete, Box, Button, ButtonGroup, Divider, InputAdornment, Snackbar, Stack, TextField, Typography, Paper, LinearProgress, Tab, Tabs, Chip, IconButton, Container } from '@mui/material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import { Context } from '../../context/store'

import { TextNumber } from "../../components/controls/form-controls";

import { myOrdersCols, myOrdersRows } from "./orders-config";

import OrderGtt from "./order-gtt";
import { useHolding } from "../../context/holding.ctx";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';

import TableConfig from "./table-config";
import CustomTabPanel from '../../components/CustomTabPanel';
import { normalize } from 'path';
const { holdingCols, holdingsInitialState, holdingsSorting, gttCols, gttInitialState }: any = TableConfig;

const Trade = () => {
  const { dispatch, state }: any = useContext(Context);
  const { updateHolding, updateSectorData, updateSids, updateTotal }: any = useHolding(dispatch);

  const [selectedQuote, setSelectedQuote]: any = useState({});
  const [loading, setLoading]: any = useState(false);
  const [leftPanel, setLeftPanel]: any = useState(true);
  const [rightPanel, setRightPanel]: any = useState(true);
  const [tSymVal, setTSymVal]: any = useState('');
  const [sids, setSids]: any = useState([]);
  const [orderType, setOrderType] = useState(transactionTypeParams.buy);
  const [form, setForm]: any = useState({
    variety: "amo",
    exchange: "NSE",
    tradingsymbol: "AWL",
    transaction_type: orderType,
    order_type: "LIMIT",
    quantity: 1,
    price: 0,
    product: "MIS",
    validity: "DAY",
    disclosed_quantity: 0,
    trigger_price: '',
    squareoff: 0,
    stoploss: 0,
    trailing_stoploss: 0,
    loss_margin: '',
    profit_margin: ''
  })
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity]: any = useState('success');
  const [snackMsg, setSnackMsg]: any = useState('');
  const [myOrders, setMyOrders] = useState([]);
  const [gttTriggers, setGttTriggers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [stockSectorMapping, setStockSectorMapping] = useState({});
  let profilePnl: any = {
    total: '_',
    arrowIcon: '#2e7d32',
    color: ''
  };

  const { holdings = [], holdingSectorData = [], total = {} } = state.holding;
  const pnlPercentage = (total.cur_val / total.invested_amt - 1) * 100;
  profilePnl.total = pnlPercentage?.toFixed(2);
  profilePnl.color = pnlPercentage < 0 ? '#ff5722' : '#2e7d32';
  profilePnl.arrowIcon = pnlPercentage < 0 ? <ArrowDropDownIcon sx={{ verticalAlign: 'top', fill: profilePnl.color }} /> : <ArrowDropUpIcon sx={{ verticalAlign: 'top', fill: profilePnl.color }} />;

  const securityOptions: any = [];
  Object.keys(state.global.instrumentLabels).map((key: any, idx: any) => {
    const val = state.global.instrumentLabels[key];
    if (val.instrument_type === 'EQ' && (val.segment === 'NSE' || val.segment === 'BSE')) {
      securityOptions.push(key);
    }
    return key;
  })

  /**
   * links gtt to orders
   */
  const linkGttToHoldings = (holdingSectorData: any, gttTriggers: any) => {
    const gttTriggersByTradingSymbol = _.keyBy(gttTriggers, (t: any) => { return t.condition.tradingsymbol });
    const nHoldingSectorData: any = holdingSectorData.map((o: any) => {
      const gtt = gttTriggersByTradingSymbol[o.tradingsymbol];
      if (gtt && gtt.status.toLowerCase() === 'active') {
        o.gtt = gtt
      }
      return o;
    })
    updateSectorData(nHoldingSectorData);
  }

  /**
   * 
   * @param callback getLtpUpdateTriggers
   */
  const getLtpUpdateTriggers = (triggers: any) => {
    // forms query from triggers
    let query = '';
    triggers.map((d: any) => {
      query = !query ? 'i=' : (query + '&i=');
      query += `${d.condition.exchange}:${d.condition.tradingsymbol}`

      return d;
    })
    const getQuoteReq: any = getQuoteLtp(query);
    getQuoteReq.promise
      .then((res: any) => {
        if (res.status === 'success') {
          dispatch({ type: 'SET_QUOTE_LTP', payload: res.data })
        }
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
        setOpenSnack(true);
      })
  }
  /**
   * set stock & sector mapping for allStocks
   */
  useEffect(() => {
    if (state.global.allStocks) {
      setStockSectorMapping(state.global.allStocks);
    }
  }, [state.global.allStocks])

  useEffect(() => {
    if (state.global.quoteLtp) {
      const nGttTriggers: any = gttTriggers.map((d: any, index: any) => {
        d.index = index;
        const key = `${d.condition.exchange}:${d.condition.tradingsymbol}`;
        d.ltp = state.global?.quoteLtp[key];
        d.transaction_type = d.orders[0].transaction_type
        return d;
      })

      setGttTriggers(nGttTriggers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.global.quoteLtp])

  /**
   * gets all orders placed
   * @param callback 
   */
  const getGttTriggersReq = (callback: any = '') => {
    // get gtt triggers
    const triggers: any = getGttTriggers();
    triggers.promise
      .then((response: any) => {
        if (response.status === "success") {
          // if (holdingSectorData.length > 0 && response.data.length > 0) {
          //   linkGttToHoldings(holdingSectorData, response.data);
          // }
          const hData = processGttTriggerData(response.data);
          const sortedOrders = hData.sort((a: any, b: any) => {
            const at = new Date(a.updated_at);
            const bt = new Date(b.updated_at);
            return bt.getTime() > at.getTime() ? 1 : -1;
          });
          setGttTriggers(sortedOrders);
          if (holdingSectorData.length > 0 && sortedOrders.length > 0) {
            linkGttToHoldings(holdingSectorData, sortedOrders);
          }
          if (sortedOrders.length > 0) {
            getLtpUpdateTriggers(sortedOrders);
          }
          callback && callback(sortedOrders);
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
  }
  /**
   * gets all orders placed
   * @param callback 
   */
  const getOrdersPlaced = async (callback: any = '') => {
    // get current orders
    setLoading(true);
    const orders: any = await getOrders();
    orders.promise
      .then((response: any) => {
        if (response.status === "success") {
          const hData = processOrdersData(response.data);
          const sortedOrders = hData.sort((a: any, b: any) => {
            const at = new Date(b.order_timestamp);
            const bt = new Date(b.order_timestamp);
            return bt.getTime() > at.getTime() ? 1 : -1;
          });
          setMyOrders(sortedOrders);
          callback && callback(sortedOrders);
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  /**
   * places order at upper/lower circuits
   */
  const placeCircutOrder = (isLowerCircuit: any = false, order: any = {}) => {
    order = {
      ...order,
      transaction_type: 'SELL',
      trigger_price: 0,
      price: parseInt(order.upper_circuit_limit),
      order_type: 'LIMIT'
    };

    if (isLowerCircuit) {
      console.log('placing lower circuit order', JSON.stringify(order))
    } else {
      console.log('placing upper circuit order', JSON.stringify(order))
    }
    createOrder(order);
  }

  useEffect(() => {
    setOrderType(form.transaction_type)
  }, [form])


  useEffect(() => {
    getOrdersPlaced();
    getGttTriggersReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (holdings.length > 0 && !state.global.isLoading && Object.keys(stockSectorMapping).length > 0) {
      const holdingSectorData = generateHodlingSector(holdings, stockSectorMapping);
      // setHoldingSectorData(holdingSectorData);
      updateSectorData(holdingSectorData);
      if (holdingSectorData.length > 0 && gttTriggers.length > 0) {
        linkGttToHoldings(holdingSectorData, gttTriggers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdings, stockSectorMapping, gttTriggers, state.global.isLoading])

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  // gets holdings
  useEffect(() => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const holdings: any = getLiveHoldings();
      holdings.promise
        .then((response: any) => {
          if (response.status !== 'e') {
            const holdingStocks = processHoldingData(response.data);
            updateHolding(holdingStocks);
            updateTotal(holdingStocks);
          } else {
            throw new Error('Error in fetching holdings.');
          }
        })
        .catch((error: any) => {
          setSnackMsg(error.message);
          setSnackSeverity('error');
          setOpenSnack(true);
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false });
        })
    } catch (error: any) {
      setSnackMsg(error.message);
      setSnackSeverity('error');
      setOpenSnack(true);
      dispatch({ type: 'SET_LOADING', payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (holdings.length > 0 && Object.keys(stockSectorMapping).length > 0) {
      const newSids = getHoldingSids(stockSectorMapping, holdings);
      setSids(newSids);
    }
  }, [holdings, stockSectorMapping])

  const showSids = (e: any) => {
    e.stopPropagation();
    updateSids(sids);
    console.log(JSON.stringify(sids));
  }


  /**
   * links uppler and lower circuit data to holdings
   */
  const linkUpplerLowerCktToHolding = (circuitLimits: any) => {
    const newHSectorData = holdingSectorData.map((item: any, index: any) => {
      const lower_circuit_limit = circuitLimits[index][0];
      const upper_circuit_limit = circuitLimits[index][1];
      return {
        ...item, lower_circuit_limit: lower_circuit_limit, upper_circuit_limit: upper_circuit_limit
      }
    })
    return newHSectorData;
  }

  const setUpperLowerCircuit = async () => {
    //links upper_circuit & lower_circuit to holdings
    if (holdingSectorData && holdingSectorData.length > 0) {
      const instrumentTokens = getInstrumentTokensFromHolding(holdings)
      const quoteReq: any = getLimitsFromWS(JSON.stringify(instrumentTokens));

      return await quoteReq.promise
        .then((res: any) => {
          const newHSectorData = linkUpplerLowerCktToHolding(res.data.data)
          updateSectorData(newHSectorData);
          return newHSectorData;
        })
    }
    return false;
  }


  const updateProfileHoldings = async (updateHoldings: any = true) => {
    try {
      updateSids(sids);
      const newHoldingSectorData = await setUpperLowerCircuit();
      if (updateHoldings) {
        const holdingReq: any = setHoldings({
          holding: {
            status: "success",
            data: newHoldingSectorData,
            sids: sids
          }
        });
        holdingReq.promise
          .then((response: any) => {
            if (response.status !== 'e') {
              setSnackMsg('Holdings updated');
              setSnackSeverity('success');
              setOpenSnack(true);
            }
          })
      }
    } catch (error: any) {
      setSnackMsg(error.message);
      setSnackSeverity('error');
      setOpenSnack(true);
    }
  }


  const varietyOpts = [
    <Button size="small" key="regular" variant={form.variety === varietyParams.regular ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, variety: varietyParams.regular })}>Regular</Button>,
    <Button size="small" key="amo"
      variant={form.variety === varietyParams.amo ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, variety: varietyParams.amo })}
      title="amo" >After Market</Button>,
  ];

  const durationButtons = [
    <Button size="small" key="mis" title="MIS"
      variant={form.product === productParams.mis ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, product: productParams.mis })}>DayTrade</Button>,
    <Button size="small" key="cnc" title="CNC"
      variant={form.product === productParams.cnc ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, product: productParams.cnc })}>Longterm</Button>,
  ];
  const orderTypeOpts = [
    <Button size="small" key="limit"
      variant={form.order_type === orderTypeParams.limit ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, order_type: orderTypeParams.limit })}>Limit</Button>,
    <Button size="small" key="market"
      variant={form.order_type === orderTypeParams.market ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, order_type: orderTypeParams.market })}>Market</Button>,
    <Button size="small" key="sl"
      variant={form.order_type === orderTypeParams.sl ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, order_type: orderTypeParams.sl })}>SL</Button>,
    <Button size="small" key="slm"
      variant={form.order_type === orderTypeParams.slm ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, order_type: orderTypeParams.slm })}>SL-M</Button>,
  ];
  const bsOpts = [
    <Button size="small" key="buy"
      variant={orderType === transactionTypeParams.buy ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, transaction_type: transactionTypeParams.buy })}
    >Buy</Button>,
    <Button size="small" key="sell" color="secondary"
      variant={orderType === transactionTypeParams.sell ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, transaction_type: transactionTypeParams.sell })}
    >Sell</Button>,
  ];
  const exchangeOpts = [
    <Button size="small" key="buy"
      variant={form.exchange === exchangeParams.bse ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, exchange: exchangeParams.bse })}
    >BSE</Button>,
    <Button size="small" key="sell"
      variant={form.exchange === exchangeParams.nse ? 'contained' : 'outlined'}
      onClick={() => setForm({ ...form, exchange: exchangeParams.nse })}
    >NSE</Button>,
  ];

  const creatingSupportingOrders = (placed_order: any) => {
    const newTransType = orderType === transactionTypeParams.buy ? transactionTypeParams.sell : transactionTypeParams.buy;
    let errorMsg: any = '';
    let purchase_price = placed_order.average_price;
    if (placed_order.status === orderStatus.amoReqReceived && placed_order.order_type === orderTypeParams.market) {
      purchase_price = parseFloat(selectedQuote.last_price);
    }
    if (placed_order.status === orderStatus.amoReqReceived && placed_order.order_type === orderTypeParams.limit) {
      purchase_price = parseFloat(form.price);
    }


    if (orderType === transactionTypeParams.sell) {
      // for SELL orders
      const trigger_price_profit = purchase_price - parseFloat(form.profit_margin);
      const trigger_price_loss = purchase_price + parseFloat(form.loss_margin);

      if (form.profit_margin > 0) {
        //create 2 buy orders
        // profit order
        if (trigger_price_profit < purchase_price) {
          const po = {
            ...form,
            price: trigger_price_profit,
            transaction_type: newTransType,
            order_type: orderTypeParams.limit
          }
          createOrder(po);
        } else if (form.profit_margin > 0) {
          errorMsg += " / BUY Order: Profit margin should be lesser than Price"
        }

        //loss order
        if (trigger_price_loss > purchase_price) {
          const po = {
            ...form,
            price: trigger_price_loss,
            trigger_price: trigger_price_loss,
            transaction_type: newTransType,
            order_type: orderTypeParams.slm,
          }
          createOrder(po);
        } else if (form.loss_margin > 0) {
          errorMsg += " / BUY Order: Loss margin should be greater than Price"
        }
      }
    }

    if (orderType === transactionTypeParams.buy) {
      // for BUY orders
      //create 2 sell orders
      const trigger_price_profit = purchase_price + parseFloat(form.profit_margin);
      const trigger_price_loss = purchase_price - parseFloat(form.loss_margin);

      // profit order
      if (trigger_price_profit > purchase_price) {
        const po = {
          ...form,
          price: trigger_price_profit,
          transaction_type: newTransType,
          order_type: "LIMIT"
        }
        createOrder(po);
      } else if (trigger_price_profit < purchase_price) {
        errorMsg += " / BUY Order: Profit margin should be greater than Purchase Price"
      }

      //loss order
      if (trigger_price_loss < purchase_price) {
        const po = {
          ...form,
          price: trigger_price_loss,
          trigger_price: trigger_price_loss,
          transaction_type: newTransType,
          order_type: orderTypeParams.slm,
        }
        createOrder(po);
      } else if (trigger_price_loss < purchase_price) {
        errorMsg += " / BUY Order: Loss margin should be lesser than Purchase Price"
      }
    }
    if (errorMsg) {
      setSnackMsg(errorMsg);
      setOpenSnack(true);
    }
  }

  const createOrder = (order: any, isNewOrder: boolean = false) => {
    console.log('order', order, normalizeOrder(order))
    // return false;
    const po: any = placeOrder(normalizeOrder(order));
    po.promise
      .then((res: any) => {
        setSnackMsg(res.message ? res.message : res.status);
        setSnackSeverity(res.status);
        if (res.status === 'error') {
          setSnackSeverity('error');
        }
        setOpenSnack(true);
        if (res.status === "success") {
          const order_id = res.data.order_id;
          getOrdersPlaced(
            (orders: any) => {
              const orderObj = _.keyBy(orders, 'order_id');
              const createdOrder = orderObj[order_id];
              if (isNewOrder) {
                creatingSupportingOrders(createdOrder);
              }
            }
          );
        }
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
        setOpenSnack(true);
      })
  }

  const deleteGttOrderAction = (d: any) => {
    const delReq: any = deleteGttOrder(d.id);
    delReq.promise
      .then((res: any) => {
        setSnackMsg(`Gtt order ${d.id} sucessfully deleted.`);
        setSnackSeverity(res.status);
        if (res.status === 'error') {
          setSnackSeverity('error');
        }
        getGttTriggersReq();
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
      })
      .finally(() => {
        setOpenSnack(true);
        window.setTimeout(() => { setOpenSnack(false) }, 3000)
      })
  }

  const securityChangeHandler = (e: any, newValue: any) => {
    const sym = `${form.exchange}:${newValue}`
    const quoteReq = getQuote(sym);
    quoteReq.promise
      .then((res: any) => {
        if (res.status === 'success') {
          setSelectedQuote(res.data[sym]);
          setForm({ ...form, price: res.data[sym].last_price, tradingsymbol: newValue })
        }
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
        setOpenSnack(true);
      })
  }

  const toggleLeftPanel = () => {
    setLeftPanel(!leftPanel)
  }

  const togglePanel = () => {
    setRightPanel(!rightPanel)
  }

  const getHoldingColumns = React.useMemo(() => {
    return holdingCols({ getGttTriggersReq: getGttTriggersReq, placeCircutOrder: placeCircutOrder });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{ getGttTriggersReq: getGttTriggersReq }])

  const getGttColumns = React.useMemo(() => {
    return gttCols(deleteGttOrderAction);
  }, [deleteGttOrderAction])

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };


  return (
    <>
      <TemplateDefault
        layout={{
          mainView: 12
        }}
        header={
          (toggleDrawer: any) => {
            return (
              <AppHeader
                handleDrawerOpen={() => { return }}
                challengesFlag={false}
                getChallenges={() => { return }}
                open={false}
                lastUpdated={''}
                activeIndex={3}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        }
      >

        <div className={"trade-group"}>
          <Box className="blade-table" sx={{ display: leftPanel ? 'block' : 'none' }}>

            <Paper elevation={0} sx={{ padding: 1, margin: 1 }} >

              <>
                <Stack direction="row" spacing={1} justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" component="h6">Holdings ({holdingSectorData.length})</Typography>
                  <span>
                    <Button onClick={showSids} >Show sids</Button>
                    <Button onClick={updateProfileHoldings} >Update profile</Button>
                  </span>
                  <Typography variant="h6" component="h6">Invested: {currencyFormat(total.invested_amt)}  Current: {currencyFormat(total.cur_val)}
                    <Chip size="small" sx={{ color: profilePnl.color, marginLeft: '5px', fontWeight: 'bold' }} icon={profilePnl.arrowIcon}
                      label={profilePnl.total + '%'} />
                  </Typography>
                </Stack>
                <div className="view-table">
                  {holdingSectorData && <DataGrid
                    rows={holdingSectorData}
                    columns={getHoldingColumns}
                    disableRowSelectionOnClick
                    hideFooter
                    loading={loading}
                    slots={{
                      loadingOverlay: LinearProgress,
                      toolbar: () => (
                        <>
                          <GridToolbarContainer>
                            <GridToolbarColumnsButton />
                            <GridToolbarFilterButton />
                            <GridToolbarDensitySelector />
                            <GridToolbarExport />
                            <div className='actions'>
                              <IconButton aria-label="EnableGtt" title="Toggle Circut"
                                onClick={() => updateProfileHoldings(false)}
                              >
                                <DynamicFormIcon />
                              </IconButton>
                            </div>
                          </GridToolbarContainer>

                        </>
                      ),
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: holdingsInitialState
                      },
                      sorting: holdingsSorting
                    }}
                  />
                  }
                </div>
              </>

            </Paper>
          </Box>
          <Box className="right-panel blade-buy-sell" sx={{ display: rightPanel ? 'block' : 'none' }} >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Orders"
            >
              <Tab label="GTT" />
              <Tab label="Positions" />
              <Tab label="Place order" />
            </Tabs>
            {/* GTT */}
            <CustomTabPanel value={tabValue} index={0}>
              <div className="view-table">
                <DataGrid
                  rows={gttTriggers}
                  columns={getGttColumns}
                  disableRowSelectionOnClick
                  hideFooter
                  // loading={loading}
                  slots={{
                    loadingOverlay: LinearProgress,
                    toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: gttInitialState
                    },
                  }}
                />
              </div>
            </CustomTabPanel>
            {/* Positions */}
            <CustomTabPanel value={tabValue} index={1}>
              <Container className="view-table">
                {myOrders.length > 0 && <ResponsiveTable data={myOrders} columns={myOrdersCols} rows={myOrdersRows} className={'stock-table'} />}
              </Container>

            </CustomTabPanel>
            {/* Place order */}
            <CustomTabPanel value={tabValue} index={2}>
              <Paper elevation={0} variant="outlined" sx={{ padding: 1, margin: 1 }} >
                <section className="sec-bs form-sm">
                  <header>
                    <Stack spacing={2} sx={{ my: 2 }} direction="row" margin="normal">
                      <Stack spacing={2} direction="column" alignItems="center">
                        {securityOptions.length > 0 &&
                          <Autocomplete
                            // value={form.tradingsymbol}
                            onChange={securityChangeHandler}

                            inputValue={tSymVal}
                            onInputChange={(event, newInputValue) => {
                              setTSymVal(newInputValue)
                            }}
                            options={securityOptions}

                            size="small"
                            disablePortal
                            disableClearable
                            autoHighlight
                            id="security-ac"
                            sx={{ width: 180, height: 30 }}
                            renderInput={(params) => <TextField {...params} label="Security" />}
                          />}
                      </Stack>
                      <Stack spacing={2} direction="column" alignItems="center">
                        <TextField label="Order Qty" variant="outlined"
                          sx={{ width: 96 }}
                          size="small"
                          value={form.quantity}
                          onChange={(e: any) => setForm({ ...form, quantity: e.target.value })}
                        />
                      </Stack>
                      <Stack spacing={2} direction="column" alignItems="center">
                        <ButtonGroup disableElevation aria-label="exchange opts">
                          {bsOpts}
                        </ButtonGroup>
                      </Stack>
                      <Stack spacing={2} direction="column" alignItems="center">
                        {/* <FormLabel component="legend" >Exchange</FormLabel> */}
                        <ButtonGroup disableElevation aria-label="order type bg">
                          {exchangeOpts}
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                  </header>
                  <div className="trade-form">
                    <Stack spacing={2} sx={{ my: 2 }} direction="row" margin="normal">
                      <Stack spacing={2} direction="column" alignItems="center">
                        {/* <FormLabel component="legend" >Buy at price</FormLabel> */}
                        <ButtonGroup disableElevation aria-label="order type bg">
                          {orderTypeOpts}
                        </ButtonGroup>
                      </Stack>
                      <Stack spacing={2} direction="column" alignItems="center">
                        {/* <FormLabel component="legend" >Order Type</FormLabel> */}
                        <ButtonGroup disableElevation aria-label="order type bg">
                          {varietyOpts}
                        </ButtonGroup>
                      </Stack>
                      <Stack spacing={2} direction="column" alignItems="center">
                        {/* <FormLabel component="legend" >Duration</FormLabel> */}
                        <ButtonGroup disableElevation aria-label="order type bg">
                          {durationButtons}
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                    <Stack spacing={2} sx={{ my: 2 }} direction="row" divider={<Divider orientation="vertical" flexItem />}>

                      <Stack spacing={2} direction="column">
                        <TextNumber label="Trigger Price" variant="outlined" size="small"
                          value={form.trigger_price}
                          onChange={(e: any) => setForm({ ...form, trigger_price: e.target.value })}
                          disabled={form.order_type === orderTypeParams.market || form.order_type === orderTypeParams.limit}
                        />
                      </Stack>
                      <Stack spacing={2} direction="column">
                        {
                          form.product === productParams.mis &&
                          <Stack spacing={2} direction="row" alignItems="center">
                            <TextNumber label="Profit margin" variant="outlined" size="small"
                              value={form.profit_margin}
                              onChange={(e: any) => setForm({ ...form, profit_margin: e.target.value })}
                            />
                            <TextField
                              size="small"
                              variant="outlined"
                              label="Profit %"
                              id="outlined-start-adornment"
                              sx={{ m: 1, width: '86px' }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              value={getPercentage(form.profit_margin, form.price)}
                            />

                            <Typography variant="body1" component="span">Net price: {form.quantity * (form.price + 1 * form.profit_margin)}</Typography>
                          </Stack>
                        }

                        <Stack spacing={2} direction="row" alignItems="center">
                          <TextNumber label={`${orderType === transactionTypeParams.buy ? 'Buy' : 'Sell'} Price`}
                            variant="outlined"
                            size="small"
                            value={form.price}
                            disabled={form.order_type === orderTypeParams.market || form.order_type === orderTypeParams.slm}
                            onChange={(e: any) => setForm({ ...form, price: e.target.value })}
                          />

                          <TextField
                            id="quote-value"
                            // label="LTP"
                            sx={{ m: 1, width: '86px' }}
                            value={selectedQuote?.last_price}
                            defaultValue={selectedQuote?.last_price}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <Typography variant="body1" component="span">Net price: {form.quantity * form.price}</Typography>
                        </Stack>

                        {
                          form.product === productParams.mis &&
                          <Stack spacing={2} direction="row" alignItems="center">
                            <TextNumber label="Loss margin" variant="outlined" size="small"
                              sx={{ color: 'red', fontSize: 8, height: 38 }}
                              value={form.loss_margin}
                              onChange={(e: any) => setForm({ ...form, loss_margin: e.target.value })}
                            />
                            <TextField
                              size="small"
                              label="Loss %"
                              id="outlined-start-adornment"
                              sx={{ m: 1, width: '86px' }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              value={getPercentage(-1 * form.loss_margin, form.price)}
                            />
                            <Typography variant="body1" component="span">Net price: {form.quantity * (form.price - form.loss_margin)}</Typography>
                          </Stack>
                        }
                      </Stack>
                    </Stack>
                    <footer>
                      <Stack spacing={2} sx={{ my: 2 }} direction="row">
                        <Button size="small" variant="contained" disableElevation
                          onClick={() => createOrder(form, true)}
                          color={orderType === 'SELL' ? 'secondary' : 'primary'}
                        >{form.transaction_type}</Button>
                      </Stack>
                    </footer>
                  </div>
                </section>
              </Paper>
              <Paper elevation={0} variant="outlined" sx={{ padding: 1, margin: 1 }} >
                <OrderGtt getGttTriggersReq={getGttTriggersReq} />
              </Paper>
            </CustomTabPanel>
          </Box>

          <button className='show-hide-handle lt' onClick={toggleLeftPanel}><KeyboardArrowLeftRoundedIcon fontSize="small"
            sx={{ transform: leftPanel ? 'rotate(0)' : 'rotate(180deg)' }}
          /></button>
          <button className='show-hide-handle' onClick={togglePanel}><KeyboardArrowLeftRoundedIcon fontSize="small"
            sx={{ transform: rightPanel ? 'rotate(180deg)' : 'rotate(0)' }}
          /></button>
        </div>


        <Snackbar open={openSnack} autoHideDuration={6000}>
          <Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: '100%' }}>
            {snackMsg}
          </Alert>
        </Snackbar>
      </TemplateDefault >
    </>
  )
}

export default Trade;