// Initial state
// const initialState = {
//   count: 0,
//   profiles: [], // Placeholder for user profiles
// };

import { MF_STOCK_KEY } from "../../container/screener/screener-config";


// Reducer function
export const screenerReducer = (state: any, action: any) => {

  const getNewStockMapping = (oldMappings: any) => {
    const mappings: any = {};
    oldMappings.forEach((item: any) => {
      mappings[item.sid] = item;
    });
    return { ...state.sidStockInfoMapping, ...mappings }
  }

  const getNewPerformanceData = (screenerQuotesData: any) => {
    const stockAddedData = screenerQuotesData.map((d: any) => {
      return { ...d, stockInfo: state.sidStockInfoMapping[d.sid]?.stock?.info }
    })
    return stockAddedData;
  }

  switch (action.type) {
    case 'SET_PERFORMANCE_DATA':
      return { ...state, performance: getNewPerformanceData(action.payload) };
    case 'SET_SID_STOCK_INFO_MAPPING':
      return {
        ...state,
        sidStockInfoMapping: getNewStockMapping(action.payload)
      }
    case 'SET_HOLDINGS_SIDS':
      return {
        ...state,
        sids: action.payload
      }
    case 'SET_MUTUAL_FUND':
      const stockAllocation: any = [];
      const mfList = { ...state.mutualfund, ...action.payload };
      Object.values(mfList).forEach((allocation: any) => {
        allocation.forEach((instrument: any) => {
          const key = instrument.ticker ? instrument.ticker : instrument.type;
          const value = {
            ticker: key,
            sid: key,
            latest: stockAllocation[key] ? stockAllocation[key].latest + 1 : 1,
            title: instrument.title,
            allocation: instrument.latest
          };
          if (instrument.latest > 0) {
            // if allocation is > 0
            stockAllocation[key] = value;
          }
        })
      })

      mfList[MF_STOCK_KEY] = Object.values(stockAllocation);
      mfList[MF_STOCK_KEY].sort((a: any, b: any) => (b.latest - a.latest))


      return {
        ...state,
        mutualfund: mfList
      };
    
    case 'SET_SCREENER_DATA':
      return {
        ...state,
        screenerData: action.payload
      }
    default:
      return state;
  }
}

export default screenerReducer;