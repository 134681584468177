import { useEffect } from "react";
import { getSectorByName } from "../common/utils";
import T100Bar from "../components/t100-bar";
import T100Line from "../components/t100-line";
import T100Performance from "../components/t100-performance";
import theme from "../common/theme";



const T100Chart = ({ data, durationSeries, chartType, getPerformanceData, performanceChartData }: any) => {

  const newData = data;
  let chartData: any = durationSeries.map((series: any) => {
    return {
      id: series,
      data: [],
      sids: []
    }
  });

  newData.sort((a: any, b: any) => { return b.stock.advancedRatios[durationSeries[0]] - a.stock.advancedRatios[durationSeries[0]] });

  // filter out stocks according to selected active series (time duration)
  const filteredData = newData.filter((d: any) => {
    if (!d.stock.advancedRatios && !d.stock[durationSeries[0]]) {
      return false;
    }
    return true;
  })

  filteredData.map((d: any) => {
    const sector = d.stock.info.sector;
    const sd = getSectorByName(sector);

    const cd = {
      sid: d.sid,
      name: d.stock.info.name,
      ticker: d.stock.info.ticker,
      x: d.sid,
      y: d.stock.advancedRatios[durationSeries[0]],
      sector: sd.name,
      color: sd.color
    }
    durationSeries.forEach((series: any, idx: any) => {
      chartData[idx].data.push({
        ...d,
        ...cd,
        y: d.stock.advancedRatios[series]
      })
      chartData[idx].sids.push(d.sid)
    })
    return d;
  })

  useEffect(() => {
    if (chartType === 'performance' && chartData[0]?.sids?.length > 0) {
      getPerformanceData(chartData[0].sids);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartType])

  const perfData = !performanceChartData
    ? []
    : performanceChartData.map((d: any) => {
      // console.log(d)
      const instrumentSeries: any = {};
      instrumentSeries.id = d.sid;
      const hl: any = {
        minPct: parseFloat(((d.l - d.price) / (d.h - d.l) * 100)?.toFixed(2)) || 0,
        maxPct: parseFloat(((d.h - d.price) / (d.h - d.l) * 100)?.toFixed(2)) || 0,
        pricePct: parseFloat(((d.price - d.price) / (d.h - d.l) * 100)?.toFixed(2)) || 0
      };
      instrumentSeries.color = d.dyChange > 0 ? theme.colors.up : theme.colors.down;
      instrumentSeries.maxMinChange = (d.h - d.l) / d.h * 100;
      instrumentSeries.data = [
        {
          x: d.sid,
          y: hl.minPct,
          labelId: 'Low',
          label: d.l,
          color: instrumentSeries.color,
          stockInfo: d.stockInfo,
          maxMinChange: instrumentSeries.maxMinChange
        },
        {
          x: d.sid,
          y: hl.pricePct,
          labelId: 'Price',
          label: d.price,
          color: instrumentSeries.color,
          stockInfo: d.stockInfo,
          dyChange: d.dyChange,
          maxMinChange: instrumentSeries.maxMinChange
        },
        {
          x: d.sid,
          y: hl.maxPct,
          labelId: 'High',
          label: d.h,
          color: instrumentSeries.color,
          stockInfo: d.stockInfo,
          dyChange: d.dyChange,
          maxMinChange: instrumentSeries.maxMinChange
        },
      ]
      return instrumentSeries;
    })

  perfData.sort((a: any, b: any) => b.maxMinChange - a.maxMinChange)

  return (
    chartType === 'bar'
      ? <T100Bar data={chartData} />
      : (chartType === 'performance'
        ? <T100Performance data={perfData} />
        : <T100Line data={chartData} />)
  )
}

export default T100Chart;
