/**
 * profile pie component
 */
import { ResponsiveTreeMap } from '@nivo/treemap';

const PLOT_ID = 'weight';

const ProfilePie = ({ data }: any) => {
  const colors = data.children.map((d: any) => d.color);

  return (
    <ResponsiveTreeMap
      data={data}
      identity="name"
      value={PLOT_ID}
      valueFormat=".02s"
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      labelSkipSize={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.2]] }}
      colors={['#ececef', ...colors]}
      parentLabelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      borderColor={{ from: 'color', modifiers: [['darker', 0.1]] }}
      // tooltip={({ node }:any) => (
      //       <strong style={{ color: node.color }}>
      //           {node.pathComponents.join(' / ')}: {node.formattedValue}
      //       </strong>
      //   )}
      // tooltip={({ node }: any) => (
      //   <strong >
      //     {node.pathComponents.join(' / ')}: {node.formattedValue}
      //   </strong>
      // )}
    />
  )
}

export default ProfilePie;
