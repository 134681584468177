import { Alert, Autocomplete, Box, Button, ButtonGroup, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react'
import { getDiffPercentage } from '../../common/utils';
import { Context } from '../../context/store'
import { getQuote } from '../../services/api-services';
import { placeGttOrder } from '../../services/trade-services';
import { normalizeGttOrder } from './normalize';
import { transactionTypeParams, exchangeParams } from './params';
import { initialGttOrderRequest } from "./constants";


// const initialGttOrderRequest = {
//   condition: { "exchange": "NSE", "tradingsymbol": "BEL", "trigger_values": [1, 100], "last_price": 100 },

//   orders: [{ "exchange": "NSE", "tradingsymbol": "BEL", "transaction_type": "SELL", "quantity": 10, "price": 1, "order_type": "LIMIT", "product": "CNC" }, { "exchange": "NSE", "tradingsymbol": "BEL", "transaction_type": "SELL", "quantity": 10, "price": 100, "order_type": "LIMIT", "product": "CNC" }],

//   type: 'two-leg'
// }

const OrderGtt = ({ getGttTriggersReq }: any) => {
  const {  state }: any = useContext(Context);

  const [tradingsymbol, setTradingsymbol]: any = useState('AWL');
  const [exchange, setExchange]: any = useState(exchangeParams.nse);
  const [quantity, setQuantity]: any = useState(1);
  const [triggerValues, setTriggerValues]: any = useState([0, 100]);
  const [orderType, setOrderType]: any = useState(transactionTypeParams.sell);
  const [gttRequestData, setGttRequestData] = useState(initialGttOrderRequest);

  const [selectedQuote, setSelectedQuote]: any = useState({});

  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity]: any = useState('success');
  const [snackMsg, setSnackMsg]: any = useState('');

  const [tSymVal, setTSymVal]: any = useState('');


  const securityOptions: any = [];
  Object.keys(state.global.instrumentLabels).map((key: any, idx: any) => {
    const val = state.global.instrumentLabels[key];
    if (val.instrument_type === 'EQ' && (val.segment === 'NSE' || val.segment === 'BSE')) {
      securityOptions.push(key);
    }
    return key;
  })


  useEffect(() => {
    const newOrders = gttRequestData.orders;
    newOrders[0] = { ...gttRequestData.orders[0], tradingsymbol: tradingsymbol, exchange: exchange, quantity: quantity, price: triggerValues[0] };
    newOrders[1] = { ...gttRequestData.orders[1], tradingsymbol: tradingsymbol, exchange: exchange, quantity: quantity, price: triggerValues[1] };
    setGttRequestData({
      ...gttRequestData,
      condition: { ...gttRequestData.condition, tradingsymbol: tradingsymbol, exchange: exchange, trigger_values: triggerValues, last_price: selectedQuote.last_price },
      orders: newOrders
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingsymbol, exchange, quantity, triggerValues, selectedQuote])


  const bsOpts = [
    <Button size="small" key="buy"
      variant={orderType === transactionTypeParams.buy ? 'contained' : 'outlined'}
      onClick={() => setOrderType(transactionTypeParams.buy)}
    >Buy</Button>,
    <Button size="small" key="sell" color="secondary"
      variant={orderType === transactionTypeParams.sell ? 'contained' : 'outlined'}
      onClick={() => setOrderType(transactionTypeParams.sell)}
    >Sell</Button>,
  ];
  const exchangeOpts = [
    <Button size="small" key="buy"
      variant={exchange === exchangeParams.bse ? 'contained' : 'outlined'}
      onClick={() => setExchange(exchangeParams.bse)}
    >BSE</Button>,
    <Button size="small" key="sell"
      variant={exchange === exchangeParams.nse ? 'contained' : 'outlined'}
      onClick={() => setExchange(exchangeParams.nse)}
    >NSE</Button>,
  ];

  const securityChangeHandler = (e: any, newValue: any) => {
    const sym = `${exchange}:${newValue}`
    const quoteReq = getQuote(sym);
    quoteReq.promise
      .then((res: any) => {
        if (res.status === 'success') {
          setSelectedQuote(res.data[sym]);
          setTradingsymbol(newValue)
        }
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
        setOpenSnack(true);
      })
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  const createOrder = () => {
    const po: any = placeGttOrder(normalizeGttOrder(gttRequestData));
    po.promise
      .then((res: any) => {
        setSnackMsg(res.message ? res.message : `GTT order ${res.data?.trigger_id} created`);
        setSnackSeverity(res.status);
        if (res.status === 'error') {
          setSnackSeverity('error');
        }
        setOpenSnack(true);
        getGttTriggersReq();
      })
      .catch((error: any) => {
        setSnackMsg(error.message);
        setSnackSeverity('error');
        setOpenSnack(true);
      })
  }

  return (
    <>
      <div className="blade-buy-sell">
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
        </Box>
        <section className="sec-bs form-sm">

          <Stack spacing={2} sx={{ my: 2 }} direction="row" margin="normal">
            <Typography variant="h6" component="h6">New order</Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              {securityOptions.length > 0 &&
                <Autocomplete
                  // value={tradingsymbol}
                  onChange={securityChangeHandler}

                  inputValue={tSymVal}
                  onInputChange={(event, newInputValue) => {
                    setTSymVal(newInputValue)
                  }}
                  options={securityOptions}

                  size="small"
                  disablePortal
                  disableClearable
                  autoHighlight
                  id="security-ac-gtt"
                  sx={{ width: 180, height: 30 }}
                  renderInput={(params) => <TextField {...params} label="Security" />}
                />}
              <TextField label="Order Qty" variant="outlined"
                sx={{ width: 96 }}
                size="small"
                value={quantity}
                onChange={(e: any) => setQuantity(e.target.value)}
              />
              <Typography variant="body1" component="span">LTP: {selectedQuote.last_price}</Typography>
            </Stack>
            <Stack spacing={2} direction="column" alignItems="center">
              <ButtonGroup disableElevation aria-label="exchange opts">
                {bsOpts}
              </ButtonGroup>
            </Stack>
            <Stack spacing={2} direction="column" alignItems="center">
              <ButtonGroup disableElevation aria-label="order type bg">
                {exchangeOpts}
              </ButtonGroup>
            </Stack>
          </Stack>

          {tradingsymbol && <>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography variant="body1" component="span">Trigger Price</Typography>

              <TextField label="Trigger Min" variant="outlined"
                sx={{ width: 96 }}
                size="small"
                value={triggerValues[0]}
                onChange={(e: any) => setTriggerValues([e.target.value, triggerValues[1]])}
              />
              <Typography variant="body1" component="span">{getDiffPercentage(triggerValues[0], selectedQuote.last_price)}%</Typography>
              <Typography variant="body1" component="span">|</Typography>
              <TextField label="Trigger Max" variant="outlined"
                sx={{ width: 96 }}
                size="small"
                value={triggerValues[1]}
                onChange={(e: any) => setTriggerValues([triggerValues[0], e.target.value])}
              />
              <Typography variant="body1" component="span">{getDiffPercentage(triggerValues[1], selectedQuote.last_price)}%</Typography>
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <Button size="small" variant="contained" disableElevation
                onClick={() => createOrder()}
                color={orderType === 'SELL' ? 'secondary' : 'primary'}
              >{orderType}</Button>
            </Stack>
          </>}

        </section>

      </div>
      <Snackbar open={openSnack} autoHideDuration={6000}>
        <Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: '100%' }}>
          {snackMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default OrderGtt;
