import { allSectors } from "../config/sectors";

/**
 * returns mapping of ticker, sid, sym...
 */
export const getSectorStockMapping = (response: any) => {
  const results = response.success ? response.data.results : [];
  const mappings: any = {};

  results.map((d: any, i: any) => {
    const co = results[i];
    const sector = co.stock.info.sector ? co.stock.info.sector : 'Unkown';
    const ticker = co.stock.info.ticker;
    const tickerObj: any = {};
    tickerObj[ticker] = co;
    mappings[sector] ? mappings[sector][ticker] = co : mappings[sector] = tickerObj;
    return d;
  })

  return mappings;
}

export const getStockSectorMapping = (response: any) => {
  const results = response.success ? response.data.results : [];
  const mappings: any = {};

  const allSectorsObj: any = {};
  allSectors.map((d: any) => {
    allSectorsObj[d.name] = d;
    return d;
  });

  results.map((d: any, i: any) => {
    const co = results[i];
    // if(!co.stock.advancedRatios) return d;
    const sector = co.stock.info.sector ? co.stock.info.sector : 'Unknown';
    const ticker = co.stock.info.ticker;
    mappings[ticker] = {
      sid: co.sid,
      sector: sector,
      color: allSectorsObj[sector] ? allSectorsObj[sector].color : '#ccc',
      mrktCapf: co.stock.advancedRatios.mrktCapf
    };
    return d;
  })

  return mappings;
}

export const generateInstumentMapping = (csvInstruments: any) => {
  const rows = (csvInstruments + '').split('\n');
  const mappings: any = {};
  rows.map((row: any, pidx: any) => {
    if (pidx > 0) {
      const keys = rows[0].split(',');
      const vals = row.split(',');
      const obj: any = {};
      keys.map((key: any, idx: any) => {
        obj[key] = vals[idx];
        return key;
      });
      mappings[obj.tradingsymbol] = obj;
    }
    return row;
  });
  return mappings;
}