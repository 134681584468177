export const data = {
    "templateName": "EMEA Order",
    "columns": [
      "qtcuid",
      "overall_notes_lupd",
      "has_customer_notes",
      "HP Order No",
      "Purchase Order No",
      "Purchase Order Date",
      "HP Receive Date",
      "SDDLAD Delta",
      "Order Entry Date",
      "MSM",
      "PA#",
      "Account Name",
      "Item No",
      "Product No",
      "Localization",
      "Ordered Qty",
      "Product Description",
      "Status",
      "PCSD",
      "PCDD",
      "Shipment Cut off Date",
      "User Stati",
      "Header Stati",
      "Net Item Price (USD)",
      "PL",
      "Plant MfgSo",
      "HP Supplier MfgSo"
    ],
    "internalColumns": [
      "order_no",
      "overall_notes_lupd",
      "has_customer_notes",
      "hp_order_no",
      "purchase_order_no",
      "purch_order_date",
      "hp_receive_date",
      "sdd_lad_delta",
      "order_entry_date",
      "msm",
      "purch_agree",
      "account",
      "item_sln",
      "product_no",
      "prod_localization",
      "order_qty",
      "product_descr",
      "status",
      "sched_ship_date",
      "sched_delv_date",
      "rev_ship_cutoff_date",
      "user_stati",
      "header_stati",
      "item_net_price",
      "pl",
      "plant_mfgso",
      "supplier_mfgso"
    ],
    "stdColumns": [
      "qtcuid",
      "overall_notes_lupd",
      "has_customer_notes",
      "HP Order No",
      "Purchase Order No",
      "Purchase Order Date",
      "HP Receive Date",
      "SDDLAD Delta",
      "Order Entry Date",
      "MSM",
      "PA#",
      "Account Name",
      "Item No",
      "Product No",
      "Localization",
      "Ordered Qty",
      "Product Description",
      "Status",
      "PCSD",
      "PCDD",
      "Shipment Cut off Date",
      "User Stati",
      "Header Stati",
      "Net Item Price (USD)",
      "PL",
      "Plant MfgSo",
      "HP Supplier MfgSo"
    ],
    "stdInternalColumns": [
      "order_no",
      "overall_notes_lupd",
      "has_customer_notes",
      "hp_order_no",
      "purchase_order_no",
      "purch_order_date",
      "hp_receive_date",
      "sdd_lad_delta",
      "order_entry_date",
      "msm",
      "purch_agree",
      "account",
      "item_sln",
      "product_no",
      "prod_localization",
      "order_qty",
      "product_descr",
      "status",
      "sched_ship_date",
      "sched_delv_date",
      "rev_ship_cutoff_date",
      "user_stati",
      "header_stati",
      "item_net_price",
      "pl",
      "plant_mfgso",
      "supplier_mfgso"
    ],
    "nrOfRows": 942683424,
    "nrOfRealRows": 508,
    "rows": [
      [
        "210302S45XK618",
        "",
        "N",
        "102229394",
        "S7Q2021-03-02 19:35:19",
        "2021-03-02",
        "2021-03-02",
        "0",
        "2021-03-02",
        "CHANNEL",
        "2BL37",
        "",
        "0010,2",
        "J8H61A#B19",
        "",
        "2",
        "HP LJ Pro M501dn Printer:EUR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NPIH NoMP REL",
        "CCOK FSCM MOID REL ",
        "0.00",
        "C5",
        "RU22",
        "RU22"
      ],
      [
        "210603S24HG567",
        "",
        "N",
        "102370766",
        "(PRD)test config-annana",
        "2021-06-03",
        "2021-06-03",
        "0",
        "2021-06-03",
        "Standard",
        "",
        "",
        "0010,2",
        "1FH47AA#AB2",
        "",
        "55",
        "HP EliteDisplay E243 Monitor",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL SCAL",
        "CCOK MOID REL ",
        "0.00",
        "BO",
        "CN52",
        "CN52"
      ],
      [
        "210607S02LS094",
        "",
        "N",
        "102371700",
        "ZT to ZB prog change retest 001",
        "2021-06-07",
        "2021-06-07",
        "0",
        "2021-06-07",
        "DIRECT",
        "AJ014",
        "NIKON_CORPORATION_-_WW",
        "0010,2",
        "7PS84A#B19",
        "",
        "1",
        "HP LaserJet Ent M611dn Printer:EUR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD EOLH MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "C5",
        "CH75",
        "CH75"
      ],
      [
        "210607S02LS094",
        "",
        "N",
        "102371700",
        "ZT to ZB prog change retest 001",
        "2021-06-07",
        "2021-06-07",
        "0",
        "2021-06-07",
        "DIRECT",
        "AJ014",
        "NIKON_CORPORATION_-_WW",
        "0020,2",
        "U07CGSB",
        "",
        "1",
        "BOM 3 YEARS UP REGISTRATION LJ M600 SFM",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL EOLH MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "64",
        "DE01",
        "DE01"
      ],
      [
        "210607S02LS094",
        "",
        "N",
        "102371700",
        "ZT to ZB prog change retest 001",
        "2021-06-07",
        "2021-06-07",
        "0",
        "2021-06-07",
        "DIRECT",
        "AJ014",
        "NIKON_CORPORATION_-_WW",
        "0030,2",
        "7PS84A#B19",
        "",
        "5",
        "HP LaserJet Ent M611dn Printer:EUR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD EOLH MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "C5",
        "CH75",
        "CH75"
      ],
      [
        "210607S02LS094",
        "",
        "N",
        "102371700",
        "ZT to ZB prog change retest 001",
        "2021-06-07",
        "2021-06-07",
        "0",
        "2021-06-07",
        "DIRECT",
        "AJ014",
        "NIKON_CORPORATION_-_WW",
        "0040,2",
        "U07CGSB",
        "",
        "5",
        "BOM 3 YEARS UP REGISTRATION LJ M600 SFM",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL EOLH MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "64",
        "DE01",
        "DE01"
      ],
      [
        "210615S21BS868",
        "",
        "N",
        "102376062",
        "BTO MULTI",
        "2021-06-24",
        "2021-06-24",
        "0",
        "2021-06-24",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0010,2",
        "1X9G7PA#ACJ",
        "",
        "2",
        "400G6PDS/i38100/1h/4G/8k",
        "CANCELED",
        "",
        "",
        "",
        "ACKN BLPC COM-POC EOL1 MtoO POEC REL SETC",
        "CCOK MOID REL ",
        "0.00",
        "5U",
        "IN51",
        "IN51"
      ],
      [
        "210718S00VC151",
        "",
        "N",
        "102382771",
        "BTO Engine Test",
        "2021-07-18",
        "2021-07-18",
        "0",
        "2021-07-18",
        "Standard",
        "",
        "",
        "0020,2",
        "1PV64A#BBU",
        "",
        "150",
        "HP LaserJet Ent MFP M528dn Printer:ID",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "C5",
        "SG91",
        "SG91"
      ],
      [
        "210723S39TD348",
        "",
        "N",
        "102385791",
        "Single_Reg_1",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "",
        "INGRAM_MICRO_INC",
        "0060,2",
        "5RZ45US#ABA",
        "",
        "10",
        "HP Z2G4M XE2124G 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP OSS-2SMO REL",
        "CCOK MOID REL ",
        "0.00",
        "I0",
        "US73",
        "US73"
      ],
      [
        "210723S39TD348",
        "",
        "N",
        "102385791",
        "Single_Reg_1",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "",
        "INGRAM_MICRO_INC",
        "0070,2",
        "5RZ45US#ABA",
        "",
        "10",
        "HP Z2G4M XE2124G 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP OSS-2SMO REL",
        "CCOK MOID REL ",
        "0.00",
        "I0",
        "US73",
        "US73"
      ],
      [
        "210723S38WY428",
        "",
        "N",
        "102385836",
        "BRF+ BOM Test2",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0010,2",
        "C2P01FN#140",
        "",
        "1",
        "HP 951 CMY/950XL Blk Ink Cartridge Pk NA",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL STCP ",
        "0.00",
        "1N",
        "US27",
        "US27"
      ],
      [
        "210723S38WY428",
        "",
        "N",
        "102385836",
        "BRF+ BOM Test2",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0020,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL CPCD NoMP REL ZCCF",
        "CCOK MOID REL STCP ",
        "0.00",
        "MG",
        "CA01",
        "CA01"
      ],
      [
        "210723S38WY428",
        "",
        "N",
        "102385836",
        "BRF+ BOM Test2",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0030,2",
        "UK703E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL CPCD NoMP REL ZCCF",
        "CCOK MOID REL STCP ",
        "0.00",
        "MG",
        "CA01",
        "CA01"
      ],
      [
        "210723S43DJ420",
        "",
        "N",
        "102385856",
        "BRF+ BOM Test3",
        "2021-07-23",
        "2021-07-23",
        "0",
        "2021-07-23",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0010,2",
        "1MV65UA#ABA",
        "",
        "1",
        "T/t530/TP/8GF/4GRW TC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "I1",
        "US61",
        "US61"
      ],
      [
        "210723S43DJ420",
        "",
        "N",
        "102385856",
        "BRF+ BOM Test3",
        "2021-07-23",
        "2021-07-23",
        "-6",
        "2021-07-23",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0020,2",
        "C2P01FN#140",
        "",
        "1",
        "HP 951 CMY/950XL Blk Ink Cartridge Pk NA",
        "CANCELED",
        "2021-07-26",
        "2021-08-02",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "1N",
        "US27",
        "US27"
      ],
      [
        "210723S38BQ174",
        "",
        "N",
        "102387463",
        "BOM3TestPrasanth",
        "2021-05-17",
        "2021-05-17",
        "0",
        "2021-05-17",
        "Standard",
        "",
        "AGILENT_TECHNOLOGIES_INC_-_WW",
        "0020,2",
        "U9ZA7E",
        "",
        "1",
        "HP 4y Nbd+DMR DesignJet Z9-44 1 roll HWS",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK DUPO MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "K2",
        "AU01",
        "AU01"
      ],
      [
        "210723S38VQ494",
        "",
        "N",
        "102387465",
        "BOM3TestPrasanth",
        "2021-05-17",
        "2021-05-17",
        "0",
        "2021-05-17",
        "Standard",
        "",
        "AGILENT_TECHNOLOGIES_INC_-_WW",
        "0020,2",
        "U9ZA7E",
        "",
        "1",
        "HP 4y Nbd+DMR DesignJet Z9-44 1 roll HWS",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK DUPO MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "K2",
        "AU01",
        "AU01"
      ],
      [
        "210726S65CH707",
        "",
        "N",
        "102387579",
        "FWH with HPCQ inhouse CTO UAT",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "Standard",
        "",
        "",
        "0180,2",
        "141X6AV#AB2",
        "",
        "2",
        "CKIT HP CCC PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLPD MDID NoMP PRSE REL",
        "CCOK MDID MPAN NTRS PCRP REL ",
        "0.00",
        "7F",
        "CN43",
        "CN43"
      ],
      [
        "210726S64SR065",
        "",
        "N",
        "102387619",
        "HP2BAPJPO0726V2",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "DIRECT",
        "",
        "",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "2021-10-15",
        "2021-10-15",
        "",
        "CFSL FPDI MDID NoMP REL ZHWN",
        "CCOK MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "AU01",
        "AU01"
      ],
      [
        "210726S45ZM362",
        "",
        "N",
        "102387640",
        "Partner_HP2B DMFI PO 0726 V2",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "Standard",
        "",
        "NESTLE_SA_-_WW",
        "0010,2",
        "QK555AA",
        "",
        "1",
        "HP 1TB 7200rpm SATA 6Gbps Hard Drive",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 GTIC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "9F",
        "PA20",
        "PA20"
      ],
      [
        "210726S41DQ514",
        "",
        "N",
        "102387696",
        "76293314",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "Standard",
        "",
        "MAJRANI_GROUP_SRL_-_IT",
        "0080,2",
        "U7D00E",
        "",
        "1",
        "HP 1yr PriorityManagemt PC 1K+ seats SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL ESCD MDID NoMP REL STOR",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "MG",
        "IT01",
        "IT01"
      ],
      [
        "210727S40PZ898",
        "",
        "N",
        "102387738",
        "test ZVA05 enhancement-CZ993A#AB2-r",
        "2021-07-27",
        "2021-07-27",
        "0",
        "2021-07-27",
        "Standard",
        "AG399",
        "",
        "0010,2",
        "CZ993A#AB2",
        "",
        "1",
        "HP OfficeJet 200 Mobile Printer:CN-zh",
        "CANCELED",
        "",
        "",
        "",
        "ATDD NoMP REL SCAL",
        "CCOK MOID REL ",
        "0.00",
        "DU",
        "CN30",
        "CN30"
      ],
      [
        "210728S62BL072",
        "",
        "N",
        "102388050",
        "BOM3 test plant change 002",
        "2021-07-28",
        "2021-07-28",
        "0",
        "2021-07-28",
        "DIRECT",
        "DM5DH",
        "DEUTSCHE_POST_/_DHL_-_WW",
        "0020,2",
        "U07CCSB",
        "",
        "1",
        "BOM 3 YEARS UP REGISTRATION LJ M400 MFM",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP PRSE REL RROM",
        "CCOK MDID NTRS REL ",
        "0.00",
        "64",
        "DE01",
        "DE01"
      ],
      [
        "210728S43XV595",
        "",
        "N",
        "102388085",
        "TEST_BOM3",
        "2021-07-28",
        "2021-07-28",
        "0",
        "2021-07-28",
        "CHANNEL-COUNTRYFIT",
        "D3M02",
        "",
        "0010,2",
        "7EM46EA#ABD",
        "",
        "1",
        "HP 400G5PD DM i59500T 8GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "DG",
        "CH66",
        "CH66"
      ],
      [
        "210728S43XV595",
        "",
        "N",
        "102388085",
        "TEST_BOM3",
        "2021-07-28",
        "2021-07-28",
        "0",
        "2021-07-28",
        "CHANNEL-COUNTRYFIT",
        "D3M02",
        "",
        "0020,2",
        "U09GFE",
        "",
        "1",
        "HP 3y NBD DT Bundle Austria Germ",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MG",
        "DE01",
        "DE01"
      ],
      [
        "210728S43SF569",
        "",
        "N",
        "102388123",
        "TEST_HDTEXT004.31",
        "2021-07-28",
        "2021-07-28",
        "0",
        "2021-07-28",
        "Standard",
        "",
        "INGRAM_MICRO_INC",
        "0010,2",
        "5FP62UA#ABA",
        "",
        "5",
        "HP Laptop 14-df0014ds",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP OSS-2SMO REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "US65",
        "US65"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0010,2",
        "6CJ09AV",
        "",
        "3",
        "BU IDS DSC T2000 4GB i7-9850H 15 G6",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PCSL PRSE REL RROM SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "3935.55",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0020,2",
        "4SS11AV#ABA",
        "",
        "3",
        "OSLOC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "AN",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0030,2",
        "6CJ59AV",
        "",
        "3",
        "Win 10 Pro 64",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0040,2",
        "6CK07AV",
        "",
        "3",
        "Integrated FHD 1080p IR TM Webcam",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0050,2",
        "6CJ28AV",
        "",
        "3",
        "15.6 FHD AG LED UWVA 220 wFHDC IR slim",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0060,2",
        "6CJ71AV",
        "",
        "3",
        "32GB (2x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0070,2",
        "6CJ86AV",
        "",
        "3",
        "256GB PCIe NVMe TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0080,2",
        "6CJ44AV",
        "",
        "3",
        "No Near Field Communication (No NFC)",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0090,2",
        "6CK10AV",
        "",
        "3",
        "Intel 22260ax2x2LTECOEXMU-MIMO+BT5WW2Ant",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0100,2",
        "6CJ49AV",
        "",
        "3",
        "No WWAN",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0110,2",
        "6CJ80AV",
        "",
        "3",
        "SEC Fingerprint Sensor",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0120,2",
        "6CL89AV",
        "",
        "3",
        "4 Cell 90 WHr Long Life",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0130,2",
        "6CH99AV",
        "",
        "3",
        "150 Watt Smart PFC Slim AC Adapter",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0140,2",
        "6CK13AV#ABA",
        "",
        "3",
        "C5 1.0m tag Power Cord",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0150,2",
        "6CK04AV#ABA",
        "",
        "3",
        "3/3/0 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0160,2",
        "2KY02AV",
        "",
        "3",
        "DIB No Adobe Software",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0170,2",
        "6GS73AV",
        "",
        "3",
        "AMT Enabled",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0180,2",
        "6CJ42AV",
        "",
        "3",
        "MISC HDD Carrier Cage",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0190,2",
        "6CK14AV#ABA",
        "",
        "3",
        "Country Localization",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0200,2",
        "6CK15AV#ABA",
        "",
        "3",
        "Dual Point BL SR Cllb",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0210,2",
        "X9H35AV",
        "",
        "3",
        "eStar Enable IOPT",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0220,2",
        "7DG36AV",
        "",
        "3",
        "LBL Core i7 vPro sz3 G9",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210729S45XN855",
        "",
        "N",
        "102388200",
        "Aug21Reg_16_29701",
        "2020-06-01",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0230,2",
        "3TR87UT#ABA",
        "",
        "3",
        "HP TB Dock G2 w/ Combo Cable",
        "CANCELED",
        "",
        "",
        "",
        "ECLX INC-VBEP-LDDAT INC-VBEP-MBDAT INC-VBEP-TDDAT INC-VBEP-WADAT INCO NoMP PRSE REL",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "MP",
        "US21",
        "US21"
      ],
      [
        "210729S59PY308",
        "",
        "N",
        "102388202",
        "PO_USPS_123",
        "2021-07-29",
        "2021-07-29",
        "0",
        "2021-07-29",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP PRSE REL",
        "CCOK CINV FCPS MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210729S59KV654",
        "",
        "N",
        "102388252",
        "Test vga 860",
        "2021-07-29",
        "2021-07-29",
        "0",
        "2021-07-29",
        "CHANNEL-COUNTRYFIT",
        "U3M01",
        "",
        "0010,2",
        "5DZ74UP#ABU",
        "",
        "1",
        "HP Z4G4T XW2145 32GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "ECLX NoMP PRSD PRSE REL",
        "CCOK REL STOR ",
        "0.00",
        "5X",
        "CH61",
        "CH61"
      ],
      [
        "210730S46YJ708",
        "",
        "N",
        "102388413",
        "PRNT-ALLOC-TEST-8",
        "2021-07-30",
        "2021-07-30",
        "0",
        "2021-07-30",
        "Standard",
        "F3M01",
        "",
        "0010,2",
        "4UJ28B#629",
        "",
        "10",
        "HP DeskJet 2622 All-in-One Prntr:EU-XMO2",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 FDSP NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "2N",
        "CH88",
        "CH88"
      ],
      [
        "210730S46DJ846",
        "",
        "N",
        "102388465",
        "123",
        "2021-07-30",
        "2021-07-30",
        "0",
        "2021-07-30",
        "Standard",
        "",
        "Iron_Mountain",
        "0010,2",
        "1JP11AV",
        "",
        "2",
        "HP Z4 G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "CFSL MDID NPIH NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210731S38GP053",
        "",
        "N",
        "102388535",
        "CR123_Reg24",
        "2021-07-31",
        "2021-07-31",
        "0",
        "2021-07-31",
        "Standard",
        "",
        "INGRAM_MICRO_INC",
        "0010,2",
        "5FP62UA#ABA",
        "",
        "11",
        "HP Laptop 14-df0014ds",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP OSS-2SMO REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "US65",
        "US65"
      ],
      [
        "210731S38GP053",
        "",
        "N",
        "102388535",
        "CR123_Reg24",
        "2021-07-31",
        "2021-07-31",
        "0",
        "2021-07-31",
        "Standard",
        "",
        "INGRAM_MICRO_INC",
        "0010,2",
        "5FP62UA#ABA",
        "",
        "11",
        "HP Laptop 14-df0014ds",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP OSS-2SMO REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "US65",
        "US65"
      ],
      [
        "210802S38VL429",
        "",
        "N",
        "102388605",
        "Inhouse Bundle testing Aug01",
        "2021-07-19",
        "2021-08-02",
        "0",
        "2021-08-02",
        "Standard",
        "",
        "",
        "0040,2",
        "U7897E",
        "",
        "1",
        "HP 4y NextBusDay Onsite DT Only HW Supp",
        "CANCELED",
        "",
        "",
        "",
        "BLPD CFSL NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "MG",
        "CN04",
        "CN04"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0010,2",
        "4HJ65AV",
        "",
        "4",
        "HP RCTO BU Z6 NMIC G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP PCSL POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0020,2",
        "1AM10AV",
        "",
        "4",
        "MUS WD USB OPT",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0030,2",
        "1AY26AV#ACJ",
        "",
        "4",
        "USB BusSlim Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0040,2",
        "1MX44AV",
        "",
        "4",
        "HP DisplayPort to DVI-D Adapter (2-pack)",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0050,2",
        "2DL32AV",
        "",
        "4",
        "Intel 6128 Xeon3.4 6C",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0060,2",
        "2DL33AV",
        "",
        "4",
        "Intel 6128 Xeon3.4 6C 2nd",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0070,2",
        "2JA81AV",
        "",
        "4",
        "HP Z6 G4 Memory Cooling Solution",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0080,2",
        "6CQ04AV",
        "",
        "4",
        "32GB (4x8GB) DDR4 2933 ECC REG AP 2CPU",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0090,2",
        "7AV95AV",
        "",
        "4",
        "GFX Nvd Qdr P2200 5GB 4DP",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0100,2",
        "Z3Z05AV#ACJ",
        "",
        "4",
        "HP Z6 G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0110,2",
        "Z3Z06AV#AB4",
        "",
        "4",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0120,2",
        "Z4H56AV",
        "",
        "4",
        "Operating System Load to SATA/SAS",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0130,2",
        "Z5G89AV",
        "",
        "4",
        "Base FIO 4xUSB3 TypeA",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0140,2",
        "Z5H58AV",
        "",
        "4",
        "9.5 DVDWR 1st ODD",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0150,2",
        "Z5H79AV",
        "",
        "4",
        "Single Unit (TWR) Packaging",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0160,2",
        "Z5H80AV",
        "",
        "4",
        "1TB 2.5in SATA SSD",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0170,2",
        "Z5H81AV",
        "",
        "4",
        "1TB 2.5in SATA 2nd SSD",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0180,2",
        "Z5J06AV",
        "",
        "4",
        "Z6 G4 90 1000W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0190,2",
        "Z5L08AV",
        "",
        "4",
        "HP Linux-ready",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S62QV846",
        "",
        "N",
        "102388660",
        "MIT CTO EDI860  INDIA 01 Change",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "",
        "KOCH_INDUSTRIES_INC_-_WW",
        "0200,2",
        "Z7X84AV",
        "",
        "4",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "BLPC COM-POC MDID NoMP POEC REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRP REL ",
        "0.00",
        "5X",
        "SG79",
        "SG79"
      ],
      [
        "210802S46YG304",
        "",
        "N",
        "102389397",
        "2297ctotesting",
        "2021-08-02",
        "2021-08-02",
        "0",
        "2021-08-02",
        "DIRECT",
        "FWP01",
        "FLORENTZ_-_LOUIS_DREYFUS_-_WW",
        "0010,2",
        "CN660A",
        "",
        "5",
        "HP PWP Webwipe Cassette 10 Pack",
        "CANCELED",
        "",
        "",
        "",
        "DELC MDID NoMP REL",
        "ADPT CCOK MDID MOFD MOID MPHO NTRS REL STOR ",
        "0.00",
        "UO",
        "CH22",
        "CH22"
      ],
      [
        "210803S39MG762",
        "",
        "N",
        "102389607",
        "FCC testing",
        "2021-08-03",
        "2021-08-03",
        "0",
        "2021-08-03",
        "Standard",
        "",
        "ABB_GROUP_-_WW",
        "0020,2",
        "5FM80A#ABY",
        "",
        "2",
        "HP Color LJ Mgd MFP E87640du+ Prntr:DK",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "G8",
        "CH32",
        "CH32"
      ],
      [
        "210803S39MG762",
        "",
        "N",
        "102389607",
        "FCC testing",
        "2021-08-03",
        "2021-08-03",
        "0",
        "2021-08-03",
        "Standard",
        "",
        "ABB_GROUP_-_WW",
        "0120,2",
        "T8X23B#629",
        "",
        "20",
        "HP DeskJet 3762 All-in-One Prntr:EU-XMO2",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP PHLD REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "2N",
        "CH32",
        "CH32"
      ],
      [
        "210804S58VG329",
        "",
        "N",
        "102389719",
        "ZRLA BANGALORE",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0010,2",
        "9AK44EC#ACJ",
        "",
        "47",
        "HP Elitex2G4 i5-8365U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "BLPC NoMP PCSL REL",
        "CCOK MOID REL ",
        "0.00",
        "8J",
        "IN29",
        "IN29"
      ],
      [
        "210804S58VG329",
        "",
        "N",
        "102389719",
        "ZRLA BANGALORE",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0020,2",
        "UA9Z1E",
        "",
        "47",
        "HP 3y TravelNextBusDay Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLPC CFSL NoMP REL STOR",
        "CCOK MOID REL ",
        "0.00",
        "MG",
        "IN01",
        "IN01"
      ],
      [
        "210804S58VG329",
        "",
        "N",
        "102389719",
        "ZRLA BANGALORE",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0030,2",
        "UZ354E",
        "",
        "47",
        "HP 3y Smart Buddy Printer Privilege SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLPC NoMP REL STOR",
        "CCOK MOID REL ",
        "0.00",
        "R6",
        "IN01",
        "IN01"
      ],
      [
        "210804S58VG329",
        "",
        "N",
        "102389719",
        "ZRLA BANGALORE",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0040,2",
        "U9GU1B",
        "",
        "47",
        "HP 2Y RTD EU directive High NB svc",
        "CANCELED",
        "",
        "",
        "",
        "BLPC CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MN",
        "IN01",
        "IN01"
      ],
      [
        "210804S58VG329",
        "",
        "N",
        "102389719",
        "ZRLA BANGALORE",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0050,2",
        "H6L29AA#UUF",
        "",
        "47",
        "HP USB Essential Keyboard and Mouse A/P",
        "CANCELED",
        "",
        "",
        "",
        "BLPC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MP",
        "IN29",
        "IN29"
      ],
      [
        "210804S39PF337",
        "",
        "N",
        "102389720",
        "ZRLA COCHIN",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0050,2",
        "H6L29AA#UUF",
        "",
        "11",
        "HP USB Essential Keyboard and Mouse A/P",
        "CANCELED",
        "",
        "",
        "",
        "BLPC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MP",
        "IN26",
        "IN26"
      ],
      [
        "210804S43LV514",
        "",
        "N",
        "102389788",
        "UAT_BYD_860_Cancellation",
        "2021-08-04",
        "2021-08-04",
        "0",
        "2021-08-04",
        "Standard",
        "",
        "",
        "0010,2",
        "12Q85EA#ABF",
        "",
        "2",
        "HP EO143 AiO T i3-7100U 8GB/256 PC",
        "DELIVERED",
        "",
        "",
        "",
        "ACKN BLKD COM-POC MDID NOCH NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "IQ",
        "CH77",
        "CH77"
      ],
      [
        "210805S43ZJ788",
        "",
        "N",
        "102390010",
        "4234235234234",
        "2021-08-05",
        "2021-08-05",
        "0",
        "2021-08-05",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP PRSE REL",
        "CCOK CINV FCPS MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210805S62MK431",
        "",
        "N",
        "102390023",
        "504838030",
        "2021-08-05",
        "2021-08-05",
        "0",
        "2021-08-05",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AP CFSL NoMP PRSE REL",
        "CCOK CINV FCPS MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210806S40LT835",
        "",
        "N",
        "102390152",
        "Partner_DMFI EC_8.6 Cancelled",
        "2021-08-06",
        "2021-08-06",
        "0",
        "2021-08-06",
        "Standard",
        "",
        "",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "2021-10-29",
        "2021-11-01",
        "",
        "CFSL CPCD NoMP REL",
        "CCOK MOID REL STCP ",
        "0.00",
        "MG",
        "PA01",
        "PA01"
      ],
      [
        "210809S43QG486",
        "",
        "N",
        "102390336",
        "Order Repeat Check_8.9",
        "2021-08-09",
        "2021-08-09",
        "-1",
        "2021-08-09",
        "Standard",
        "",
        "STATE_FARM_MUTUAL_AUTOMOBILE",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "DELIVERED",
        "2021-08-10",
        "2021-08-10",
        "",
        "BLPD CFSL ECLX NoMP REL",
        "CCOK MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210809S45FZ506",
        "",
        "N",
        "102390361",
        "APJ Order Check Cancelled_0809",
        "2021-08-09",
        "2021-08-09",
        "-1",
        "2021-08-09",
        "DIRECT",
        "",
        "",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "DELIVERED",
        "2021-08-10",
        "2021-08-10",
        "",
        "CFSL MDID NoMP REL",
        "CCOK MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "SG01",
        "SG01"
      ],
      [
        "210809S40MS992",
        "",
        "N",
        "102390418",
        "TDDchange0-T03",
        "2021-07-27",
        "2021-08-09",
        "0",
        "2021-08-09",
        "Standard",
        "",
        "",
        "0020,2",
        "4SS11AV#ABE",
        "",
        "2",
        "OSLOC",
        "DELIVERED",
        "",
        "",
        "",
        "BLKD MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210810S38PV881",
        "",
        "N",
        "102390522",
        "AMS Cancelled_0810 AAA",
        "2021-08-10",
        "2021-08-10",
        "-1",
        "2021-08-10",
        "Standard",
        "",
        "STATE_FARM_MUTUAL_AUTOMOBILE",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "DELIVERED",
        "2021-08-11",
        "2021-08-11",
        "",
        "BLPD CFSL ECLX NoMP REL",
        "CCOK MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0010,2",
        "1JP11AV",
        "",
        "1",
        "HP Z4 G4 WKS",
        "DELIVERED",
        "",
        "",
        "",
        "COM-POC MDID NoMP PCSL REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0020,2",
        "2LH04AV#ABA",
        "",
        "1",
        "Win 10 Pro 64 WKST",
        "DELIVERED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0030,2",
        "2PC01AV",
        "",
        "1",
        "Intel XeonW-2125 4.0 4C",
        "DELIVERED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0040,2",
        "1JQ29AV",
        "",
        "1",
        "Z4 G4 90 750W Chassis",
        "DELIVERED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0050,2",
        "1MY89AV",
        "",
        "1",
        "HP Z4 G4 Fan and Front Card Guide Kit",
        "DELIVERED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0060,2",
        "1MY90AV",
        "",
        "1",
        "HP Z4 G4 Memory Cooling Solution",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0070,2",
        "1JQ27AV",
        "",
        "1",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0080,2",
        "1JQ44AV#ABA",
        "",
        "1",
        "HP Z4 G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0090,2",
        "1JP10AV",
        "",
        "1",
        "Base FIO 4xUSB3 TypeA",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0100,2",
        "1JP20AV",
        "",
        "1",
        "Nvd Qdr 5GB P2000 (4)DP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0110,2",
        "2XE92AV",
        "",
        "1",
        "No Adapters Needed",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0120,2",
        "1JP90AV",
        "",
        "1",
        "HP Z Turbo Drive M.2 512GB TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0130,2",
        "1JP92AV",
        "",
        "1",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0140,2",
        "1JP42AV",
        "",
        "1",
        "1TB 7200 SATA Enterprise 3.5in",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0150,2",
        "1JQ90AV#ABA",
        "",
        "1",
        "HP KBDWD USB BusSlim SCard CCID",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0160,2",
        "1JQ80AV",
        "",
        "1",
        "32GB (4x8GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0170,2",
        "2TL55AV",
        "",
        "1",
        "MUS WD USB Prm",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0180,2",
        "1JQ97AV",
        "",
        "1",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0190,2",
        "1JR04AV",
        "",
        "1",
        "Single Unit (TWR) Packaging",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0200,2",
        "1QE66AV",
        "",
        "1",
        "HP Z4 Std CPU Cooling Solution",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S57FJ059",
        "",
        "N",
        "102391070",
        "Test EDI860 action code 2 SB",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "",
        "0210,2",
        "1JR07AV#ABA",
        "",
        "1",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC MDID NoMP REL SEAR",
        "C2AF CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "210810S43DR809",
        "",
        "N",
        "102391079",
        "MY_SST_Without Bundle1",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "NESTLE_SA_-_WW",
        "0010,2",
        "CF413XC",
        "",
        "1",
        "HP 410X Mgn Contract LJ Toner Cartridge",
        "CANCELED",
        "",
        "",
        "",
        "CFSL DCRJ MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL STCP ",
        "0.00",
        "5T",
        "SG25",
        "SG25"
      ],
      [
        "210810S42JY577",
        "",
        "N",
        "102391084",
        "MY TAX _Standalone12",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "NESTLE_SA_-_WW",
        "0030,2",
        "U9QE5AAE",
        "",
        "1",
        "HP 1y Proactive Mgmt Standard Svc E-LTU",
        "CANCELED",
        "",
        "",
        "",
        "BLES MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL STCP ",
        "0.00",
        "67",
        "SG63",
        "SG63"
      ],
      [
        "210810S42JY577",
        "",
        "N",
        "102391084",
        "MY TAX _Standalone12",
        "2021-08-10",
        "2021-08-10",
        "0",
        "2021-08-10",
        "Standard",
        "",
        "NESTLE_SA_-_WW",
        "0030,2",
        "U9QE5AAE",
        "",
        "1",
        "HP 1y Proactive Mgmt Standard Svc E-LTU",
        "CANCELED",
        "",
        "",
        "",
        "BLES MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL STCP ",
        "0.00",
        "67",
        "SG63",
        "SG63"
      ],
      [
        "210811S42NX139",
        "",
        "N",
        "102391121",
        "Mar21Reg_16_AugReg2021a",
        "2020-06-01",
        "2021-08-11",
        "0",
        "2021-08-11",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0230,2",
        "3TR87UT#ABA",
        "",
        "3",
        "HP TB Dock G2 w/ Combo Cable",
        "CANCELED",
        "",
        "",
        "",
        "C2AF INC-VBEP-LDDAT INC-VBEP-MBDAT INC-VBEP-TDDAT INC-VBEP-WADAT INCO NoMP REL",
        "CCOK FCFL MDID MOFD MOID MPAN PCRF REL RRMF ",
        "0.00",
        "MP",
        "US21",
        "US21"
      ],
      [
        "210811S45YJ524",
        "",
        "N",
        "102391185",
        "AMS Production B _0811 AAA",
        "2021-08-11",
        "2021-08-11",
        "0",
        "2021-08-11",
        "DIRECT",
        "",
        "THE_WALT_DISNEY_COMPANY",
        "0010,2",
        "U4414E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL ECLX GTIC NoMP REL",
        "CCOK MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210811S45PX750",
        "",
        "N",
        "102393029",
        "T_AUG11_Synnex",
        "2021-08-11",
        "2021-08-11",
        "0",
        "2021-08-11",
        "Standard",
        "",
        "Synnex_Corporation",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "200",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210811S45DJ054",
        "",
        "N",
        "102393031",
        "T_Aug11_Ingram",
        "2021-08-11",
        "2021-08-11",
        "0",
        "2021-08-11",
        "Standard",
        "58BOZ",
        "INGRAM_MICRO_INC",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "400",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210812S43LC604",
        "",
        "N",
        "102394655",
        "504908034",
        "2021-08-12",
        "2021-08-12",
        "0",
        "2021-08-12",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "3FW29A#BH1",
        "",
        "1",
        "HP Jet Fusion 5200 3D Build Unit:WW",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK CINV FCFL MDID MPAN REL ",
        "20800.00",
        "VI",
        "US6A",
        "US6A"
      ],
      [
        "210813S45LY536",
        "",
        "N",
        "102394856",
        "1308202101",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U4416PE",
        "",
        "123",
        "HP 1y PW NextBusDay Onsite NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL ECLX NoMP REL",
        "CCOK FCFL MDID MPAN REL STCP ",
        "14335.65",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210813S45RG168",
        "",
        "N",
        "102395814",
        "vc bom missing in item",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "Standard",
        "",
        "",
        "0040,2",
        "141X6AV#AB2",
        "",
        "1",
        "CKIT HP CCC PRC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID PCRF REL ZCON ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210813S64FL835",
        "",
        "N",
        "102396069",
        "KDD 2964 - VC BOM-5",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0290,2",
        "CN130AV",
        "",
        "1",
        "HP Service Domestic China CTP East",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN43",
        "CN43"
      ],
      [
        "210813S64FL835",
        "",
        "N",
        "102396069",
        "KDD 2964 - VC BOM-5",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0300,2",
        "2W8J9AV",
        "",
        "1",
        "Place Holder for Domestic China CTP East",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN43",
        "CN43"
      ],
      [
        "210813S45NL064",
        "",
        "N",
        "102396411",
        "KDD 2964 - VC BOM-7",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0020,2",
        "141X6AV#AB2",
        "",
        "1",
        "CKIT HP CCC PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD NoMP PRSE PRSF REL",
        "CCOK MOID PCRD REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210813S45NL064",
        "",
        "N",
        "102396411",
        "KDD 2964 - VC BOM-7",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0030,2",
        "192J5AV",
        "",
        "1",
        "Electronic TCO Certified labeling",
        "CANCELED",
        "",
        "",
        "",
        "BLKD NoMP PRSE PRSF REL",
        "CCOK MOID PCRD REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210813S45NL064",
        "",
        "N",
        "102396411",
        "KDD 2964 - VC BOM-7",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0040,2",
        "1AJ06AV",
        "",
        "1",
        "No 2nd HDD for China CGP",
        "CANCELED",
        "",
        "",
        "",
        "BLKD NoMP PRSE PRSF REL",
        "CCOK MOID PCRD REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210813S45NL064",
        "",
        "N",
        "102396411",
        "KDD 2964 - VC BOM-7",
        "2021-08-13",
        "2021-08-13",
        "0",
        "2021-08-13",
        "CHANNEL",
        "AG908",
        "",
        "0050,2",
        "1B8E7AV",
        "",
        "1",
        "SLIM ODD BAY SATA Cable Kit",
        "CANCELED",
        "",
        "",
        "",
        "BLKD NoMP PRSE PRSF REL",
        "CCOK MOID PCRD REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210816S42HY969",
        "",
        "N",
        "102397056",
        "test vc add carepacl",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "AG908",
        "",
        "0030,2",
        "192J5AV",
        "",
        "1",
        "Electronic TCO Certified labeling",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210816S42HY969",
        "",
        "N",
        "102397056",
        "test vc add carepacl",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "AG908",
        "",
        "0040,2",
        "1AJ06AV",
        "",
        "1",
        "No 2nd HDD for China CGP",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210816S42HY969",
        "",
        "N",
        "102397056",
        "test vc add carepacl",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "AG908",
        "",
        "0050,2",
        "1B8E7AV",
        "",
        "1",
        "SLIM ODD BAY SATA Cable Kit",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210816S42HY969",
        "",
        "N",
        "102397056",
        "test vc add carepacl",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "AG908",
        "",
        "0060,2",
        "141X6AV#AB2",
        "",
        "1",
        "CKIT HP CCC PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210816S62MN785",
        "",
        "N",
        "102397058",
        "TEST SRCD",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "Standard",
        "AG450",
        "",
        "0010,2",
        "L0S51AA",
        "",
        "2",
        "HP 955 Cyan Original Ink Cartridge",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CC1D CCOK HDOM MOID REL ",
        "0.00",
        "1N",
        "SG25",
        "SG25"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0010,2",
        "5FM77A#B13",
        "",
        "4",
        "HP LJ Managed MFP E82550du+ Prntr:ME",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "G8",
        "RU22",
        "RU22"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0020,2",
        "U08PXE",
        "",
        "4",
        "BOM 1 year Parts Only PL G8",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "RU01",
        "RU01"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0030,2",
        "5FM77A#B13",
        "",
        "3",
        "HP LJ Managed MFP E82550du+ Prntr:ME",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "G8",
        "RU22",
        "RU22"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0040,2",
        "U08PXE",
        "",
        "3",
        "BOM 1 year Parts Only PL G8",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "RU01",
        "RU01"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0050,2",
        "5FM77A#B13",
        "",
        "1",
        "HP LJ Managed MFP E82550du+ Prntr:ME",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "G8",
        "RU22",
        "RU22"
      ],
      [
        "210816S43DR714",
        "",
        "N",
        "102397062",
        "Happy flow 48/retest CR55954",
        "2021-08-16",
        "2021-08-16",
        "0",
        "2021-08-16",
        "CHANNEL",
        "YY403",
        "OOO_\"M.video_Management\"",
        "0060,2",
        "U08PXE",
        "",
        "1",
        "BOM 1 year Parts Only PL G8",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "RU01",
        "RU01"
      ],
      [
        "210817S43XM108",
        "",
        "N",
        "102398021",
        "T_AUG17_Ingram",
        "2021-08-17",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "58BOZ",
        "INGRAM_MICRO_INC",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "100",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210817S42GY950",
        "",
        "N",
        "102398022",
        "T_Aug17_SN",
        "2021-08-13",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "58CDE",
        "Supplies_Network",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "150",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210817S43ZM158",
        "",
        "N",
        "102398023",
        "T_Aug17_TD",
        "2021-08-16",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "58BPH",
        "Tech_Data_Product_Management_Inc",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "80",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210818S40HQ434",
        "",
        "N",
        "102398028",
        "New added via VA02 in deal-RJ",
        "2021-08-18",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "AG399",
        "",
        "0010,2",
        "1FH47AA#AB2",
        "",
        "1",
        "HP EliteDisplay E243 Monitor PRC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MPHO REL ",
        "0.00",
        "BO",
        "CN52",
        "CN52"
      ],
      [
        "210818S40HQ434",
        "",
        "N",
        "102398028",
        "New added via VA02 in deal-RJ",
        "2021-08-18",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "AG399",
        "",
        "0020,2",
        "1FH47AA#AB2",
        "",
        "1",
        "HP EliteDisplay E243 Monitor PRC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MPHO REL ",
        "0.00",
        "BO",
        "CN52",
        "CN52"
      ],
      [
        "210818S41CJ666",
        "",
        "N",
        "102398538",
        "T_Au18_Synnext1",
        "2021-08-18",
        "2021-08-18",
        "0",
        "2021-08-18",
        "Standard",
        "",
        "Synnex_Corporation",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "50",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210819S40RS032",
        "",
        "N",
        "102398601",
        "Demo0819prodc",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "DIRECT",
        "",
        "KDDI_-_WW",
        "0010,2",
        "D9Y32AA#UUF",
        "",
        "1",
        "HP UltraSlim Docking Station A/P",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MPAN REL ",
        "0.00",
        "MP",
        "SG65",
        "SG65"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0010,2",
        "6CJ09AV",
        "",
        "3",
        "BU IDS DSC T2000 4GB i7-9850H 15 G6",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PCSL PRSE REL RROM SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "3904.01",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0020,2",
        "4SS11AV#ABA",
        "",
        "3",
        "OSLOC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "AN",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0030,2",
        "6CJ59AV",
        "",
        "3",
        "Win 10 Pro 64",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0040,2",
        "6CK07AV",
        "",
        "3",
        "Integrated FHD 1080p IR TM Webcam",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0050,2",
        "6CJ28AV",
        "",
        "3",
        "15.6 FHD AG LED UWVA 220 wFHDC IR slim",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0060,2",
        "6CJ71AV",
        "",
        "3",
        "32GB (2x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0070,2",
        "6CJ86AV",
        "",
        "3",
        "256GB PCIe NVMe TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0080,2",
        "6CJ44AV",
        "",
        "3",
        "No Near Field Communication (No NFC)",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE PRSF REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0090,2",
        "6CK10AV",
        "",
        "3",
        "Intel 22260ax2x2LTECOEXMU-MIMO+BT5WW2Ant",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0100,2",
        "6CJ49AV",
        "",
        "3",
        "No WWAN",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0110,2",
        "6CJ80AV",
        "",
        "3",
        "SEC Fingerprint Sensor",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0120,2",
        "6CL89AV",
        "",
        "3",
        "4 Cell 90 WHr Long Life",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0130,2",
        "6CH99AV",
        "",
        "3",
        "150 Watt Smart PFC Slim AC Adapter",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0140,2",
        "6CK13AV#ABA",
        "",
        "3",
        "C5 1.0m tag Power Cord",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0150,2",
        "6CK04AV#ABA",
        "",
        "3",
        "3/3/0 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0160,2",
        "2KY02AV",
        "",
        "3",
        "DIB No Adobe Software",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0170,2",
        "6GS73AV",
        "",
        "3",
        "AMT Enabled",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0180,2",
        "6CJ42AV",
        "",
        "3",
        "MISC HDD Carrier Cage",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0190,2",
        "6CK14AV#ABA",
        "",
        "3",
        "Country Localization",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0200,2",
        "6CK15AV#ABA",
        "",
        "3",
        "Dual Point BL SR Cllb",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0210,2",
        "X9H35AV",
        "",
        "3",
        "eStar Enable IOPT",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0220,2",
        "7DG36AV",
        "",
        "3",
        "LBL Core i7 vPro sz3 G9",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210819S57HW579",
        "",
        "N",
        "102398707",
        "HP2B AugReg190801",
        "2020-06-01",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0230,2",
        "3TR87UT#ABA",
        "",
        "3",
        "HP TB Dock G2 w/ Combo Cable",
        "CANCELED",
        "",
        "",
        "",
        "ECLX INC-VBEP-LDDAT INC-VBEP-MBDAT INC-VBEP-TDDAT INC-VBEP-WADAT INCO NoMP PRSE REL",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "MP",
        "US21",
        "US21"
      ],
      [
        "210819S42SX808",
        "",
        "N",
        "102400595",
        "test_current TSD",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "",
        "Synnex_Corporation",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "50",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210819S57JJ178",
        "",
        "N",
        "102400718",
        "Synnex CA 0819",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "30",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210819S57JJ178",
        "",
        "N",
        "102400718",
        "Synnex CA 0819",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "R3EJC",
        "SYNNEX_CANADA_LIMITED",
        "0020,2",
        "1PS54A#BGJ",
        "",
        "25",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210819S38DY098",
        "",
        "N",
        "102400720",
        "Ingram CA 0819",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "R3EPM",
        "INGRAM_MICRO_CANADA_INC",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "3",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210819S57PY706",
        "",
        "N",
        "102400722",
        "D&H CA 0819",
        "2021-08-19",
        "2021-08-19",
        "0",
        "2021-08-19",
        "Standard",
        "R3EHR",
        "D&H_CANADA_ULC",
        "0010,2",
        "1PS54A#BGJ",
        "",
        "4",
        "HP LJ Managed E52645dn Printer:US/CA/LA",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "PQ",
        "US84",
        "US84"
      ],
      [
        "210820S64JH615",
        "",
        "N",
        "102400829",
        "Test for IN x-dock 01",
        "2021-08-10",
        "2021-08-20",
        "0",
        "2021-08-20",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0030,2",
        "UZ354E",
        "",
        "50",
        "HP 3y Smart Buddy Printer Privilege SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLPC CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "R6",
        "IN01",
        "IN01"
      ],
      [
        "210820S64JH615",
        "",
        "N",
        "102400829",
        "Test for IN x-dock 01",
        "2021-08-10",
        "2021-08-20",
        "0",
        "2021-08-20",
        "CHANNEL",
        "AG445",
        "SAVEX_TECHNOLOGIES_PVT_LTD",
        "0040,2",
        "U9GU1B",
        "",
        "50",
        "HP 2Y RTD EU directive High NB svc",
        "CANCELED",
        "",
        "",
        "",
        "BLPC CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MN",
        "IN01",
        "IN01"
      ],
      [
        "210820S59MR389",
        "",
        "N",
        "102400842",
        "OrgA-4Week-newOrder3",
        "2021-08-20",
        "2021-08-20",
        "0",
        "2021-08-20",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U1G39E",
        "",
        "1",
        "HP 5y Nextbusday Onsite WS Only HW Supp",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK CINV FCPS MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "210820S46DB959",
        "",
        "N",
        "102400922",
        "TS Sea Print - BTP 2",
        "2021-08-20",
        "2021-08-20",
        "0",
        "2021-08-20",
        "Standard",
        "2BC65",
        "",
        "0010,2",
        "1TJ09A#A82",
        "",
        "5",
        "HP Smart Tank 515 AiO Printer:E EUR/RU",
        "CANCELED",
        "",
        "",
        "",
        "C2AP NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "GC",
        "CH23",
        "CH23"
      ],
      [
        "210823S38VV527",
        "",
        "N",
        "102400969",
        "Test DCT V4",
        "2021-08-23",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "",
        "",
        "0030,2",
        "5ZY60A#ACJ",
        "",
        "18",
        "HP DesignJet T530 24-in Printer:IN",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "30",
        "SG25",
        "SG25"
      ],
      [
        "210823S38VV527",
        "",
        "N",
        "102400969",
        "Test DCT V4",
        "2021-08-23",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "",
        "",
        "0040,2",
        "6KD26A#ACJ",
        "",
        "18",
        "HP DesignJet XL 3600dr PS MFP Printer:IN",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "GE",
        "SG25",
        "SG25"
      ],
      [
        "210823S38VV527",
        "",
        "N",
        "102400969",
        "Test DCT V4",
        "2021-08-23",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "",
        "",
        "0050,2",
        "E1L21A#BCD",
        "",
        "18",
        "HP Designjet Z5400ps ePrinter:APJ",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "30",
        "SG25",
        "SG25"
      ],
      [
        "210823S38JG318",
        "",
        "N",
        "102400978",
        "Siva RFR test1 T01",
        "2021-08-01",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "58BOZ",
        "INGRAM_MICRO_INC",
        "0040,2",
        "6ZB91UA#ABA",
        "",
        "40",
        "HP Stream Laptop 14-DS0060NR",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "US66",
        "US66"
      ],
      [
        "210823S42VW773",
        "",
        "N",
        "102401007",
        "TS Reloc BTO",
        "2021-08-23",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "",
        "TOYOTA_MOTOR_-_WW",
        "0010,2",
        "W1A30A#BAZ",
        "",
        "3",
        "HP LaserJet Pro MFP M428fdw Printer:CH",
        "CANCELED",
        "",
        "",
        "",
        "BLPD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "8A",
        "CH23",
        "CH23"
      ],
      [
        "210823S42VW773",
        "",
        "N",
        "102401007",
        "TS Reloc BTO",
        "2021-08-23",
        "2021-08-23",
        "0",
        "2021-08-23",
        "Standard",
        "",
        "TOYOTA_MOTOR_-_WW",
        "0040,2",
        "8GS37A#B13",
        "",
        "2",
        "HP Clr LJ Mgd MFP E78228dn Prntr:SA",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "G8",
        "CH27",
        "CH27"
      ],
      [
        "210825S43BN789",
        "",
        "N",
        "102401138",
        "Siva CTO+BTO T02",
        "2021-08-01",
        "2021-08-24",
        "0",
        "2021-08-24",
        "Standard",
        "58BOZ",
        "INGRAM_MICRO_INC",
        "0210,2",
        "6ZB91UA#ABA",
        "",
        "30",
        "HP Stream Laptop 14-DS0060NR",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "KV",
        "US66",
        "US66"
      ],
      [
        "210825S38RF015",
        "",
        "N",
        "102401347",
        "BTO_Allocation_Cancel and return_1",
        "2021-08-25",
        "2021-08-25",
        "0",
        "2021-08-25",
        "Standard",
        "AG789",
        "",
        "0010,2",
        "B6S02A#BBU",
        "",
        "3",
        "HP LaserJet Pro M706n Printer:ID Test",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "GS",
        "SG91",
        "SG91"
      ],
      [
        "210825S62SR021",
        "",
        "N",
        "102401355",
        "test-FOE-without deal-RJ",
        "2021-08-25",
        "2021-08-25",
        "0",
        "2021-08-25",
        "DIRECT",
        "",
        "",
        "0010,2",
        "1FH47AA#AB2",
        "",
        "1",
        "HP EliteDisplay E243 Monitor PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLPD EOL2 NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "BO",
        "CN52",
        "CN52"
      ],
      [
        "210825S62SR021",
        "",
        "N",
        "102401355",
        "test-FOE-without deal-RJ",
        "2021-08-25",
        "2021-08-25",
        "0",
        "2021-08-25",
        "DIRECT",
        "",
        "",
        "0020,2",
        "H6Y82AA#AB2",
        "",
        "1",
        "HP 65W Slim AC Adapter PRC",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "MP",
        "SG76",
        "SG76"
      ],
      [
        "210826S59FP595",
        "",
        "N",
        "102401581",
        "test-FOE-CN15-30-add-rj",
        "2021-08-26",
        "2021-08-26",
        "0",
        "2021-08-26",
        "CHANNEL",
        "AH328",
        "",
        "0010,2",
        "1FH47AA#AB2",
        "",
        "1",
        "HP EliteDisplay E243 Monitor PRC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "BO",
        "CN52",
        "CN52"
      ],
      [
        "210826S45LJ097",
        "",
        "N",
        "102401585",
        "4289409 RC test",
        "2021-08-26",
        "2021-08-26",
        "0",
        "2021-08-26",
        "Standard",
        "",
        "UNIVERSITIES_-_UK_-_WW",
        "0080,2",
        "ZD021A",
        "",
        "1",
        "HP std door/dock delivery service NB",
        "CANCELED",
        "",
        "",
        "",
        "CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "16",
        "GB01",
        "GB01"
      ],
      [
        "210826S42WW155",
        "",
        "N",
        "102401615",
        "APJ PRINT BTO E_2021826143635",
        "2021-08-26",
        "2021-08-26",
        "0",
        "2021-08-26",
        "Standard",
        "AG519",
        "",
        "0230,2",
        "W1A29A#BBU",
        "",
        "20",
        "HP LaserJet Pro MFP M428fdn Printer:ID",
        "CANCELED",
        "",
        "",
        "",
        "BLPD NoMP PFRC PHLD PRSE REL",
        "CCOK MOID REL ",
        "0.00",
        "8A",
        "SG91",
        "SG91"
      ],
      [
        "210826S38XV408",
        "",
        "N",
        "102401619",
        "testpdf 1.",
        "2021-08-26",
        "2021-08-26",
        "0",
        "2021-08-26",
        "Standard",
        "",
        "HATCH_INDISA_SAS",
        "0010,2",
        "3WN80LT#ABM",
        "",
        "1",
        "280G3eS/i38100/1hq/4G/54f LTNA",
        "CANCELED",
        "",
        "",
        "",
        "C2AP GTPC MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "DG",
        "CO20",
        "CO20"
      ],
      [
        "210826S38XV408",
        "",
        "N",
        "102401619",
        "testpdf 1.",
        "2021-08-26",
        "2021-08-26",
        "0",
        "2021-08-26",
        "Standard",
        "",
        "HATCH_INDISA_SAS",
        "0020,2",
        "6XG95LP#ABM",
        "",
        "1",
        "400G4PDeD/i58500T/500h/4G/54f LTNA",
        "CANCELED",
        "",
        "",
        "",
        "C2AP GTPC MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "DG",
        "CO20",
        "CO20"
      ],
      [
        "210827S43GR313",
        "",
        "N",
        "102401981",
        "APJ PRINT BTO E_202182782325",
        "2021-08-27",
        "2021-08-27",
        "0",
        "2021-08-27",
        "Standard",
        "AG519",
        "",
        "0010,2",
        "W1A29A#BBU",
        "",
        "20",
        "HP LaserJet Pro MFP M428fdn Printer:ID",
        "CANCELED",
        "",
        "",
        "",
        "BLPD NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "8A",
        "SG91",
        "SG91"
      ],
      [
        "210831S38TR877",
        "",
        "N",
        "102402308",
        "CTO Foxconn WU Ref 01",
        "2021-08-31",
        "2021-08-31",
        "0",
        "2021-08-31",
        "DIRECT",
        "PM1SH",
        "SHELL_-KONINKLIJKE_NEDERLANDSCHE_PETROLEUM_NV_-_WW",
        "0180,2",
        "ZD081AA",
        "",
        "3",
        "Door/dock delivery workstations",
        "CANCELED",
        "",
        "",
        "",
        "C2AP CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "16",
        "FR01",
        "FR01"
      ],
      [
        "210901S38SD461",
        "",
        "N",
        "102402539",
        "test NI BHM 2",
        "2021-09-01",
        "2021-09-01",
        "0",
        "2021-09-01",
        "DIRECT",
        "I2017",
        "GSB_-_Odessa_Offset_Limited",
        "0030,2",
        "CP838A",
        "",
        "1",
        "HP HDR245 10L Yellow Crtrdg Scitex Ink",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "TY",
        "GB20",
        "GB20"
      ],
      [
        "210901S38SD461",
        "",
        "N",
        "102402539",
        "test NI BHM 2",
        "2021-09-01",
        "2021-09-01",
        "0",
        "2021-09-01",
        "DIRECT",
        "I2017",
        "GSB_-_Odessa_Offset_Limited",
        "0040,2",
        "CP838A",
        "",
        "1",
        "HP HDR245 10L Yellow Crtrdg Scitex Ink",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "TY",
        "CH24",
        "CH24"
      ],
      [
        "210901S02NW200",
        "",
        "N",
        "102402635",
        "KDD2869_16",
        "2021-09-01",
        "2021-09-01",
        "0",
        "2021-09-01",
        "CHANNEL",
        "X3LRE",
        "",
        "0020,2",
        "U09GFE",
        "",
        "1",
        "HP 3y NBD DT Bundle Austria Germ",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "MG",
        "CH01",
        "CH01"
      ],
      [
        "210901S08NC410",
        "",
        "N",
        "102402645",
        "SalesContracts_Test1..",
        "2021-09-01",
        "2021-09-01",
        "0",
        "2021-09-01",
        "CHANNEL",
        "3G00F",
        "FASTRAX_SA_CO",
        "0010,2",
        "22J87LA#ABM",
        "",
        "102",
        "HP Laptop 15-ef1006la",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 GTPC HDSC NoMP PRSE REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "PA66",
        "PA66"
      ],
      [
        "210903S00LJ097",
        "",
        "N",
        "102402904",
        "Anemoi-FXN CZ New Vendor ID",
        "2021-09-03",
        "2021-09-03",
        "0",
        "2021-09-03",
        "Standard",
        "X3LBJ",
        "",
        "0010,2",
        "2CF56A#B19",
        "",
        "1",
        "HP PW Mgd Color MFP E776dn Base Prntr:EU",
        "CANCELED",
        "",
        "",
        "",
        "BLKD COM-POC GTHT GTPC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "GQ",
        "CH75",
        "CH75"
      ],
      [
        "210903S00LJ097",
        "",
        "N",
        "102402904",
        "Anemoi-FXN CZ New Vendor ID",
        "2021-09-03",
        "2021-09-03",
        "0",
        "2021-09-03",
        "Standard",
        "X3LBJ",
        "",
        "0020,2",
        "U08PYE",
        "",
        "1",
        "BOM 1 year Parts Only PL GQ",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL GTHT GTPC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "CH01",
        "CH01"
      ],
      [
        "210903S00LJ097",
        "",
        "N",
        "102402904",
        "Anemoi-FXN CZ New Vendor ID",
        "2021-09-03",
        "2021-09-03",
        "0",
        "2021-09-03",
        "Standard",
        "X3LBJ",
        "",
        "0030,2",
        "U08Q0E",
        "",
        "1",
        "BOM 1 year Parts and Labor PL GQ",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL GTHT GTPC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "CH01",
        "CH01"
      ],
      [
        "210903S01VC162",
        "",
        "N",
        "102402922",
        "prio tdd",
        "2021-08-03",
        "2021-09-03",
        "0",
        "2021-09-03",
        "DIRECT",
        "PM1SH",
        "SHELL_-KONINKLIJKE_NEDERLANDSCHE_PETROLEUM_NV_-_WW",
        "0010,2",
        "108B0EA#ABF",
        "",
        "3",
        "HP 27-dp0048nf AiO PC",
        "CANCELED",
        "",
        "",
        "",
        "C2AP MDID NoMP REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "1M",
        "CH32",
        "CH32"
      ],
      [
        "210903S05BV522",
        "",
        "N",
        "102402993",
        "2686KDD_08",
        "2021-09-03",
        "2021-09-03",
        "0",
        "2021-09-03",
        "CHANNEL",
        "X3LOE",
        "",
        "0040,2",
        "U7D01E",
        "",
        "1",
        "HP 3yr PriorityManagemt PC 1K+ seats SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AP CFSL ESCD NoMP REL STOR",
        "CCOK MOID REL STOR ",
        "0.00",
        "MG",
        "CH01",
        "CH01"
      ],
      [
        "210906S03WL340",
        "",
        "N",
        "102403186",
        "Northern Ireland None EU via GB03",
        "2021-09-06",
        "2021-09-06",
        "0",
        "2021-09-06",
        "DIRECT",
        "A20U0",
        "LIBERTY_MUTUAL_HOLDING_COMPANY_INC_-_WW",
        "0020,2",
        "6TQ90EA#ABU",
        "",
        "1",
        "HP Z VR BP G2 i7-8850H 32GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "BQ",
        "CH66",
        "CH66"
      ],
      [
        "210906S03WL340",
        "",
        "N",
        "102403186",
        "Northern Ireland None EU via GB03",
        "2021-09-06",
        "2021-09-06",
        "0",
        "2021-09-06",
        "DIRECT",
        "A20U0",
        "LIBERTY_MUTUAL_HOLDING_COMPANY_INC_-_WW",
        "0030,2",
        "6TQ90EA#ABU",
        "",
        "1",
        "HP Z VR BP G2 i7-8850H 32GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 GTPC MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID NTRS REL ",
        "0.00",
        "BQ",
        "CH26",
        "CH26"
      ],
      [
        "210907S02VT876",
        "",
        "N",
        "102403256",
        "BOM 5 partial HW",
        "2021-09-07",
        "2021-09-07",
        "0",
        "2021-09-07",
        "Standard",
        "",
        "",
        "0030,2",
        "AY158AV",
        "",
        "4",
        "Regional Staging Center Service",
        "CANCELED",
        "",
        "",
        "",
        "BLPD C2AP CFSL MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "9R",
        "CN48",
        "CN48"
      ],
      [
        "210907S02VT876",
        "",
        "N",
        "102403256",
        "BOM 5 partial HW",
        "2021-09-07",
        "2021-09-07",
        "0",
        "2021-09-07",
        "Standard",
        "",
        "",
        "0040,2",
        "2D1L1AV",
        "",
        "4",
        "HP 2F6N7AV Z2G4SFF ImageLANXESS/Infosys",
        "CANCELED",
        "",
        "",
        "",
        "BLPD C2AP CFSL MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "9R",
        "CN48",
        "CN48"
      ],
      [
        "210907S02VT876",
        "",
        "N",
        "102403256",
        "BOM 5 partial HW",
        "2021-09-07",
        "2021-09-07",
        "0",
        "2021-09-07",
        "Standard",
        "",
        "",
        "0050,2",
        "6SP30AA#UUF",
        "",
        "4",
        "HP Bluetooth Travel Mouse A/P",
        "CANCELED",
        "",
        "",
        "",
        "BLPD C2AP MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "MP",
        "CN48",
        "CN48"
      ],
      [
        "210907S00RY564",
        "",
        "N",
        "102403434",
        "INC4433289 - BOM3 CP cancelled",
        "2021-09-07",
        "2021-09-07",
        "0",
        "2021-09-07",
        "Standard",
        "",
        "",
        "0020,2",
        "U9ZA7E",
        "",
        "1",
        "HP 4y Nbd+DMR DesignJet Z9-44 1 roll HWS",
        "CANCELED",
        "",
        "",
        "",
        "CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "K2",
        "AU01",
        "AU01"
      ],
      [
        "210908S03NJ539",
        "",
        "N",
        "102403631",
        "BOM 5 partial HW.1",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "",
        "",
        "0050,2",
        "6SP30AA#UUF",
        "",
        "2",
        "HP Bluetooth Travel Mouse A/P",
        "CANCELED",
        "",
        "",
        "",
        "BLPD MDID NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "MP",
        "CN48",
        "CN48"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0010,2",
        "2YW27AV",
        "",
        "10",
        "HP Z2 TWR G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP PCSL REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0020,2",
        "2ZR37AV",
        "",
        "10",
        "Single Unit TWR Z2 Packaging",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0030,2",
        "2ZS06AV",
        "",
        "10",
        "Z2 TWR G4 90 500W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0040,2",
        "3FK24AV#ABD",
        "",
        "10",
        "Win 10 Pro 64 WKST",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0050,2",
        "3PL99AV",
        "",
        "10",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0060,2",
        "7AD14AV",
        "",
        "10",
        "CPU I Xeon E -2224G 3.5GHz 4C",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0070,2",
        "2ZR59AV",
        "",
        "10",
        "32GB (2x16GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0080,2",
        "2ZQ96AV",
        "",
        "10",
        "Nvd Qdr 8GB P4000 (4)DP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0090,2",
        "5RQ61AV",
        "",
        "10",
        "HP Z Turbo Drv M.2 512GB SED TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0100,2",
        "2ZR80AV",
        "",
        "10",
        "512GB 2.5in SATA SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0110,2",
        "2ZR34AV",
        "",
        "10",
        "No Included Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0120,2",
        "2ZR35AV",
        "",
        "10",
        "No Included Mouse",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0130,2",
        "2ZR41AV",
        "",
        "10",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0140,2",
        "2ZS03AV",
        "",
        "10",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0150,2",
        "2ZV81AV#ABB",
        "",
        "10",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0160,2",
        "3GD03AV",
        "",
        "10",
        "HP Z2 Front USB-C 3.1 Port",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0170,2",
        "6UA25AV",
        "",
        "10",
        "No Adapters Needed",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0180,2",
        "2ZV86AV#ABD",
        "",
        "10",
        "HP Z2 TWR G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0190,2",
        "4NM33AV",
        "",
        "10",
        "Z2TWR SATA Data Cbl - ST-RA -015",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S08QB306",
        "",
        "N",
        "102403651",
        "FXOCH_5_BIS",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "D3M01",
        "",
        "0200,2",
        "4NM31AV",
        "",
        "10",
        "HP 3.5 to 2.5 Dr Carrier Z2 TWR",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NoMP REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210908S02JS035",
        "",
        "N",
        "102403679",
        "BTP for Russia",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "",
        "",
        "0010,2",
        "W1A31A#B09",
        "",
        "50",
        "HP LJ Pro MFP M428dw Prntr:EAC EU MULTI",
        "CANCELED",
        "",
        "",
        "",
        "BLKD GTPC ILCN ILCR ILEA ILNE ILRH ILRN ILWI NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "8A",
        "RU22",
        "RU22"
      ],
      [
        "210908S02JS035",
        "",
        "N",
        "102403679",
        "BTP for Russia",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "",
        "",
        "0020,2",
        "U07CCSB",
        "",
        "50",
        "BOM 3 YEARS UP REGISTRATION LJ M400 MFM",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL GTPC ILCN ILCR ILEA ILNE ILRH ILRN ILWI NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "64",
        "RU01",
        "RU01"
      ],
      [
        "210908S02JS035",
        "",
        "N",
        "102403679",
        "BTP for Russia",
        "2021-09-08",
        "2021-09-08",
        "0",
        "2021-09-08",
        "Standard",
        "",
        "",
        "0080,2",
        "2CF56A#B19",
        "",
        "30",
        "HP PW Mgd Color MFP E776dn Base Prntr:EU",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL",
        "CCOK MOID REL ",
        "0.00",
        "GQ",
        "RU22",
        "RU22"
      ],
      [
        "210909S02VT747",
        "",
        "N",
        "102403722",
        "CN27 (CN15) testing 2",
        "2021-09-09",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "",
        "0020,2",
        "3S168PA#AB2",
        "",
        "2",
        "HP Pavilion Laptop 13-an1072TU 2",
        "CANCELED",
        "",
        "",
        "",
        "BLPD MDID NOCH NoMP REL",
        "CCOK MDID MPAN NTRS REL ",
        "0.00",
        "KV",
        "CN27",
        "CN48"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0010,2",
        "6CJ09AV",
        "",
        "3",
        "BU IDS DSC T2000 4GB i7-9850H 15 G6",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PCSL PRSE REL RROM SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0020,2",
        "4SS11AV#ABA",
        "",
        "3",
        "OSLOC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "AN",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0030,2",
        "6CJ59AV",
        "",
        "3",
        "Win 10 Pro 64",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0040,2",
        "6CK07AV",
        "",
        "3",
        "Integrated FHD 1080p IR TM Webcam",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0050,2",
        "6CJ28AV",
        "",
        "3",
        "15.6 FHD AG LED UWVA 220 wFHDC IR slim",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0060,2",
        "6CJ71AV",
        "",
        "3",
        "32GB (2x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0070,2",
        "6CJ86AV",
        "",
        "3",
        "256GB PCIe NVMe TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0080,2",
        "6CJ44AV",
        "",
        "3",
        "No Near Field Communication (No NFC)",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0090,2",
        "6CK10AV",
        "",
        "3",
        "Intel 22260ax2x2LTECOEXMU-MIMO+BT5WW2Ant",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0100,2",
        "6CJ49AV",
        "",
        "3",
        "No WWAN",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0110,2",
        "6CJ80AV",
        "",
        "3",
        "SEC Fingerprint Sensor",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0120,2",
        "6CL89AV",
        "",
        "3",
        "4 Cell 90 WHr Long Life",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0130,2",
        "6CH99AV",
        "",
        "3",
        "150 Watt Smart PFC Slim AC Adapter",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0140,2",
        "6CK13AV#ABA",
        "",
        "3",
        "C5 1.0m tag Power Cord",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0150,2",
        "6CK04AV#ABA",
        "",
        "3",
        "3/3/0 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0160,2",
        "2KY02AV",
        "",
        "3",
        "DIB No Adobe Software",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL1 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0170,2",
        "6GS73AV",
        "",
        "3",
        "AMT Enabled",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0180,2",
        "6CJ42AV",
        "",
        "3",
        "MISC HDD Carrier Cage",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0190,2",
        "6CK14AV#ABA",
        "",
        "3",
        "Country Localization",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0200,2",
        "6CK15AV#ABA",
        "",
        "3",
        "Dual Point BL SR Cllb",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0210,2",
        "X9H35AV",
        "",
        "3",
        "eStar Enable IOPT",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX EOL1 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0220,2",
        "7DG36AV",
        "",
        "3",
        "LBL Core i7 vPro sz3 G9",
        "CANCELED",
        "",
        "",
        "",
        "BLKD C2AF ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "3.90",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210909S03HP920",
        "",
        "N",
        "102403743",
        "Mar21Reg_16_21RSep_01",
        "2020-06-01",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0230,2",
        "3TR87UT#ABA",
        "",
        "3",
        "HP TB Dock G2 w/ Combo Cable",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX INC-VBEP-LDDAT INC-VBEP-MBDAT INC-VBEP-TDDAT INC-VBEP-WADAT INCO NoMP PRSE REL",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "MP",
        "US21",
        "US21"
      ],
      [
        "210909S08BJ417",
        "",
        "N",
        "102403778",
        "TEsting DIBH-2",
        "2021-09-09",
        "2021-09-09",
        "0",
        "2021-09-09",
        "Standard",
        "AG308",
        "",
        "0020,2",
        "180Z9AA#AB1",
        "",
        "1",
        "HP Slim Desktop S01-pf1149kr PC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK DIBH MOID REL ",
        "0.00",
        "1M",
        "SG73",
        "SG73"
      ],
      [
        "210910S01LD825",
        "",
        "N",
        "102403990",
        "SHIPPING CON-01_202191061955",
        "2021-09-10",
        "2021-09-10",
        "0",
        "2021-09-10",
        "Standard",
        "AHE40",
        "",
        "0020,2",
        "W1A34A#AB2",
        "",
        "1",
        "HP LaserJet Pro MFP M429fdn Printer:CN",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "8A",
        "CN20",
        "CN20"
      ],
      [
        "210913S07CR590",
        "",
        "N",
        "102404253",
        "UCDAB436099_SeptUAT0",
        "2021-08-27",
        "2021-09-13",
        "0",
        "2021-09-13",
        "Standard",
        "",
        "UNIVERSITY_OF_CALIFORNIA_DAVIS",
        "0010,2",
        "8MB10A6#ABA",
        "",
        "6",
        "HP P24q G4 23.8-inch Monitor",
        "CANCELED",
        "",
        "",
        "",
        "INC-VBAP-CEPOK INC-VBAP-NETWR INCO MDID NoMP PRSE REL RROM",
        "CCAP CCOK MDID MOFD MOID MPAN NTRS PRAF REL ",
        "0.00",
        "BO",
        "US76",
        "US76"
      ],
      [
        "210913S02PV687",
        "",
        "N",
        "102404327",
        "NWPR4",
        "2021-09-02",
        "2021-09-13",
        "0",
        "2021-09-13",
        "Standard",
        "D3M01",
        "",
        "0040,2",
        "3FK24AV#ABD",
        "",
        "10",
        "Win 10 Pro 64 WKST",
        "CANCELED",
        "",
        "",
        "",
        "BLKD EOL2 NoMP REL",
        "CCOK MOID PCRF REL ZPIP ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210913S02PV687",
        "",
        "N",
        "102404327",
        "NWPR4",
        "2021-09-02",
        "2021-09-13",
        "0",
        "2021-09-13",
        "Standard",
        "D3M01",
        "",
        "0050,2",
        "3PL99AV",
        "",
        "10",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "BLKD NoMP REL",
        "CCOK MOID PCRF REL ZPIP ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210913S02TT791",
        "",
        "N",
        "102404328",
        "NWPR5",
        "2021-09-02",
        "2021-09-13",
        "0",
        "2021-09-13",
        "DIRECT",
        "FM3FU",
        "FUJIFILM_CORPORATION_-_WW",
        "0030,2",
        "4YH35AV#BCM",
        "",
        "1",
        "OS Localization",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MDID MOFD MOID NTRS PCRP REL ",
        "0.00",
        "7F",
        "CH66",
        "CH66"
      ],
      [
        "210914S00PY934",
        "",
        "N",
        "102404454",
        "UCDAB436099_SeptUATHA",
        "2021-08-27",
        "2021-09-14",
        "0",
        "2021-09-14",
        "Standard",
        "",
        "UNIVERSITY_OF_CALIFORNIA_DAVIS",
        "0010,2",
        "8MB10A6#ABA",
        "",
        "6",
        "HP P24q G4 23.8-inch Monitor",
        "CANCELED",
        "",
        "",
        "",
        "INC-VBAP-CEPOK INC-VBAP-NETWR INCO MDID NoMP PRSE REL RROM",
        "CCAP CCOK MDID MOFD MOID MPAN NTRS PRAF REL ",
        "0.00",
        "BO",
        "US76",
        "US76"
      ],
      [
        "210914S19BZ542",
        "",
        "N",
        "102404455",
        "UCDAB436099_SeptUATH01",
        "2021-08-27",
        "2021-09-14",
        "0",
        "2021-09-14",
        "Standard",
        "",
        "UNIVERSITY_OF_CALIFORNIA_DAVIS",
        "0010,2",
        "8MB10A6#ABA",
        "",
        "6",
        "HP P24q G4 23.8-inch Monitor",
        "CANCELED",
        "",
        "",
        "",
        "INC-VBAP-CEPOK INC-VBAP-NETWR INCO MDID NoMP PRSE REL RROM",
        "CCAP CCOK MDID MOFD MOID MPAN NTRS PRAF REL ",
        "0.00",
        "BO",
        "US76",
        "US76"
      ],
      [
        "210915S03WM265",
        "",
        "N",
        "102404728",
        "RTSept15_ML18",
        "2020-04-16",
        "2021-09-15",
        "0",
        "2021-09-15",
        "CHANNEL-COUNTRYFIT",
        "Q3M01",
        "ALSO_A/S",
        "0030,2",
        "D9Y32AA#ABB",
        "",
        "1",
        "HP UltraSlim Docking Station EURO",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 GTIC NoMP OSS-OPTIC REL",
        "CCOK MOID MPHO REL ",
        "0.00",
        "MP",
        "CH61",
        "CH61"
      ],
      [
        "210915S03CM936",
        "",
        "N",
        "102404990",
        "PRNT-ALLOC-TEST-38",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "Q3M01",
        "",
        "0010,2",
        "T8X19B#629",
        "",
        "10",
        "HP DeskJet 3760 All-in-One Prntr:EU-XMO2",
        "CANCELED",
        "",
        "",
        "",
        "FDSP NoMP PHLD REL SCAL",
        "CCOK MOID REL ",
        "0.00",
        "2N",
        "CH92",
        "CH92"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0010,2",
        "2YW27AV",
        "",
        "10",
        "HP Z2 TWR G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP PCSL REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0020,2",
        "2ZR37AV",
        "",
        "10",
        "Single Unit TWR Z2 Packaging",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0030,2",
        "2ZS06AV",
        "",
        "10",
        "Z2 TWR G4 90 500W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0040,2",
        "3FK24AV#ABD",
        "",
        "10",
        "Win 10 Pro 64 WKST",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0050,2",
        "3PL99AV",
        "",
        "10",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0060,2",
        "7AD14AV",
        "",
        "10",
        "CPU I Xeon E -2224G 3.5GHz 4C",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0070,2",
        "2ZR59AV",
        "",
        "10",
        "32GB (2x16GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0080,2",
        "2ZQ96AV",
        "",
        "10",
        "Nvd Qdr 8GB P4000 (4)DP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0090,2",
        "5RQ61AV",
        "",
        "10",
        "HP Z Turbo Drv M.2 512GB SED TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0100,2",
        "2ZR80AV",
        "",
        "10",
        "512GB 2.5in SATA SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0110,2",
        "2ZR34AV",
        "",
        "10",
        "No Included Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0120,2",
        "2ZR35AV",
        "",
        "10",
        "No Included Mouse",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0130,2",
        "2ZR41AV",
        "",
        "10",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0140,2",
        "2ZS03AV",
        "",
        "10",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0150,2",
        "2ZV81AV#ABB",
        "",
        "10",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0160,2",
        "3GD03AV",
        "",
        "10",
        "HP Z2 Front USB-C 3.1 Port",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0170,2",
        "6UA25AV",
        "",
        "10",
        "No Adapters Needed",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0180,2",
        "2ZV86AV#ABD",
        "",
        "10",
        "HP Z2 TWR G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0190,2",
        "4NM33AV",
        "",
        "10",
        "Z2TWR SATA Data Cbl - ST-RA -015",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0200,2",
        "4NM31AV",
        "",
        "10",
        "HP 3.5 to 2.5 Dr Carrier Z2 TWR",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0210,2",
        "2YW27AV",
        "",
        "10",
        "HP Z2 TWR G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP PCSL REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0220,2",
        "2ZR37AV",
        "",
        "10",
        "Single Unit TWR Z2 Packaging",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0230,2",
        "2ZS06AV",
        "",
        "10",
        "Z2 TWR G4 90 500W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0240,2",
        "3FK24AV#ABD",
        "",
        "10",
        "Win 10 Pro 64 WKST",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0250,2",
        "3PL99AV",
        "",
        "10",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0260,2",
        "7AD14AV",
        "",
        "10",
        "CPU I Xeon E -2224G 3.5GHz 4C",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0270,2",
        "2ZR59AV",
        "",
        "10",
        "32GB (2x16GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0280,2",
        "2ZQ96AV",
        "",
        "10",
        "Nvd Qdr 8GB P4000 (4)DP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0290,2",
        "5RQ61AV",
        "",
        "10",
        "HP Z Turbo Drv M.2 512GB SED TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0300,2",
        "2ZR80AV",
        "",
        "10",
        "512GB 2.5in SATA SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0310,2",
        "2ZR34AV",
        "",
        "10",
        "No Included Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0320,2",
        "2ZR35AV",
        "",
        "10",
        "No Included Mouse",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0330,2",
        "2ZR41AV",
        "",
        "10",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0340,2",
        "2ZS03AV",
        "",
        "10",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0350,2",
        "2ZV81AV#ABB",
        "",
        "10",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0360,2",
        "3GD03AV",
        "",
        "10",
        "HP Z2 Front USB-C 3.1 Port",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0370,2",
        "6UA25AV",
        "",
        "10",
        "No Adapters Needed",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0380,2",
        "2ZV86AV#ABD",
        "",
        "10",
        "HP Z2 TWR G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0390,2",
        "4NM33AV",
        "",
        "10",
        "Z2TWR SATA Data Cbl - ST-RA -015",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S03HL465",
        "",
        "N",
        "102405057",
        "FXOCH_5_TER",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "D3M01",
        "",
        "0400,2",
        "4NM31AV",
        "",
        "10",
        "HP 3.5 to 2.5 Dr Carrier Z2 TWR",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210915S26MP257",
        "",
        "N",
        "102405068",
        "00000145378507",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "57BMA",
        "Costco_Wholesale",
        "0010,2",
        "1G128UA#ABA",
        "",
        "2",
        "HP Chromebook 14a 14a-na0023cl",
        "CANCELED",
        "",
        "",
        "",
        "BTCC EOL1 NoMP REL RROM",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "US66",
        "US66"
      ],
      [
        "210915S02BZ482",
        "",
        "N",
        "102405076",
        "INC4433289 - BOM3 CP cancelled II",
        "2021-09-15",
        "2021-09-15",
        "0",
        "2021-09-15",
        "Standard",
        "",
        "",
        "0020,2",
        "U9ZA7E",
        "",
        "1",
        "HP 4y Nbd+DMR DesignJet Z9-44 1 roll HWS",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL STCP ",
        "0.00",
        "K2",
        "AU01",
        "AU01"
      ],
      [
        "210916S03TN274",
        "",
        "N",
        "102405117",
        "BTO Allocation Test PLSP",
        "2021-09-16",
        "2021-09-16",
        "0",
        "2021-09-16",
        "Standard",
        "",
        "",
        "0010,2",
        "7FR25B#BG2",
        "",
        "100",
        "HP DeskJet IA 2777 AiO Printer:TH/PH-th",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "2N",
        "SG92",
        "SG92"
      ],
      [
        "210916S07CS770",
        "",
        "N",
        "102405137",
        "BTO Allocation Ack Test",
        "2021-09-16",
        "2021-09-16",
        "0",
        "2021-09-16",
        "Standard",
        "",
        "HONEYWELL_INTERNATIONAL_-_WW",
        "0010,2",
        "1PV64A#BBU",
        "",
        "2",
        "HP LaserJet Ent MFP M528dn Printer:ID",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP PHLD REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "C5",
        "SG91",
        "SG91"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0010,2",
        "8TP60AV",
        "",
        "10",
        "BU IDS UMA i5-10310U 850 G7",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PCSL PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0020,2",
        "9TR23AV",
        "",
        "10",
        "MISC Packaging Standard",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0030,2",
        "4SS11AV#ABD",
        "",
        "10",
        "OSLOC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0040,2",
        "8TP96AV",
        "",
        "10",
        "OST W10P6",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0050,2",
        "8TQ23AV",
        "",
        "10",
        "WEBCAM Integrated HD 720p IR DM",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0060,2",
        "8TP76AV",
        "",
        "10",
        "LCD 15.6FHDAGLEDUWVA250fWANfHDCIRbentTOP",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0070,2",
        "8TQ00AV",
        "",
        "10",
        "RAM 16GB (1x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0080,2",
        "8TQ10AV",
        "",
        "10",
        "SSD 256GB PCIe NVMe Value",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0090,2",
        "8TQ33AV",
        "",
        "10",
        "MISC No NFC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0100,2",
        "8TQ19AV",
        "",
        "10",
        "WLAN IWiFi6AX201ax2x2MUMIMO160MHz+BT5WW2",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0110,2",
        "8TQ16AV",
        "",
        "10",
        "WWAN I XMM 7360 LTE-Advanced (Cat 9)",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0120,2",
        "8TQ27AV",
        "",
        "10",
        "MISC No SVC Prvdr EMEA",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0130,2",
        "8TQ30AV",
        "",
        "10",
        "SEC Fingerprint Sensor",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0140,2",
        "8TQ34AV",
        "",
        "10",
        "SEC Active SmartCard",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0150,2",
        "8TP51AV",
        "",
        "10",
        "BATT 3C 56 WHr Long Life",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0160,2",
        "8TP49AV",
        "",
        "10",
        "ACADPT 65 Watt Smart nPFC RA",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0170,2",
        "8TQ76AV#ABD",
        "",
        "10",
        "PWRCORD C5 1.0m stkr CNVTL GR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0180,2",
        "8TQ82AV#ABB",
        "",
        "10",
        "WARR 3/3/0 EURO",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0190,2",
        "8TQ36AV",
        "",
        "10",
        "MISC AMT Enabled",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0200,2",
        "8TQ85AV#ABD",
        "",
        "10",
        "CNTRYLOC GR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0210,2",
        "8TQ42AV#ABD",
        "",
        "10",
        "KBDPM CP+PS num kypd SR GR",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX EOL1 NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0220,2",
        "3AH72AV",
        "",
        "10",
        "EU RED Pictogram Label",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0230,2",
        "3E755AV",
        "",
        "10",
        "Electronic TCO Certified labeling",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0240,2",
        "X7B43AV",
        "",
        "10",
        "eStar Enable IOPT",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NPIH NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0250,2",
        "9JP85AV",
        "",
        "10",
        "LBL Core i5 vPro sz3 G10",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "AN",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0260,2",
        "AY103AV",
        "",
        "10",
        "HP PC BIOS Settings Service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0270,2",
        "AY106AV",
        "",
        "10",
        "HP Intel vPro Setup + Configuration Svc",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0280,2",
        "AY130AV",
        "",
        "10",
        "HP Packaging Tag SN+MAC1+MAC2+UUID SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0290,2",
        "3EG02AV",
        "",
        "10",
        "HP Packaging Tag SN+HBMA+WLAN+UUID SVC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0300,2",
        "1U579AV",
        "",
        "10",
        "HP 1U579AV Raiffeisen 850 G7 BIOS",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0310,2",
        "7NM33AV",
        "",
        "10",
        "HP 7NM33AV Raiffeisen 850 G7 VPRO",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0320,2",
        "YW595AV",
        "",
        "10",
        "HP Account Service - Level A",
        "CANCELED",
        "",
        "",
        "",
        "BLKD ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "9R",
        "CH65",
        "CH65"
      ],
      [
        "210909S22TK496",
        "",
        "N",
        "102405363",
        "Mar21Reg_11_21RSep_01",
        "2021-02-22",
        "2021-09-09",
        "0",
        "2021-09-09",
        "CHANNEL",
        "J3M03",
        "",
        "0330,2",
        "ZD021AV",
        "",
        "10",
        "HP Deliv SVC Door/Dock NB",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL ECLX NoMP PRSD PRSE REL",
        "CCOK MOID PCRP REL ",
        "0.00",
        "16",
        "AT01",
        "AT01"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0010,2",
        "6CJ09AV",
        "",
        "3",
        "BU IDS DSC T2000 4GB i7-9850H 15 G6",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PCSL PRSE REL RROM SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0020,2",
        "4SS11AV#ABA",
        "",
        "3",
        "OSLOC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "AN",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0030,2",
        "6CJ59AV",
        "",
        "3",
        "Win 10 Pro 64",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0040,2",
        "6CK07AV",
        "",
        "3",
        "Integrated FHD 1080p IR TM Webcam",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0050,2",
        "6CJ28AV",
        "",
        "3",
        "15.6 FHD AG LED UWVA 220 wFHDC IR slim",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0060,2",
        "6CJ71AV",
        "",
        "3",
        "32GB (2x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0070,2",
        "6CJ86AV",
        "",
        "3",
        "256GB PCIe NVMe TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0080,2",
        "6CJ44AV",
        "",
        "3",
        "No Near Field Communication (No NFC)",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0090,2",
        "6CK10AV",
        "",
        "3",
        "Intel 22260ax2x2LTECOEXMU-MIMO+BT5WW2Ant",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0100,2",
        "6CJ49AV",
        "",
        "3",
        "No WWAN",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0110,2",
        "6CJ80AV",
        "",
        "3",
        "SEC Fingerprint Sensor",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0120,2",
        "6CL89AV",
        "",
        "3",
        "4 Cell 90 WHr Long Life",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0130,2",
        "6CH99AV",
        "",
        "3",
        "150 Watt Smart PFC Slim AC Adapter",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0140,2",
        "6CK13AV#ABA",
        "",
        "3",
        "C5 1.0m tag Power Cord",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0150,2",
        "6CK04AV#ABA",
        "",
        "3",
        "3/3/0 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0160,2",
        "2KY02AV",
        "",
        "3",
        "DIB No Adobe Software",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL1 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0170,2",
        "6GS73AV",
        "",
        "3",
        "AMT Enabled",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0180,2",
        "6CJ42AV",
        "",
        "3",
        "MISC HDD Carrier Cage",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0190,2",
        "6CK14AV#ABA",
        "",
        "3",
        "Country Localization",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0200,2",
        "6CK15AV#ABA",
        "",
        "3",
        "Dual Point BL SR Cllb",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL2 NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0210,2",
        "X9H35AV",
        "",
        "3",
        "eStar Enable IOPT",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX EOL1 NoMP PRSD PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0220,2",
        "7DG36AV",
        "",
        "3",
        "LBL Core i7 vPro sz3 G9",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX NoMP PRSE REL SEAH",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "0.00",
        "TA",
        "US21",
        "US21"
      ],
      [
        "210920S00WY178",
        "",
        "N",
        "102405700",
        "Mar21Reg_16_21RJUN",
        "2020-06-01",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "",
        "METROPOLITAN_COUNCIL",
        "0230,2",
        "3TR87UT#ABA",
        "",
        "3",
        "HP TB Dock G2 w/ Combo Cable",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX INC-VBEP-LDDAT INC-VBEP-MBDAT INC-VBEP-TDDAT INC-VBEP-WADAT INCO NoMP PRSE REL",
        "CCOK FCFL MDID MOID MPAN PCRF REL RRMF ",
        "574.82",
        "MP",
        "US21",
        "US21"
      ],
      [
        "210920S05DJ649",
        "",
        "N",
        "102405793",
        "3MIT-SG-02_2021_202192017930",
        "2021-09-20",
        "2021-09-20",
        "0",
        "2021-09-20",
        "Standard",
        "AG789",
        "",
        "0260,2",
        "1UN12AA#AB4",
        "",
        "2",
        "HP Engage One Advanced I/O Base SING",
        "CANCELED",
        "",
        "",
        "",
        "COM-POC NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IQ",
        "SG76",
        "SG76"
      ],
      [
        "210923S03CM232",
        "",
        "N",
        "102407183",
        "audrey_tc8_tw",
        "2021-09-23",
        "2021-09-23",
        "0",
        "2021-09-23",
        "Standard",
        "",
        "SHELL_-KONINKLIJKE_NEDERLANDSCHE_PETROLEUM_NV_-_WW",
        "0050,2",
        "1G5D5AA",
        "",
        "1",
        "HP Home Delivery Svc Desktops",
        "CANCELED",
        "",
        "",
        "",
        "C2AP CFSL ECLE MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "16",
        "TW01",
        "TW01"
      ],
      [
        "210923S02DY954",
        "",
        "N",
        "102407360",
        "Before code fix",
        "2021-09-23",
        "2021-09-23",
        "0",
        "2021-09-23",
        "Standard",
        "",
        "",
        "0010,2",
        "8YM57AV",
        "",
        "1",
        "BU RCTO EliteDesk 800 G6 SFF",
        "CANCELED",
        "",
        "",
        "",
        "BLKD Cstd MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ZCON ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210923S02DY954",
        "",
        "N",
        "102407360",
        "Before code fix",
        "2021-09-23",
        "2021-09-23",
        "0",
        "2021-09-23",
        "Standard",
        "",
        "",
        "0020,2",
        "8YN31AV",
        "",
        "1",
        "SSD 512G M.2 2280 PCIe NVMe TLC 2nd",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ZCON ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210923S02DY954",
        "",
        "N",
        "102407360",
        "Before code fix",
        "2021-09-23",
        "2021-09-23",
        "0",
        "2021-09-23",
        "Standard",
        "",
        "",
        "0030,2",
        "AY128AV",
        "",
        "1",
        "HP Chassis Tagging SN+MAC1+UUID service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ZCON ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210923S02DY954",
        "",
        "N",
        "102407360",
        "Before code fix",
        "2021-09-23",
        "2021-09-23",
        "0",
        "2021-09-23",
        "Standard",
        "",
        "",
        "0040,2",
        "YT924AV",
        "",
        "1",
        "HP Chassis Tagging SN+MAC1+UUID Service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ZCON ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05LC192",
        "",
        "N",
        "102407425",
        "After code fix",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0010,2",
        "8YM57AV",
        "",
        "2",
        "BU RCTO EliteDesk 800 G6 SFF",
        "CANCELED",
        "",
        "",
        "",
        "BLKD Cstd MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05LC192",
        "",
        "N",
        "102407425",
        "After code fix",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0020,2",
        "8YN31AV",
        "",
        "2",
        "SSD 512G M.2 2280 PCIe NVMe TLC 2nd",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05LC192",
        "",
        "N",
        "102407425",
        "After code fix",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0030,2",
        "AY128AV",
        "",
        "2",
        "HP Chassis Tagging SN+MAC1+UUID service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05LC192",
        "",
        "N",
        "102407425",
        "After code fix",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0040,2",
        "YT924AV",
        "",
        "2",
        "HP Chassis Tagging SN+MAC1+UUID Service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0010,2",
        "8YM57AV",
        "",
        "1",
        "BU RCTO EliteDesk 800 G6 SFF",
        "CANCELED",
        "",
        "",
        "",
        "BLKD Cstd NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0020,2",
        "192J5AV",
        "",
        "1",
        "Electronic TCO Certified labeling",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0030,2",
        "1AJ06AV",
        "",
        "1",
        "No 2nd HDD for China CGP",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0040,2",
        "1B8E7AV",
        "",
        "1",
        "SLIM ODD BAY SATA Cable Kit",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0050,2",
        "1Y482AV",
        "",
        "1",
        "MUS WD USB BLK",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0060,2",
        "1Y484AV#AB2",
        "",
        "1",
        "HP KBDWD BLK USB PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0070,2",
        "2Y778AV",
        "",
        "1",
        "HP 2Y778AV AMAZONCOM IMG",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0080,2",
        "3E735AV",
        "",
        "1",
        "HP BIOS SETTINGS",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0090,2",
        "3F685AV",
        "",
        "1",
        "LBL Intel CML Core i5 vpro",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0100,2",
        "4YH35AV#AB2",
        "",
        "1",
        "OS Localization",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0110,2",
        "6ME13AV",
        "",
        "1",
        "ENERGY STAR Certified",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0120,2",
        "7HC95AV",
        "",
        "1",
        "LBL China Govt Procurement",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0130,2",
        "8YM60AV",
        "",
        "1",
        "Chassis EliteDesk ENT20 SFF HS65W PLA260",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0140,2",
        "8YM95AV",
        "",
        "1",
        "HDMI Port v2",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0150,2",
        "8YN12AV",
        "",
        "1",
        "LBL GP ChinaEnergy(Cat D G1) SFF",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0160,2",
        "8YN30AV",
        "",
        "1",
        "SSD 512G M.2 2280 PCIe NVMe TLC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0170,2",
        "8YN45AV",
        "",
        "1",
        "CPU I Core i5-10500 3.1GHz 6C65W",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0180,2",
        "8YN70AV",
        "",
        "1",
        "MISC PKG Sgl UN SFF G6",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0190,2",
        "8YN79AV",
        "",
        "1",
        "ODD 9.5 DVDWR G3 800/600 Tower",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0200,2",
        "8YP00AV",
        "",
        "1",
        "OST W10P6 CH",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0210,2",
        "8YP11AV",
        "",
        "1",
        "RAM 16GB (1x16GB) DDR4 2666",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0220,2",
        "8YQ23AV#AB4",
        "",
        "1",
        "WARR 3/3/3 SFF SING",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0230,2",
        "AY100AV",
        "",
        "1",
        "HP PC Image Load Service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0240,2",
        "AY103AV",
        "",
        "1",
        "HP PC BIOS Settings Service",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "9R",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0250,2",
        "141X6AV#AB2",
        "",
        "1",
        "CKIT HP CCC PRC",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05CW378",
        "",
        "N",
        "102407453",
        "Testing ZCON - CTO cancel",
        "2021-09-24",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0260,2",
        "W5W61AV",
        "",
        "1",
        "China Reg CCC Compl Mark Label",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210924S05YR904",
        "",
        "N",
        "102407456",
        "Testing ZCON - CTO cancel-2",
        "2021-08-13",
        "2021-09-24",
        "0",
        "2021-09-24",
        "Standard",
        "",
        "",
        "0260,2",
        "W5W61AV",
        "",
        "1",
        "China Reg CCC Compl Mark Label",
        "CANCELED",
        "",
        "",
        "",
        "BLKD CFSL NoMP REL",
        "CCOK MOID PCRF REL ",
        "0.00",
        "7F",
        "CN50",
        "CN50"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0010,2",
        "6QN76EA#ABB",
        "",
        "10",
        "HP Z4G4T i79800X 16GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "5X",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0020,2",
        "2YW27AV",
        "",
        "17",
        "HP Z2 TWR G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP PCSL REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0030,2",
        "2ZR37AV",
        "",
        "17",
        "Single Unit TWR Z2 Packaging",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0040,2",
        "2ZS06AV",
        "",
        "17",
        "Z2 TWR G4 90 500W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0050,2",
        "3FK24AV#ABD",
        "",
        "17",
        "Win 10 Pro 64 WKST",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0060,2",
        "3PL99AV",
        "",
        "17",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0070,2",
        "7AD14AV",
        "",
        "17",
        "CPU I Xeon E -2224G 3.5GHz 4C",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0080,2",
        "2ZR59AV",
        "",
        "17",
        "32GB (2x16GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0090,2",
        "2ZQ96AV",
        "",
        "17",
        "Nvd Qdr 8GB P4000 (4)DP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0100,2",
        "5RQ61AV",
        "",
        "17",
        "HP Z Turbo Drv M.2 512GB SED TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0110,2",
        "2ZR80AV",
        "",
        "17",
        "512GB 2.5in SATA SSD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0120,2",
        "2ZR34AV",
        "",
        "17",
        "No Included Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0130,2",
        "2ZR35AV",
        "",
        "17",
        "No Included Mouse",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0140,2",
        "2ZR41AV",
        "",
        "17",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0150,2",
        "2ZS03AV",
        "",
        "17",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0160,2",
        "2ZV81AV#ABB",
        "",
        "17",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0170,2",
        "3GD03AV",
        "",
        "17",
        "HP Z2 Front USB-C 3.1 Port",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0180,2",
        "6UA25AV",
        "",
        "17",
        "No Adapters Needed",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0190,2",
        "2ZV86AV#ABD",
        "",
        "17",
        "HP Z2 TWR G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0200,2",
        "4NM33AV",
        "",
        "17",
        "Z2TWR SATA Data Cbl - ST-RA -015",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210927S08YF388",
        "",
        "N",
        "102407623",
        "FXOCH_16_Multiline",
        "2021-09-27",
        "2021-09-27",
        "0",
        "2021-09-27",
        "Standard",
        "D3M01",
        "",
        "0210,2",
        "4NM31AV",
        "",
        "17",
        "HP 3.5 to 2.5 Dr Carrier Z2 TWR",
        "CANCELED",
        "",
        "",
        "",
        "ACKN COM-POC NOCH NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "IL",
        "CH61",
        "CH61"
      ],
      [
        "210928S02HT452",
        "",
        "N",
        "102407766",
        "Home Delivery 5 IDS",
        "2021-09-28",
        "2021-09-28",
        "0",
        "2021-09-28",
        "Standard",
        "",
        "3M_COMPANY_-_WW",
        "0010,2",
        "1A763UP#ABJ",
        "",
        "10",
        "HP EB745G6 R5-3500U 14 8GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK HDBL MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "AN",
        "SG65",
        "SG65"
      ],
      [
        "210928S02LX054",
        "",
        "N",
        "102407843",
        "0928 test-2",
        "2021-09-28",
        "2021-09-28",
        "0",
        "2021-09-28",
        "CHANNEL",
        "AHE40",
        "CONTINENTAL_AG_-_WW",
        "0070,2",
        "UL046E",
        "",
        "400",
        "HP 3y PickupRtn Monitor23 and above SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP REL",
        "CCOK HDOM MOID REL ",
        "0.00",
        "MN",
        "CN04",
        "CN04"
      ],
      [
        "210928S02LX054",
        "",
        "N",
        "102407843",
        "0928 test-2",
        "2021-09-28",
        "2021-09-28",
        "0",
        "2021-09-28",
        "CHANNEL",
        "AHE40",
        "CONTINENTAL_AG_-_WW",
        "0100,2",
        "1PX48AA#AB0",
        "",
        "400",
        "HP 24y 23.8-inch Display",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK HDOM MOID REL ",
        "0.00",
        "7F",
        "SG76",
        "SG76"
      ],
      [
        "210928S08TB894",
        "",
        "N",
        "102407863",
        "audrey_TC4_NZ3",
        "2021-09-28",
        "2021-09-28",
        "0",
        "2021-09-28",
        "Standard",
        "",
        "",
        "0010,2",
        "1FH49AA#ABG",
        "",
        "1",
        "HP EliteDisplay E243i Monitor AUST",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "BO",
        "SG73",
        "SG73"
      ],
      [
        "210930S00RM580",
        "",
        "N",
        "102408047",
        "Test PO 0930-2",
        "2021-09-30",
        "2021-09-30",
        "-1",
        "2021-09-30",
        "Standard",
        "",
        "MODESTO_CITY_SCHOOLS",
        "0010,2",
        "U1G39E",
        "",
        "1",
        "HP 5y Nextbusday Onsite WS Only HW Supp",
        "CANCELED",
        "2021-10-01",
        "2021-10-01",
        "",
        "CFSL NoMP REL",
        "CCOK MDID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211001S05HV484",
        "",
        "N",
        "102408323",
        "DDP US23",
        "2021-10-01",
        "2021-10-01",
        "0",
        "2021-10-01",
        "Standard",
        "R1UE1",
        "IMPRESISTEM_SAS",
        "0020,2",
        "Q2612AD",
        "",
        "5",
        "HP LaserJet 1000/3000 Crtg Dual Pack",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL SCME",
        "CCOK MOID REL ",
        "0.00",
        "GP",
        "US22",
        "US22"
      ],
      [
        "211004S07ZH272",
        "",
        "N",
        "102408451",
        "BTO Allocation Test PLSP 2",
        "2021-10-04",
        "2021-10-04",
        "0",
        "2021-10-04",
        "Standard",
        "",
        "",
        "0030,2",
        "7FR25B#BG2",
        "",
        "100",
        "HP DeskJet IA 2777 AiO Printer:TH/PH-th",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PHLD REL",
        "CCOK MOID REL ",
        "0.00",
        "2N",
        "SG92",
        "SG92"
      ],
      [
        "211005S05XQ425",
        "",
        "N",
        "102408696",
        "TYSONTESTPO158",
        "2021-09-02",
        "2021-10-05",
        "0",
        "2021-10-05",
        "Standard",
        "",
        "TYSON_FOODS",
        "0010,2",
        "CZ993A#B1H",
        "",
        "2",
        "HP OfficeJet 200 Mobile Printer:US/CA-en",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ZSAM ",
        "0.00",
        "DU",
        "US23",
        "US23"
      ],
      [
        "211005S00TC338",
        "",
        "N",
        "102408714",
        "TYSONTESTPO159",
        "2021-09-02",
        "2021-10-05",
        "0",
        "2021-10-05",
        "Standard",
        "",
        "TYSON_FOODS",
        "0010,2",
        "EDIERROR",
        "",
        "2",
        "NOPARTNUMBER",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ZSAM ",
        "0.00",
        "",
        "",
        ""
      ],
      [
        "211006S03QD269",
        "",
        "N",
        "102408859",
        "RC+no softbundle+config id USD",
        "2021-10-06",
        "2021-10-06",
        "0",
        "2021-10-06",
        "Standard",
        "",
        "MAJRANI_GROUP_SRL_-_IT",
        "0080,2",
        "U7D00E",
        "",
        "1",
        "HP 1yr PriorityManagemt PC 1K+ seats SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL ESCD MDID NoMP REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "MG",
        "IT01",
        "IT01"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "1JP11AV",
        "",
        "1",
        "HP Z4 G4 WKS",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "1JR04AV",
        "",
        "1",
        "Single Unit (TWR) Packaging",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0030,2",
        "1JQ29AV",
        "",
        "1",
        "Z4 G4 90 750W Chassis",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL SEAH",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0040,2",
        "1JQ95AV",
        "",
        "1",
        "HP Linux-ready",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0050,2",
        "1JP92AV",
        "",
        "1",
        "Operating System Load to M.2",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0060,2",
        "2PC01AV",
        "",
        "1",
        "Intel XeonW-2125 4.0 4C",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0070,2",
        "1JQ76AV",
        "",
        "1",
        "16GB (2x8GB) DDR4 2666 ECC REG",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US68",
        "US68"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0080,2",
        "1JP22AV",
        "",
        "1",
        "NVIDIA Quadro P400 2GB (3)mDP Graphics",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0090,2",
        "1JP83AV",
        "",
        "1",
        "HP Z Turbo Drive M.2 1TB TLC SSD",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0100,2",
        "1JQ71AV",
        "",
        "1",
        "No Included Keyboard",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0110,2",
        "1JQ72AV",
        "",
        "1",
        "No Included Mouse",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0120,2",
        "1JQ97AV",
        "",
        "1",
        "No Included ODD",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US73",
        "US73"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0130,2",
        "1JP10AV",
        "",
        "1",
        "Base FIO 4xUSB3 TypeA",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0140,2",
        "1JQ27AV",
        "",
        "1",
        "HP Remote Graphics SW (RGS) for Z",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0150,2",
        "1JR07AV#ABA",
        "",
        "1",
        "3/3/3 Warranty",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US27",
        "US27"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0160,2",
        "1QE62AV",
        "",
        "1",
        "HP DisplayPort to DVI-D Adapter",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0170,2",
        "1JQ44AV#ABA",
        "",
        "1",
        "HP Z4 G4 CKIT",
        "CANCELED",
        "",
        "",
        "",
        "NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US61",
        "US61"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0180,2",
        "1QE66AV",
        "",
        "1",
        "HP Z4 Std CPU Cooling Solution",
        "CANCELED",
        "",
        "",
        "",
        "EOL1 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "5X",
        "US73",
        "US73"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0190,2",
        "5FD36UC#ABA",
        "",
        "1",
        "HP EBx3601030G3 i7-8650U 13 16GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0200,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0210,2",
        "7CZ10UP#ABA",
        "",
        "1",
        "HP EBx3601030G3 i7-8650U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0220,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0230,2",
        "5SN27UC#ABA",
        "",
        "1",
        "HP EBx3601030G3 i5-8350U 13 8GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0240,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0250,2",
        "UB0E0E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S00KW501",
        "",
        "N",
        "102409161",
        "432345",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0260,2",
        "UB0E0E",
        "",
        "1",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "CFSL NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL STCP ",
        "78.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S05YQ438",
        "",
        "N",
        "102409187",
        "975163",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "UJ865E",
        "",
        "500",
        "HP 1 unit Basic Install Service",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "0.00",
        "67",
        "US01",
        "US01"
      ],
      [
        "211007S05YQ438",
        "",
        "N",
        "102409187",
        "975163",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "UJ865E",
        "",
        "500",
        "HP 1 unit Basic Install Service",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "0.00",
        "67",
        "US01",
        "US01"
      ],
      [
        "211007S05YQ438",
        "",
        "N",
        "102409187",
        "975163",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U4414E",
        "",
        "500",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "35500.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211007S05YQ438",
        "",
        "N",
        "102409187",
        "975163",
        "2021-10-07",
        "2021-10-07",
        "0",
        "2021-10-07",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U4414E",
        "",
        "500",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "35500.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "7MC28UP#ABA",
        "",
        "1",
        "HP Ex21013G3 i7-8650U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "8J",
        "US66",
        "US66"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0030,2",
        "5SN27UC#ABA",
        "",
        "1",
        "HP EBx3601030G3 i5-8350U 13 8GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0040,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0050,2",
        "D7P94A4#ABA",
        "",
        "1",
        "HP Z30i 30-Inch IPS Monitor",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "TB",
        "US21",
        "US21"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0060,2",
        "7MC28UP#ABA",
        "",
        "1",
        "HP Ex21013G3 i7-8650U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "8J",
        "US66",
        "US66"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0070,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0080,2",
        "7HC41UP#ABA",
        "",
        "1",
        "HP EBx3601030G3 i7-8650U 13 16GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "2480.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211008S03SD184",
        "",
        "N",
        "102409321",
        "2434234",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0090,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211008S03GB922",
        "",
        "N",
        "102409324",
        "2869PBI01",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "CHANNEL",
        "000ER",
        "Tech_Data_bvba_-_NL_stock",
        "0010,2",
        "7GU51EA#ABU",
        "",
        "1",
        "HP Pav Gaming Laptop 15-dk0501na",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "KV",
        "CH69",
        "CH69"
      ],
      [
        "211008S07WJ000",
        "",
        "N",
        "102409333",
        "2869PBI09",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "CHANNEL",
        "X3LMK",
        "",
        "0020,2",
        "6EB60EA#BH5",
        "",
        "1",
        "HP 250G7 CelN4000 15 4GB/500 PC",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MOID REL STCP ",
        "0.00",
        "6U",
        "CH69",
        "CH69"
      ],
      [
        "211008S07SF976",
        "",
        "N",
        "102409339",
        "2869PBI015",
        "2021-10-08",
        "2021-10-08",
        "0",
        "2021-10-08",
        "CHANNEL",
        "X3LMK",
        "",
        "0030,2",
        "1CA81A7#ABA",
        "",
        "1",
        "HP 27o 27-inch Display",
        "CANCELED",
        "",
        "",
        "",
        "EOL2 NoMP REL",
        "CCOK MOID REL ",
        "0.00",
        "2G",
        "CH22",
        "CH22"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "7CZ10UP#ABA",
        "",
        "1",
        "HP EBx3601030G3 i7-8650U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0030,2",
        "5SN27UC#ABA",
        "",
        "1",
        "HP EBx3601030G3 i5-8350U 13 8GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0040,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0050,2",
        "7HC41UP#ABA",
        "",
        "1",
        "HP EBx3601030G3 i7-8650U 13 16GB/512 PC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "AN",
        "US66",
        "US66"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0060,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0070,2",
        "7MC28UP#ABA",
        "",
        "1",
        "HP Ex21013G3 i7-8650U 13 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL EOL2 NoMP PCSL PRSE REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "8J",
        "US66",
        "US66"
      ],
      [
        "211009S03XC225",
        "",
        "N",
        "102409483",
        "505043066",
        "2021-10-09",
        "2021-10-09",
        "0",
        "2021-10-09",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0080,2",
        "U7NT8E",
        "",
        "1",
        "HP 3y Travel Pickup Return NB Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "MDID NoMP REL",
        "CCOK CINV FCFL MDID MOFD MOID MPAN REL ",
        "0.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211011S00PV441",
        "",
        "N",
        "102409529",
        "9755",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "EDIERROR",
        "",
        "1",
        "X3A70A",
        "CANCELED",
        "",
        "",
        "",
        "C2AF ECLX INC-VBAP-ROUTE INC-VBAP-VSTEL INC-VBAP-WERKS INCO MATE NoMP PRSE REL",
        "CCOK FCFL MDID MOID MPAN REL ",
        "11469.00",
        "",
        "",
        ""
      ],
      [
        "211011S05NK227",
        "",
        "N",
        "102409589",
        "307602",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "UJ865E",
        "",
        "500",
        "HP 1 unit Basic Install Service",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "0.00",
        "67",
        "US01",
        "US01"
      ],
      [
        "211011S05NK227",
        "",
        "N",
        "102409589",
        "307602",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U4414E",
        "",
        "500",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "35500.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211011S19DV104",
        "",
        "N",
        "102409591",
        "835328",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "UJ865E",
        "",
        "500",
        "HP 1 unit Basic Install Service",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "0.00",
        "67",
        "US01",
        "US01"
      ],
      [
        "211011S19DV104",
        "",
        "N",
        "102409591",
        "835328",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0020,2",
        "U4414E",
        "",
        "500",
        "HP 3y NextBusDayOnsite Notebook Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "C2AF CFSL NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL STCP ",
        "35500.00",
        "MG",
        "US01",
        "US01"
      ],
      [
        "211011S00XS147",
        "",
        "N",
        "102409656",
        "ODW-PC-test2",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "FLEX_AUTOMOTIVE_INC",
        "0010,2",
        "2Y3R5A#ABA",
        "",
        "3",
        "Stubbing test matl 1",
        "CANCELED",
        "",
        "",
        "",
        "MDID NPIH NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "N0",
        "US23",
        "US23"
      ],
      [
        "211011S00XS147",
        "",
        "N",
        "102409656",
        "ODW-PC-test2",
        "2021-10-11",
        "2021-10-11",
        "0",
        "2021-10-11",
        "Standard",
        "",
        "FLEX_AUTOMOTIVE_INC",
        "0020,2",
        "2Y3R5A#ABA",
        "",
        "5",
        "Stubbing test matl 1",
        "CANCELED",
        "",
        "",
        "",
        "MDID NPIH NoMP PRSE REL",
        "CCOK MDID MOFD MOID MPAN NTRS REL ",
        "0.00",
        "N0",
        "US23",
        "US23"
      ],
      [
        "211012S22YF596",
        "",
        "N",
        "102409704",
        "Itg3",
        "2021-10-12",
        "2021-10-12",
        "0",
        "2021-10-12",
        "Standard",
        "",
        "DUKE_UNIVERSITY",
        "0010,2",
        "1FH47A8",
        "",
        "111",
        "HP PROMO EliteDisplay E243 Monitor",
        "CANCELED",
        "",
        "",
        "",
        "C2AF EOL2 MDID NoMP REL",
        "CCOK FCFL MDID MOID MPAN REL ",
        "19869.00",
        "BO",
        "US21",
        "US21"
      ],
      [
        "211019S22KF021",
        "",
        "N",
        "102411759",
        "gtv_inc3",
        "2021-09-14",
        "2021-10-19",
        "0",
        "2021-10-19",
        "Standard",
        "",
        "",
        "0010,2",
        "8WH81AV",
        "",
        "1",
        "SSD 512GB PCIe NVMe TLC",
        "CANCELED",
        "",
        "",
        "",
        "GTPC NoMP REL",
        "CCOK CREQ EOL2 FCPS MOID REL STOR ",
        "0.00",
        "KV",
        "US65",
        "US65"
      ],
      [
        "211019S22KF021",
        "",
        "N",
        "102411759",
        "gtv_inc3",
        "2021-09-14",
        "2021-10-19",
        "0",
        "2021-10-19",
        "Standard",
        "",
        "",
        "0010,2",
        "8WH81AV",
        "",
        "1",
        "SSD 512GB PCIe NVMe TLC",
        "CANCELED",
        "",
        "",
        "",
        "GTPC NoMP REL",
        "CCOK CREQ EOL2 FCPS MOID REL STOR ",
        "0.00",
        "KV",
        "US65",
        "US65"
      ],
      [
        "210726S40NW352",
        "",
        "N",
        "70VS18771001",
        "ADProducts",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "BBTO",
        "",
        "NESTLE_SA_-_WW",
        "0001,1",
        "U7D31E",
        "",
        "1",
        "HP 1yrADP Select EU Direct Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL RGLI TBA",
        "1SOH CNAP OPN REL ",
        "0.00",
        "MG",
        "B606",
        "7000"
      ],
      [
        "210726S40NW352",
        "",
        "N",
        "70VS18771001",
        "ADProducts",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "BBTO",
        "",
        "NESTLE_SA_-_WW",
        "0002,1",
        "U7D32E",
        "",
        "1",
        "HP 2yrADP Select EU Direct Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL RGLI TBA",
        "1SOH CNAP OPN REL ",
        "0.00",
        "MG",
        "B606",
        "7000"
      ],
      [
        "210726S40NW352",
        "",
        "N",
        "70VS18771001",
        "ADProducts",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "BBTO",
        "",
        "NESTLE_SA_-_WW",
        "0003,1",
        "U7D33E",
        "",
        "1",
        "HP 3yrADP Select EU Direct Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL RGLI TBA",
        "1SOH CNAP OPN REL ",
        "0.00",
        "MG",
        "B606",
        "7000"
      ],
      [
        "210726S40NW352",
        "",
        "N",
        "70VS18771001",
        "ADProducts",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "BBTO",
        "",
        "NESTLE_SA_-_WW",
        "0004,1",
        "U7D34E",
        "",
        "1",
        "HP 4yrADP Select EU Direct Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL RGLI TBA",
        "1SOH CNAP OPN REL ",
        "0.00",
        "MG",
        "B606",
        "7000"
      ],
      [
        "210726S40NW352",
        "",
        "N",
        "70VS18771001",
        "ADProducts",
        "2021-07-26",
        "2021-07-26",
        "0",
        "2021-07-26",
        "BBTO",
        "",
        "NESTLE_SA_-_WW",
        "0005,1",
        "U7D35E",
        "",
        "1",
        "HP 5yrADP Select EU Direct Only SVC",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL RGLI TBA",
        "1SOH CNAP OPN REL ",
        "0.00",
        "MG",
        "B606",
        "7000"
      ],
      [
        "210811S45BK028",
        "",
        "N",
        "906133324363",
        "INV CUTOVER TEST 2",
        "2021-08-11",
        "2021-08-11",
        "0",
        "2021-08-11",
        "APOrderDesk",
        "AG156",
        "MISSION_AUSTRALIA",
        "0100,2",
        "1B089PA#ABG",
        "",
        "2",
        "HP 400G5PD DM i59500T 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "CRNO EXPI NoMP OPN REL",
        "CRE ESSC ESST HCVA HTVA OPN REL ",
        "0.00",
        "DG",
        "9010",
        "Y200"
      ],
      [
        "210811S45BK028",
        "",
        "N",
        "906133324363",
        "INV CUTOVER TEST 2",
        "2021-08-11",
        "2021-08-11",
        "0",
        "2021-08-11",
        "APOrderDesk",
        "AG156",
        "MISSION_AUSTRALIA",
        "0300,2",
        "1B089PA#ABG",
        "",
        "5",
        "HP 400G5PD DM i59500T 16GB/256 PC",
        "CANCELED",
        "",
        "",
        "",
        "CRNO EXPI NoMP OPN REL",
        "CRE ESSC ESST HCVA HTVA OPN REL ",
        "0.00",
        "DG",
        "9010",
        "Y200"
      ],
      [
        "210205S41SM697",
        "",
        "N",
        "9PW260721001",
        "KT_05022021",
        "2021-02-05",
        "2021-02-05",
        "0",
        "2021-02-05",
        "BBTO",
        "",
        "Ingram_Micro_Hungary_Ltd",
        "0001,1",
        "1JS05A4",
        "",
        "2",
        "HP Z22n G2 Display",
        "CANCELED",
        "",
        "",
        "",
        "2IEC 2ZER 6NAK NoMP REL TBA p:INIT",
        "OPN REL ",
        "0.00",
        "TB",
        "6372",
        "63D7"
      ],
      [
        "210818S45VL337",
        "",
        "N",
        "E8V268834002",
        "590_Backlogconv_",
        "2021-08-12",
        "2021-08-12",
        "0",
        "2021-08-12",
        "BBTO",
        "",
        "",
        "0001,2",
        "W1A53A#B19",
        "",
        "5",
        "HP LaserJet Pro M404dn Printer:EUR",
        "CANCELED",
        "",
        "",
        "",
        "NoMP REL TBA",
        "2OPG OPN REL ",
        "0.00",
        "8A",
        "01CR",
        "N831"
      ],
      [
        "210806S46VC194",
        "",
        "N",
        "J9M863413363",
        "TESTSNI002",
        "2021-08-06",
        "2021-08-06",
        "0",
        "2021-08-06",
        "WholeSeller/W1",
        "AG445",
        "",
        "0210,2",
        "1FH50AA#ACJ",
        "",
        "1",
        "HP EliteDisplay E273 Monitor INDIA",
        "CANCELED",
        "",
        "",
        "",
        "ACK CROK NoMP REL",
        "HCVA HTVA OPN REL ",
        "0.00",
        "BO",
        "XG01",
        "XGFN"
      ]
    ],
    "rowsCount": 508,
    "watched": [
      {
        "legacy_order_no": "201020S43BQ908",
        "options": "pinned",
        "timing": ""
      }
    ]
  }

export default data;