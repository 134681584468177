import { orderTypeParams } from "./params";

const ORDER_DEFAULTS = {
  variety: 'amo',
  exchange: 'nse',
  order_type: 'LIMIT',
  disclosed_quantity: 0,
  trailing_stoploss: 0,
  trigger_price: 0,
  squareoff: 0,
  stoploss: 0,
  validity: 'DAY'

}

export const normalizeOrder = (order: any) => {
  const trigger_price = order.order_type === orderTypeParams.sl || order.order_type === orderTypeParams.slm ? order.trigger_price : 0;

  const price = order.order_type === orderTypeParams.limit || order.order_type === orderTypeParams.sl ? order.price : 0;

  return {
    variety: order.variety || ORDER_DEFAULTS.variety,
    exchange: order.exchange || ORDER_DEFAULTS.exchange,
    tradingsymbol: order.tradingsymbol,
    transaction_type: order.transaction_type,
    order_type: order.order_type || ORDER_DEFAULTS.order_type,
    quantity: order.quantity,
    price: price,
    product: order.product,
    validity: order.validity || ORDER_DEFAULTS.validity,
    disclosed_quantity: order.disclosed_quantity || ORDER_DEFAULTS.disclosed_quantity,
    trigger_price: trigger_price || ORDER_DEFAULTS.trigger_price,
    squareoff: order.squareoff || ORDER_DEFAULTS.squareoff,
    stoploss: order.stoploss || ORDER_DEFAULTS.stoploss,
    trailing_stoploss: order.trailing_stoploss || ORDER_DEFAULTS.trailing_stoploss
  }
}

export const normalizeGttOrder = (order: any, offSetMin: any = 1, offSetMax: any = -1) => {
  if (order.condition && order.condition.trigger_values) {
    if (order.type === 'single')
      order.condition.trigger_values = [parseFloat(order.condition.trigger_values[0])]
  } else {
    order.condition.trigger_values = [parseFloat(order.condition.trigger_values[0]), parseFloat(order.condition.trigger_values[1])]
  }
  order.condition.last_price = parseFloat(order.condition.last_price)
  order.orders[0].quantity = parseInt(order.orders[0].quantity)
  order.orders[1].quantity = parseInt(order.orders[1].quantity)
  order.orders[0].price = parseFloat(order.orders[0].price + offSetMin)
  order.orders[1].price = parseFloat(order.orders[1].price + offSetMax)
  return {
    condition: JSON.stringify(order.condition || {}),
    orders: JSON.stringify(order.orders || {}),
    type: order.type || 'two-leg',
    expires_at: order.expires_at || '2025-03-17 00:00:00'
  }
}

export const getSingleOrderPayload = (order: any) => {
  const newOrder: any = {
    condition: {
      exchange: order.exchange,
      tradingsymbol: order.tradingsymbol,
      trigger_values: [parseFloat(order.trigger_values[0])],
      last_price: parseFloat(order.last_price)
    },
    orders: [{
      exchange: order.exchange,
      tradingsymbol: order.tradingsymbol,
      transaction_type: order.transaction_type,
      quantity: parseInt(order.quantity),
      price: parseFloat(order.price),
      order_type: order.order_type || "LIMIT",
      product: order.product || "CNC"
    }],
    type: order.type || 'single',
    expires_at: order.expires_at || '2025-03-17 00:00:00'
  };
  const payload = {
    condition: JSON.stringify(newOrder.condition || {}),
    orders: JSON.stringify(newOrder.orders || {}),
    type: newOrder.type,
    expires_at: newOrder.expires_at
  };
  return payload;
}

