import React, { useEffect } from 'react';
import { Slider, Stack, TextField } from '@mui/material';
import MarketConfig from "../../config/market-config";

const capGap = 5000;

const MarketCapSlider = ({ getScreenerData, newCapRange }: any) => {
  const [capRange, setCapRange] = React.useState<number[]>([MarketConfig.largeCap.g, MarketConfig.largeCap.l]);

  useEffect(() => {
    if (newCapRange) { setCapRange(newCapRange) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, newCapRange)

  const handleCapChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setCapRange([Math.min(newValue[0], capRange[1] - capGap), capRange[1]]);
    } else {
      setCapRange([capRange[0], Math.max(newValue[1], capRange[0] + capGap)]);
    }
  };

  const getData = () => {
    console.log('capRange', capRange)
    getScreenerData({
      g: Number(capRange[0]),
      l: Number(capRange[1])
    });
  }

  const handleCapMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCapRange(event.target.value === '' ? [0, capRange[1]] : [Number(event.target.value), capRange[1]]);
  }
  const handleCapMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCapRange(event.target.value === '' ? [capRange[0], 0] : [capRange[0], Number(event.target.value)]);
  }

  return (
    <div className="cap-range-slider">
      <Slider
        value={capRange}
        onChange={handleCapChange}
        onChangeCommitted={getData}
        min={MarketConfig.smallCap.g}
        max={MarketConfig.largeCap.l}
        disableSwap
      />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TextField label="Min" variant="outlined"
          size="small"
          value={capRange[0]}
          onChange={handleCapMinInputChange}
          inputProps={{
            step: 1000,
            min: 0,
            max: capRange[1],
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
        <TextField label="Max" variant="outlined"
          size="small"
          value={capRange[1]}
          onChange={handleCapMaxInputChange}
          inputProps={{
            step: 100,
            min: capRange[0],
            max: MarketConfig.largeCap.l,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </Stack>
    </div>
  );
}

export default MarketCapSlider;